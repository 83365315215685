import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { RouterProvider } from 'react-router-dom'
import { Environment } from './constants'
import store, { persistor } from '@/store'
import '@/translations'
import { router } from './routes'
import '@/assets/css/tailwind.css'

import { DayJSMiddlewares } from '@/middlewares'
import { SentryConfig } from '@/services'

DayJSMiddlewares.setLocale()

if (Environment.ENV === 'PROD') {
  SentryConfig()
}

ReactDOM.createRoot(document.getElementById('root')!).render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <RouterProvider router={router} />
    </PersistGate>
  </Provider>,
)
