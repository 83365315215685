import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { CurrencyFormatter } from '@macellan/formatter'
import type { EarnablePointProps } from './EarnablePoint.types'
import styles from './EarnablePoint.module.css'
import { useAppDispatch, useAppSelector } from '@/store'
import { Badge } from '@/components/Badge'
import { Container } from '@/components/Container'
import { Toast } from '@/components/Toast'
import i18n from '@/translations'
import depositApi, { type UpHas } from '@/pages/deposit/api'
import { setUpHasCompleted } from '@/pages/deposit/slice'

const EarnablePoint: React.FC<EarnablePointProps> = props => {
  const { amount, currency, minimumDepositAmount, maximumDepositAmount, onChangeAmount, walletId } = props
  const locale = i18n.getLocale()
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const { upHasCompleted } = useAppSelector(state => state.depositSlice)
  const [earnablePoint, setEarnablePoint] = React.useState<number>(0)
  const [upHas, setUpHas] = React.useState<UpHas | undefined>()

  const [pointEarnable] = depositApi.usePointDepositEarnableMutation()

  const formattedPoint = React.useMemo(() => {
    return CurrencyFormatter.format(earnablePoint, locale, currency)
  }, [currency, earnablePoint, locale])

  const formattedToastMessage = React.useMemo(() => {
    if (!upHas) return

    const formattedIncreaseAmount = CurrencyFormatter.format(upHas.increase_amount, locale, currency)

    const formattedHasPoint = CurrencyFormatter.format(upHas.point, locale, currency)

    return (
      <Trans
        i18nKey="@deposit.EarnablePoint.up_has_toast_message"
        values={{
          formattedIncreaseAmount: formattedIncreaseAmount,
          formattedHasPoint: formattedHasPoint,
        }}
        components={[<span className={styles.ToastHighlight} />]}
      />
    ) as unknown as string
  }, [currency, upHas, locale])

  const handlePointEarnable = React.useCallback(async () => {
    let earnablePointResult = 0
    let upHasResult

    if (amount >= minimumDepositAmount && amount <= maximumDepositAmount) {
      const result = await pointEarnable({
        wallet_id: walletId,
        amount: amount,
      }).unwrap()

      earnablePointResult = result.data.point
      upHasResult = result.data.up_has
    }

    setEarnablePoint(earnablePointResult)
    setUpHas(upHasResult)
  }, [amount, maximumDepositAmount, minimumDepositAmount, pointEarnable, walletId])

  const handleChangeValue = React.useCallback(() => {
    if (!upHas?.amount) return
    dispatch(setUpHasCompleted(true))
    onChangeAmount(upHas.amount)
  }, [dispatch, onChangeAmount, upHas?.amount])

  React.useEffect(() => {
    handlePointEarnable()
  }, [handlePointEarnable])

  if (!earnablePoint) return null

  return (
    <Container>
      <div className={styles.Container}>
        <div className={`${styles.EarnablePoint} animate-enter`}>
          <p>{t('@deposit.EarnablePoint.point_title')}</p>
          <Badge variant="yellow" size="tiny">
            {formattedPoint} {t('@deposit.EarnablePoint.point_label')}
          </Badge>
        </div>
        {formattedToastMessage && upHas && !upHasCompleted ? (
          <Toast
            variant="suggestion"
            message={formattedToastMessage}
            LayoutRightComponent={type => (
              <Toast.Chip variant={type} onClick={handleChangeValue}>
                {t('@deposit.EarnablePoint.up_has_toast_chip')}
              </Toast.Chip>
            )}
          />
        ) : null}
      </div>
    </Container>
  )
}
export default EarnablePoint
