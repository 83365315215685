import React from 'react'
import type { CheckBoxProps } from './CheckBox.types'
import styles from './CheckBox.module.css'
import { Container } from '../Container'
import { Icon } from '../Icons'

const CheckBox: React.FC<CheckBoxProps> = props => {
  const { children, ...rest } = props
  return (
    <Container>
      <div className={styles.Checkbox}>
        <label>
          <div>
            <input type="checkbox" {...rest} />
            <div className={styles.CustomCheckbox}>
              <Icon name="check" size={24} color="white" />
            </div>
          </div>
          <p>{children}</p>
        </label>
      </div>
    </Container>
  )
}

export default CheckBox
