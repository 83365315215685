import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useFormik } from 'formik'
import type { PaymentRefundForm } from './PaymentRefund.types'
import styles from './PaymentRefund.module.css'
import { paymentRefundValidation } from './PaymentRefund.validations'
import toast from '@/context/ToastContext/Toast.utils'
import { Button } from '@/components/Button'
import { Header } from '@/components/Header'
import { Section } from '@/components/Section'
import { TextField } from '@/components/TextField'
import { ToolBar } from '@/components/ToolBar'
import paymentApi, { type RefundReason } from '../../api'
import PaymentRefundReasonList from './PaymentRefundReasonList'

const PaymentRefund: React.FC = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const { t } = useTranslation()

  const refundReasonListQuery = paymentApi.useRefundReasonListQuery(undefined)
  const [refundRequest, refundRequestResult] = paymentApi.useRefundRequestMutation()

  const handleSubmit = React.useCallback(
    async (values: PaymentRefundForm) => {
      try {
        const result = await refundRequest({
          payment_id: values.payment_id,
          reason: values.reason.value,
          comment: values.comment,
        }).unwrap()

        if (result.data.status === 'open') {
          toast.success({ message: t('@payment.PaymentRefund.refund_request_success'), icon: 'check-circle' })
          navigate(-1)
        }
      } catch {
        navigate(-1)
      }
    },
    [navigate, refundRequest, t],
  )

  const formik = useFormik<PaymentRefundForm>({
    initialValues: {
      payment_id: location.state?.paymendId,
      reason: { label: '', value: '' },
      comment: '',
    },
    onSubmit: handleSubmit,
    validationSchema: paymentRefundValidation,
  })

  const handleOnPressChip = React.useCallback(
    (reason: RefundReason) => {
      formik.setFieldValue('reason', reason)
    },
    [formik],
  )

  if (!refundReasonListQuery.data) return null

  return (
    <React.Fragment>
      <Header
        variant="secondary"
        title={t('@payment.PaymentRefund.route_header_title')}
        color="black"
        goBack={() => navigate(-1)}
      />
      <Section className={styles.PaymentRefundSectionContainer}>
        <div className={styles.PaymentRefundSectionHeader}>
          <h5>{t('@payment.PaymentRefund.refund_title')}</h5>
        </div>
        <PaymentRefundReasonList
          reasons={refundReasonListQuery.data.data}
          activeReason={formik.values.reason}
          onPress={handleOnPressChip}
        />
      </Section>
      <Section className={styles.PaymentRefundSectionContainer}>
        <div className={styles.PaymentRefundSectionHeader}>
          <h5>{t('@payment.PaymentRefund.refund_description')}</h5>
        </div>
        <TextField
          multiline
          error={formik.errors.comment ? 'error' : 'enable'}
          placeholder={t('@payment.PaymentRefund.comment_placeholder')}
          message={formik.errors.comment}
          value={formik.values.comment}
          onBlur={formik.handleBlur('comment')}
          onChange={formik.handleChange('comment')}
          rows={5}
          style={{ resize: 'none' }}
        />
      </Section>
      <ToolBar className="grid grid-cols-1 border-none">
        <Button
          variant="primary"
          size="large"
          radiusType="rounded"
          loading={refundRequestResult.isLoading}
          disabled={!formik.isValid}
          onClick={() => formik.handleSubmit()}>
          {t('@payment.PaymentRefund.submit_button')}
        </Button>
      </ToolBar>
    </React.Fragment>
  )
}

export default PaymentRefund
