import * as React from 'react'
import { useNavigate } from 'react-router-dom'
import { map } from 'lodash'
import { useTranslation } from 'react-i18next'
import { MasterPassHelpQuestions, MasterPassHelpUrl } from './MasterPassHelp.constants'
import toast from '@/context'
import { Button } from '@/components/Button'
import { Header } from '@/components/Header'
import { Row } from '@/components/Row'
import { Section } from '@/components/Section'
import { ToolBar } from '@/components/ToolBar'

const MasterPassHelp: React.FC = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()

  const handleOpenHelp = React.useCallback(() => {
    toast.modal({
      variant: 'weblink',
      children: <iframe src={MasterPassHelpUrl} className="h-[calc(100vh-120px)] w-full" />,
      actions: [
        {
          children: t('@deposit.MasterPassHelp.close_button'),
          variant: 'secondary',
          size: 'large',
          radiusType: 'rounded',
        },
      ],
    })
  }, [t])

  return (
    <React.Fragment>
      <Header
        variant="secondary"
        title={t('@deposit.MasterPassHelp.route_header_title')}
        color="black"
        goBack={() => navigate(-1)}
      />
      <Section className="!py-0">
        {map(MasterPassHelpQuestions, (question, index) => (
          <Row.Description key={index} title={question.title} message={question.message} />
        ))}
      </Section>
      <ToolBar className="grid grid-cols-1 border-none">
        <Button variant="primary" size="large" radiusType="rounded" onClick={handleOpenHelp}>
          {t('@deposit.MasterPassHelp.action_button_help')}
        </Button>
      </ToolBar>
    </React.Fragment>
  )
}

export default MasterPassHelp
