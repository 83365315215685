import { CreditCardFormatter, DateTimeFormatter } from '@macellan/formatter'

import { yup } from '@/middlewares'

export const cardValidationSchema = yup.object().shape({
  creditCardNo: yup
    .string()
    .required()
    .test({
      name: 'rta-pan-validation',
      test: (value: string) => CreditCardFormatter.validate(value),
    }),
  expireDate: yup
    .string()
    .required()
    .test({
      name: 'expiry-date-validation',
      test: (value: string) => DateTimeFormatter.validate(value, 'MM/YY'),
    }),
  cvcNo: yup.string().min(3).max(4).required(),
  saveCard: yup.bool(),
  name: yup.string().when('saveCard', {
    is: true,
    then: schema => schema.required().min(3),
  }),
  agreement: yup.bool().when('saveCard', {
    is: true,
    then: schema => schema.required().oneOf([true]),
  }),
})
