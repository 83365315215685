import React from 'react'
import cn from 'classnames'
import type { AccountActivityListSkeletonProps } from './AccountActivityList.types'

const AccountActivitySectionListSkeleton: React.FC<AccountActivityListSkeletonProps> = ({ className }) => {
  return (
    <div className="h-screen animate-pulse overflow-y-hidden">
      <div className={cn(className)}>
        <div className="mb-3 mt-6 h-2 w-[100px] bg-semantic-background-secondary" />
        {[...Array(7)].map((_, index) => (
          <div className="space-y-4 py-3" key={index}>
            <div className="flex items-center space-x-4">
              <div className="h-12 w-12 rounded-full bg-semantic-background-secondary"></div>
              <div className="flex-1 space-y-6 py-1">
                <div className="space-y-3">
                  <div className="grid grid-cols-3 gap-4">
                    <div className="col-span-2 h-2 rounded bg-semantic-background-secondary"></div>
                    <div className="col-span-1 h-2 rounded bg-semantic-background-secondary"></div>
                  </div>
                  <div className="grid grid-cols-3 justify-end gap-4">
                    <div className="col-span-2 h-2 w-28 rounded bg-semantic-background-secondary"></div>
                    <div className="col-span-1 h-2 rounded bg-semantic-background-secondary"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default AccountActivitySectionListSkeleton
