import { CreditCardFormatter, DateTimeFormatter } from '@macellan/formatter'

import yup from '@/middlewares/Validation'

export const paymentFormValidations = yup.object().shape({
  creditCardNo: yup
    .string()
    .required()
    .test({
      name: 'rta-pan-validation',
      test: (value: string) => CreditCardFormatter.validate(value),
    }),
  expireDate: yup
    .string()
    .required()
    .test({
      name: 'expiry-date-validation',
      test: (value: string) => DateTimeFormatter.validate(value, 'MM/YY'),
    }),
  cvcNo: yup.string().min(3).max(4).required(),
})
