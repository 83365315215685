import React from 'react'
import type { ToastProps, ToastStaticProperties } from './Toast.types'
import styles from './Toast.module.css'
import { getToastIconColor, ToastVariants } from './Toast.utils'
import { Container } from '../Container'
import { Icon } from '../Icons'
import ToastChip from './ToastChip'
import ToastProgress from './ToastProgress'

const Toast: React.FC<ToastProps> & ToastStaticProperties = props => {
  const { duration, variant, icon, message, LayoutRightComponent } = props
  const [exit, setExit] = React.useState<boolean>(true)

  React.useEffect(() => {
    if (!duration) return
    const timer = setTimeout(() => {
      setExit(false)
    }, duration - 500)

    return () => {
      clearTimeout(timer)
    }
  }, [duration])

  return (
    <Container>
      <div className={`${styles.Toast} ${ToastVariants[variant]} ${exit ? 'animate-enter' : 'animate-leave'}`}>
        <div className={styles.LeftWrapper}>
          <div className={styles.ToastContent}>
            {icon && <Icon name={icon} size={24} color={getToastIconColor(variant)} />}
            <p>{message}</p>
          </div>
        </div>
        {LayoutRightComponent && (
          <div className={styles.RightWrapper}>
            {typeof LayoutRightComponent === 'function' ? LayoutRightComponent(variant) : LayoutRightComponent}
          </div>
        )}
      </div>
    </Container>
  )
}

Toast.Chip = ToastChip
Toast.Progress = ToastProgress

export default Toast
