import { createSlice } from '@reduxjs/toolkit'
import type { BaseState } from './BaseSlice.types'
import baseApi from '@/api'

const initialState: BaseState = {
  pusherEnv: {
    host: null,
    key: null,
    port: null,
  },
}

const BaseSlice = createSlice({
  name: 'baseSlice',
  initialState: initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addMatcher(baseApi.endpoints.appInfo.matchFulfilled, (state, action) => {
      state.pusherEnv = action.payload.data.pusher
    })
  },
})

export default BaseSlice.reducer
