import React from 'react'
import { useTranslation } from 'react-i18next'
import { CurrencyFormatter } from '@macellan/formatter'
import type { CapturePriceProps } from './Capture.types'
import styles from './Capture.module.css'
import { Container } from '@/components/Container'
import { Section } from '@/components/Section'
import i18n from '@/translations'

const CapturePrice: React.FC<CapturePriceProps> = ({ amount, currency }) => {
  const { t } = useTranslation()

  const formatted = React.useMemo(() => {
    return CurrencyFormatter.formatToDetails(amount, i18n.getLocale(), currency)
  }, [amount, currency])

  return (
    <Container>
      <Section className={styles.CapturePriceContainer}>
        <div className={styles.CapturePriceHeader}>
          <h6>{t('@payment.Capture.price_header_title')}</h6>
          <p>{t('@payment.Capture.price_header_description')}</p>
        </div>
        <div className={styles.CapturePriceAmount}>
          <span className={styles.CapturePriceAmountSymbol}>{formatted.symbol}</span>
          <span className={styles.CapturePriceAmountDigid}>{formatted.digid}</span>
        </div>
      </Section>
    </Container>
  )
}

export default CapturePrice
