import * as React from 'react'
import { useNavigate } from 'react-router-dom'
import { QRCodeSVG } from 'qrcode.react'
import { replace } from 'lodash'
import { useTranslation } from 'react-i18next'
import styles from './Payment.module.css'
import useGenerateQREvents from './Payment.hooks'
import { Container } from '@/components/Container'
import { Header } from '@/components/Header'
import { Loading } from '@/components/Loading'
import { Timer } from '@/components/Timer'
import { TimerUtils } from '@/utils'
import paymentApi from '@/pages/payment/api'

const PaymentQR: React.FC = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()

  const [create, createResult] = paymentApi.useCreateMutation()

  const referenceCode = React.useMemo(() => {
    if (!createResult.data?.data.reference_code) return
    return createResult.data?.data.reference_code.toString()
  }, [createResult.data?.data.reference_code])

  useGenerateQREvents(referenceCode)

  const duration = React.useMemo(() => {
    if (!createResult.data?.data) return

    return TimerUtils.calculateDuration(createResult.data.data.expires_at, createResult.data.data.server_time)
  }, [createResult.data?.data])

  const createQrByReferenceCode = React.useCallback(() => {
    create().unwrap()
  }, [create])

  React.useEffect(() => {
    createQrByReferenceCode()
  }, [createQrByReferenceCode])

  if (!referenceCode || typeof duration !== 'number')
    return (
      <React.Fragment>
        <Header
          variant="secondary"
          title={t('@payment.Payment.route_header_title')}
          color="black"
          goBack={() => navigate('/')}
        />
        <Loading loading={createResult.isLoading} size={24} />
      </React.Fragment>
    )

  return (
    <React.Fragment>
      <Header
        variant="secondary"
        title={t('@payment.Payment.route_header_title')}
        color="black"
        goBack={() => navigate(-1)}
      />
      <Container>
        <div className={styles.QRCodeContent}>
          <QRCodeSVG value={`ALT-${referenceCode}-PAY`} className={styles.QRCode} level="H" />
          <p>{replace(referenceCode, /(\d{3})(\d{3})/, '$1 $2')}</p>
          <Timer.Circle
            size={96}
            duration={duration}
            onFinished={createQrByReferenceCode}
            valueTextStyle={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
            }}
          />
        </div>
      </Container>
    </React.Fragment>
  )
}

export default PaymentQR
