/** @type {import('tailwindcss').Config} */
import type { Config } from 'tailwindcss'
export default {
  content: ['./index.html', './src/**/*.{js,ts,jsx,tsx}'],
  theme: {
    extend: {
      colors: {
        semantic: {
          content: {
            brand: '#f4364c',
            inkInverse: '#fff',
            inkInverseTonal: '#ffffff99',
            ink: '#15151E',
            inkMedium: '#494950',
            inkLight: '#7d7d82',
            disabled: '#b1b1b4',
            error: '#c50707',
            success: '#0e968b',
            info: '#ffab00',
            link: '#1d93d2',
          },
          background: {
            inkInverse: '#fff',
            primary: '#F9F8F7',
            secondary: '#F4F3F2',
            tertiary: '#ECEBE8',
            quaternary: '#D4D3CE',
          },
        },
        solid: {
          core: {
            black: '#000',
            white: '#fff',
            transparent: 'transparent',
            yellow: '#FD9',
          },
          yellow: {
            1: '#FEC',
            2: '#FD9',
            3: '#FFCD66',
            20: '#ffab00',
          },
          green: {
            6: '#0B786F',
          },
          blue: {
            6: '#0071AC',
          },
          brand: {
            1: '#fdd7db',
            2: '#fbafb7',
            3: '#f88694',
            4: '#f65e70',
            5: '#f4364c',
            6: '#c32b3d',
            7: '#92202e',
            10: '#f4364c',
          },
          ink: {
            1: '#f3f3f7',
            2: '#cbcbcd',
            3: '#b1b1b4',
            4: '#97979b',
            5: '#7d7d82',
            6: '#636369',
            7: '#494950',
            8: '#2F2F37',
            9: '#15151E',
          },
          sand: {
            3: '#ECEBE8',
          },
        },
        tint: {
          white: {
            20: '#ffffff33',
          },
        },
      },
      fontSize: {
        'display-xs': ['14px', { lineHeight: '16px', fontWeight: '600', letterSpacing: '-0.02em' }],
        'display-sm': ['18px', { lineHeight: '24px', fontWeight: '700', letterSpacing: '-0.02em' }],
        'display-md-b': ['20px', { lineHeight: '32px', fontWeight: '700', letterSpacing: '-0.02em' }],
        'display-md-r': ['20px', { lineHeight: '32px', fontWeight: '400', letterSpacing: '-0.02em' }],
        'display-lg': ['24px', { lineHeight: '32px', fontWeight: '400', letterSpacing: '-0.02em' }],
        'display-lg-b': ['24px', { lineHeight: '32px', fontWeight: '700', letterSpacing: '-0.02em' }],
        'display-hg': ['28px', { lineHeight: '40px', fontWeight: '700', letterSpacing: '-0.02em' }],
        'title-sm': ['32px', { lineHeight: '48px', fontWeight: '400', letterSpacing: '-0.02em' }],
        'title-sm-b': ['32px', { lineHeight: '48px', fontWeight: '700', letterSpacing: '-0.02em' }],
        'title-md': ['36px', { lineHeight: '48px', fontWeight: '700', letterSpacing: '-0.02em' }],
        'title-lg-b': ['56px', { lineHeight: '56px', fontWeight: '700', letterSpacing: '-0.02em' }],
        'title-lg-r': ['56px', { lineHeight: '56px', fontWeight: '400', letterSpacing: '-0.02em' }],
        'body-sm-b': ['11px', { lineHeight: '16px', fontWeight: '700', letterSpacing: '-0.02em' }],
        'body-sm-r': ['11px', { lineHeight: '16px', fontWeight: '400', letterSpacing: '-0.02em' }],
        'body-md-b': ['13px', { lineHeight: '16px', fontWeight: '700', letterSpacing: '-0.02em' }],
        'body-md-r': ['13px', { lineHeight: '16px', fontWeight: '400', letterSpacing: '-0.02em' }],
        'body-lg-b': ['15px', { lineHeight: '24px', fontWeight: '700', letterSpacing: '-0.02em' }],
        'body-lg-r': ['15px', { lineHeight: '24px', fontWeight: '400', letterSpacing: '-0.02em' }],
        'label-sm': ['11px', { lineHeight: '16px', fontWeight: '700', letterSpacing: '-0.02em' }],
        'label-md': ['12px', { lineHeight: '16px', fontWeight: '700', letterSpacing: '-0.02em' }],
        'label-lg': ['14px', { lineHeight: '16px', fontWeight: '700', letterSpacing: '-0.02em' }],
        'link-sm': ['11px', { lineHeight: '16px', fontWeight: '700', letterSpacing: '-0.02em' }],
        'link-md': ['13px', { lineHeight: '16px', fontWeight: '700', letterSpacing: '-0.02em' }],
        'link-lg': ['15px', { lineHeight: '16px', fontWeight: '700', letterSpacing: '-0.02em' }],
        'overline-md': ['10px', { lineHeight: '12px', fontWeight: '700', letterSpacing: '0.04em' }],
        'overline-lg': ['13px', { lineHeight: '16px', fontWeight: '700', letterSpacing: '0.04em' }],
      },
      animation: {
        'enter': 'enter 200ms ease-out',
        'slide-in': 'slide-in 1.2s cubic-bezier(.41,.73,.51,1.02)',
        'leave': 'leave 250ms ease-in forwards',
        'fadeIn': 'fadeIn 0.5s ease-out forwards',
        'fadeOut': 'fadeOut 0.5s ease-out forwards',
      },
      keyframes: {
        'enter': {
          '0%': { transform: 'scale(0.9)', opacity: '0' },
          '100%': { transform: 'scale(1)', opacity: '1' },
        },
        'leave': {
          '0%': { transform: 'scale(1)', opacity: '1' },
          '100%': { transform: 'scale(0.9)', opacity: '0' },
        },
        'slide-in': {
          '0%': { transform: 'translateY(-100%)' },
          '100%': { transform: 'translateY(0)' },
        },
        'fadeIn': {
          '0%': { opacity: '0' },
          '100%': { opacity: '1' },
        },
        'fadeOut': {
          '0%': { opacity: '1' },
          '100%': { opacity: '0' },
        },
      },
      height: {
        'header': '64px',
        'screen-size': 'calc(100vh - 64px)',
      },
    },
  },
  plugins: [],
} satisfies Config
