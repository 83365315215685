import cn from 'classnames'
import type { AccountActivityListSkeletonProps } from './AccountActivityList.types'

const AccountActivityListSkeleton: React.FC<AccountActivityListSkeletonProps> = ({ className }) => {
  return (
    <div className={`${cn('animate-pulse', className)}`}>
      <div className="flex flex-col gap-2 py-4">
        <div className="flex items-center justify-between">
          <div className="h-[32px] w-[226px] rounded-xl bg-semantic-background-secondary" />
          <div className="h-6 w-6 rounded-lg bg-semantic-background-secondary" />
        </div>
        <div className="h-4 w-full rounded-xl bg-semantic-background-secondary" />
      </div>
      {[...Array(5)].map((_, index) => (
        <div className="space-y-4 py-3" key={index}>
          <div className="flex items-center space-x-4">
            <div className="h-12 w-12 rounded-full bg-semantic-background-secondary"></div>
            <div className="flex-1 space-y-6 py-1">
              <div className="space-y-3">
                <div className="grid grid-cols-3 gap-4">
                  <div className="col-span-2 h-2 rounded bg-semantic-background-secondary"></div>
                  <div className="col-span-1 h-2 rounded bg-semantic-background-secondary"></div>
                </div>
                <div className="grid grid-cols-3 justify-end gap-4">
                  <div className="col-span-2 h-2 w-28 rounded bg-semantic-background-secondary"></div>
                  <div className="col-span-1 h-2 rounded bg-semantic-background-secondary"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  )
}

export default AccountActivityListSkeleton
