import * as React from 'react'
import type { DepositSectionProps } from './DepositSection.types'
import { Row } from '@/components/Row'
import { PaymentMethodsService } from '../../services'

const DepositSection: React.FC<DepositSectionProps> = props => {
  const { type } = props

  return (
    <div className="border-t border-semantic-background-tertiary px-6 pb-4 pt-7">
      <Row.Title title={PaymentMethodsService.getTitle(type).toUpperCase()} />
    </div>
  )
}

export default DepositSection
