import React from 'react'
import type { CampaignItemCardProps } from './CampaignItem.types'
import styles from './CampaignItem.module.css'
import { Button } from '../Button'

const CampaignItemCard: React.FC<CampaignItemCardProps> = props => {
  const { logo, title, description } = props
  return (
    <div className={styles.Container}>
      <div className={styles.Logo}>
        <img src={logo} alt={title} />
      </div>
      <div className={styles.Content}>
        <div className={styles.TextWrapper}>
          <h2 className={styles.Title}>{title}</h2>
          <p className={styles.Description}>{description}</p>
        </div>
        <div className={styles.Actions}>
          <Button variant="primary" size="tiny" radiusType="pill">
            Detay
          </Button>
        </div>
      </div>
    </div>
  )
}

export default CampaignItemCard
