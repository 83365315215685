import type { RewardChartProgressProps } from './RewardChart.types'
import styles from './RewardChart.module.css'
import { useContentConfig } from '@/hooks'
import ThemeConfig from '~/tailwind.config'
import { Progress } from '@/components/Progress'

const RewardChartProgress: React.FC<RewardChartProgressProps> = props => {
  const { name, color, processCount, remainderCount } = props
  const { colors } = ThemeConfig.theme.extend
  const content = useContentConfig()

  return (
    <div className={styles.Container}>
      <div className={styles.LeftWrapper}>
        <h6 className={styles.Title}>{content.rewardChart.progressTypeTitle}</h6>
        <div className={styles.Content}>
          <span style={{ backgroundColor: color }}>{remainderCount}</span>
          <p>{content.rewardChart.progressTypeDescription}</p>
        </div>
        <p className={styles.Description}>{name}</p>
      </div>
      <div className={styles.RightWrapper}>
        <Progress
          value={processCount - remainderCount}
          totalValue={processCount}
          textValue={remainderCount}
          indicatorColor={color}
          valueTextColor={colors.semantic.content.disabled}
          backgroundColor={colors.semantic.background.tertiary}
          size={120}
        />
      </div>
    </div>
  )
}

export default RewardChartProgress
