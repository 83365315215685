import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import type { CampaignDetailFooterProps } from './CampaignDetail.types'
import { Button } from '@/components/Button'
import { ToolBar } from '@/components/ToolBar'

const CampaignDetailFooter: React.FC<CampaignDetailFooterProps> = ({ walletId }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const handleNavigate = React.useCallback(() => {
    if (!walletId) return

    navigate('/topup', { state: { walletId: walletId } })
  }, [navigate, walletId])

  return (
    <ToolBar>
      <ToolBar.Left>
        <p className="pr-2 text-body-md-r text-semantic-content-inkMedium">
          {t('@campaign.CampaignDetail.footer_deposit_description')}
        </p>
      </ToolBar.Left>
      <ToolBar.Right>
        <Button variant="primary" size="large" radiusType="rounded" onClick={handleNavigate}>
          {t('@campaign.CampaignDetail.footer_deposit_button')}
        </Button>
      </ToolBar.Right>
    </ToolBar>
  )
}

export default CampaignDetailFooter
