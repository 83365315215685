import React from 'react'
import type { ToastContextTypeProps } from './ToastContext.types'

const ToastContext = React.createContext<ToastContextTypeProps>({
  toasts: [],
  modal: null,
  addToast: () => {},
  addModal: () => {},
  closeModal: () => {},
})

export default ToastContext
