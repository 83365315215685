import { type TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
import { type AnyAction, type CombinedState, combineReducers, configureStore, type Reducer } from '@reduxjs/toolkit'
import { persistReducer, persistStore } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import baseApi from '@/api'
import { goldFramerApi } from '@/pages/deposit/api'
import { DepositSlice } from '@/pages/deposit/slice'

import { RTKQueryMiddlewares } from '@/middlewares'
import { BaseSlice } from '@/slices'

const basePersistConfig = {
  key: 'base',
  storage,
  whitelist: ['baseSlice'],
}

const combinedReducer = combineReducers({
  [baseApi.reducerPath]: baseApi.reducer,
  [goldFramerApi.reducerPath]: goldFramerApi.reducer,
  baseSlice: BaseSlice,
  depositSlice: DepositSlice,
})

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const rootReducer: Reducer<CombinedState<any>, AnyAction> = (state, action) => {
  return combinedReducer(action.type === 'RESET_STATE' ? undefined : state, action)
}

const persistedReducer = persistReducer(basePersistConfig, rootReducer)

const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false,
    }).concat(RTKQueryMiddlewares.handleError, baseApi.middleware, goldFramerApi.middleware),
})

export default store
export const persistor = persistStore(store)

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

export const useAppDispatch = () => useDispatch<AppDispatch>()
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector
