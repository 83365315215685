/* eslint-disable  */
import * as React from 'react'
import type { QueryDefinition } from '@reduxjs/toolkit/dist/query'
import type { UseQuery } from '@reduxjs/toolkit/dist/query/react/buildHooks'
import type { SubscriptionOptions as RTKSubscriptionOptions } from '@reduxjs/toolkit/dist/query/core/apiState'
import { isEqual } from 'lodash'
import { usePrevious } from '@/utils'

export type RequestWitoutPage<T> = Omit<T, 'page'>
export type RequestWithPage<T> = RequestWitoutPage<T> & { page: number }

export type SubscriptionOptions = RTKSubscriptionOptions & {
  skip?: boolean
  refetchOnMountOrArgChange?: boolean
}

export interface BaseResponse {
  meta: any
  data: any[]
}

const usePagination = <Response extends BaseResponse, Request>(
  useQuery: UseQuery<QueryDefinition<Request, any, any, Response>>,
  params: RequestWitoutPage<Request>,
  subscriptionOptions?: SubscriptionOptions,
) => {
  const [args, setArgs] = React.useState({
    ...(params as Request),
    page: 1,
  })

  const [allData, setAllData] = React.useState<Response['data'] | null>(null)

  const prevParams = usePrevious(params)

  const paginateQuery = useQuery(args, {
    refetchOnMountOrArgChange: true,
    ...subscriptionOptions,
  })

  const meta = React.useMemo(() => {
    return paginateQuery.data?.meta
  }, [paginateQuery.data?.meta]) as Response['meta']

  React.useEffect(() => {
    if (paginateQuery.data?.data)
      return setAllData(currentData => [...(currentData ?? []), ...(paginateQuery.data?.data ?? [])])
  }, [paginateQuery.data?.data])

  const more = React.useCallback(() => {
    if (!meta?.paginate?.has_more || paginateQuery.isFetching) return

    setArgs(currentArgs => ({ ...currentArgs, page: currentArgs.page + 1 }))
  }, [meta?.paginate, paginateQuery.isFetching])

  React.useEffect(() => {
    if (isEqual(params, prevParams)) {
      return
    }

    setArgs({ page: 1, ...(params as Request) })
    setAllData([])
  }, [params, prevParams])

  return {
    meta,
    data: allData,
    more,
    query: paginateQuery,
  }
}

export default usePagination
