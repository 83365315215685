import type {
  BalanceCheckData,
  BalanceCheckResponse,
  InstallmentInfoData,
  InstallmentInfoResponse,
  Mark3DClosedData,
  Mark3DClosedResponse,
  MasterPassFailData,
  MasterPassFailResponse,
  MasterPassGetTokenResponse,
  MasterPassInitData,
  MasterPassInitDirectData,
  MasterPassInitDirectResponse,
  MasterPassInitResponse,
  MasterPassMakeData,
  MasterPassMakeResponse,
  MasterPassMarkOTPData,
  MasterPassMarkOTPResponse,
  MasterPassSelectData,
  MasterPassSelectResponse,
  PointDepositEarnableData,
  PointDepositEarnableResponse,
  UserCardListResponse,
} from './DepositApi.types'
import baseApi from '@/api'

const depositApi = baseApi.injectEndpoints({
  endpoints: builder => ({
    userCardList: builder.query<UserCardListResponse, undefined>({
      query: () => ({
        url: 'v2/user/card/list',
        method: 'GET',
      }),
    }),
    balanceCheck: builder.mutation<BalanceCheckResponse, BalanceCheckData>({
      query: data => ({
        url: 'waas/payments/balance-check',
        method: 'POST',
        body: data,
      }),
    }),
    masterpassGetToken: builder.query<MasterPassGetTokenResponse, undefined>({
      query: () => ({
        url: 'v2/user/card/masterpass/get-token',
        method: 'GET',
      }),
    }),
    masterpassSelect: builder.mutation<MasterPassSelectResponse, MasterPassSelectData>({
      query: data => ({
        url: 'v2/user/card/masterpass/select',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: (_r, error) => (error ? [] : ['CardList']),
    }),
    masterpassUnlink: builder.mutation<undefined, undefined>({
      query: () => ({
        url: 'v2/user/card/masterpass/unlink',
        method: 'PUT',
      }),
      invalidatesTags: (_r, error) => (error ? [] : ['CardList']),
    }),
    masterpassInit: builder.mutation<MasterPassInitResponse, MasterPassInitData>({
      query: data => ({
        url: 'v2/deposit/masterpass/init',
        method: 'POST',
        body: data,
      }),
    }),
    masterpassInitDirect: builder.mutation<MasterPassInitDirectResponse, MasterPassInitDirectData>({
      query: data => ({
        url: 'v2/deposit/masterpass/init-direct',
        method: 'POST',
        body: data,
      }),
    }),
    masterpassMarkOTP: builder.mutation<MasterPassMarkOTPResponse, MasterPassMarkOTPData>({
      query: data => ({
        url: 'v2/deposit/masterpass/mark-otp',
        method: 'PUT',
        body: data,
      }),
    }),
    masterpassMake: builder.mutation<MasterPassMakeResponse, MasterPassMakeData>({
      query: data => ({
        url: 'v2/deposit/masterpass/make',
        method: 'POST',
        body: data,
      }),
    }),
    masterpassFail: builder.mutation<MasterPassFailResponse, MasterPassFailData>({
      query: data => ({
        url: 'v2/deposit/masterpass/fail',
        method: 'PUT',
        body: data,
      }),
    }),
    pointDepositEarnable: builder.mutation<PointDepositEarnableResponse, PointDepositEarnableData>({
      query: data => ({
        url: `v2/wallet/point/deposit-earnable`,
        method: 'POST',
        body: data,
      }),
    }),
    mark3DClosed: builder.mutation<Mark3DClosedResponse, Mark3DClosedData>({
      query: data => ({
        url: 'v2/deposit/mark-threed-closed',
        method: 'PUT',
        body: data,
      }),
    }),
    installmentInfo: builder.query<InstallmentInfoResponse, InstallmentInfoData>({
      query: data => ({
        url: 'v2/deposit/installment/info',
        method: 'POST',
        body: data,
      }),
    }),
  }),
  overrideExisting: true,
})

export default depositApi
