import { reduce } from 'lodash'
import type {
  CampaignDetailsData,
  CampaignDetailsResponse,
  CampaignNewsListData,
  CampaignNewsListResponse,
} from './CampaignApi.types'
import baseApi from '@/api'

const campaignApi = baseApi.injectEndpoints({
  endpoints: builder => ({
    campaignDetails: builder.query<CampaignDetailsResponse, CampaignDetailsData>({
      query: data => ({
        url: 'v2/campaign-news/details/' + data.company_id,
        method: 'GET',
      }),
    }),
    campaignNewsList: builder.query<CampaignNewsListResponse, CampaignNewsListData>({
      query: data => {
        const { campaign_types, ...dataWithoutCampaignTypes } = data

        const campaignTypesParams = reduce(
          campaign_types,
          (accumulator, currentValue, key) => {
            accumulator[`campaign_types[${key}]`] = currentValue
            return accumulator
          },
          {} as Record<string, string>,
        )

        return {
          url: 'v2/campaign-news/list',
          method: 'GET',
          params: {
            ...dataWithoutCampaignTypes,
            ...campaignTypesParams,
          },
        }
      },
    }),
  }),
  overrideExisting: true,
})

export default campaignApi
