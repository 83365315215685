import React from 'react'
import { useNavigate } from 'react-router-dom'
import { map } from 'lodash'
import { useTranslation } from 'react-i18next'
import type { AccountActivitiesHeaderProps } from './AccountActivities.types'
import { convertArrayToObject } from './AccountActivities.utils'
import ThemeConfig from '~/tailwind.config'
import { Badge } from '@/components/Badge'
import { EmblaCarousel } from '@/components/EmblaCarousel'
import { Header } from '@/components/Header'
import { IconButton } from '@/components/IconButton'
import { SearchField } from '@/components/SearchField'
import { Section } from '@/components/Section'
import { SectionBar } from '@/components/SectionBar'

const AccountActivitiesHeader: React.FC<AccountActivitiesHeaderProps> = porps => {
  const { walletId, activeFilters, endDate, startedDate } = porps
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { colors } = ThemeConfig.theme.extend

  const handleNavigateFilter = React.useCallback(() => {
    const convertedFilter = convertArrayToObject(activeFilters)

    navigate('/account-activities-filter', {
      state: {
        walletId: walletId,
        filters: activeFilters,
        startedDate: convertedFilter.started_date?.date ? startedDate : undefined,
        endDate: convertedFilter.end_date?.date ? endDate : undefined,
      },
    })
  }, [activeFilters, endDate, navigate, startedDate, walletId])

  return (
    <React.Fragment>
      <Header
        goBack={() =>
          navigate('/', {
            replace: true,
          })
        }
      />
      <div
        className={`flex flex-col gap-2 border-b border-semantic-background-tertiary ${
          activeFilters.length ? '' : 'pb-8'
        }`}>
        <Section className="pb-0">
          <SectionBar
            title={t('@wallet.AccountActivities.content_header_title')}
            description={t('@wallet.AccountActivities.content_header_description')}
          />
          <div onClick={handleNavigateFilter}>
            <SearchField
              placeholder={t('@wallet.FavoriteWalletAdd.search_field_placeholder')}
              onFilterButtonPress={() => {}}
            />
          </div>
        </Section>
        {activeFilters.length ? (
          <div className="py-4">
            <EmblaCarousel slideSize="auto">
              {[
                <IconButton
                  key="filter"
                  className="!h-8 !w-8 bg-solid-brand-5"
                  color={colors.semantic.content.inkInverse}
                  size={16}
                  name="control"
                  onClick={handleNavigateFilter}
                />,
                ...map(activeFilters, (filter, key) => (
                  <Badge
                    key={key}
                    size="medium"
                    variant="brand-tonal"
                    className="flex flex-row-reverse"
                    icon="close-circle"
                    onClick={() => {
                      const editedFilter = activeFilters.filter(filterItem => filterItem.type !== filter.type)
                      navigate('/account-activities', { state: { filters: editedFilter } })
                    }}>
                    {'date' in filter && filter.date !== undefined
                      ? filter.date
                      : 'labelType' in filter && t(`@wallet.AccountActivitiesFilter.${filter.labelType}`)}
                  </Badge>
                )),
              ]}
            </EmblaCarousel>
          </div>
        ) : null}
      </div>
    </React.Fragment>
  )
}

export default AccountActivitiesHeader
