export default {
  CouponApply: {
    route_header_title: 'Apply Coupon',
    content_header_description: 'Enter the coupon code below to earn points',
    coupon_add_button: 'Apply Coupon',
    coupon_toast_message: 'Coupon worth {{amount}} points successfully applied',
    coupon_input_placeholder: 'Enter Coupon Code',
  },
  FavoriteWalletAdd: {
    route_header_title: 'Search',
    section_title: 'SEARCH RESULTS',
    section_right_button: 'Sort by Name',
    search_field_placeholder: 'Search',
    max_favorite_wallets_message: 'You have reached the maximum number of favorite wallets',
  },
  WalletAgreement: {
    route_header_title: 'Wallet Agreement',
  },
  WalletDetail: {
    header_title: 'BALANCE',
    header_point: 'Points: {{point}}',
    help: 'Help',
    help_description: 'Facing an issue?',
    read_qr: 'QR Pay',
    menu_earnings_title: 'My Earnings',
    menu_earnings_subtitle: 'Contribution of Macellan SuperApp to your budget',
    menu_brand_title: 'About the Brand',
    menu_brand_subtitle: 'Menu, campaigns, branches, and more',
    wallet_history_title: 'Wallet Transactions',
    wallet_history_subtitle: 'Last 5 transactions',
  },
  WalletModules: {
    route_header_title: 'All Transactions',
    pay: 'Make Payment',
    deposit: 'Load Balance',
    coupon: 'Apply Coupon',
    wallet_share: 'Share Wallet',
    gift_card: 'Gift Card',
    ticket_system: 'My Tickets',
    branches: 'Branches',
    history: 'Account Activities',
    help: 'Help',
    agreement: 'Wallet Agreement',
  },
  AccountActivities: {
    content_header_title: 'Account Activities',
    content_header_description: 'Access all account activities easily from here',
  },
  AccountActivitiesFilter: {
    content_header_title: 'Filter Activities',
    content_header_description: 'Apply the necessary filters and easily access the results',
    categories: 'CATEGORIES',
    filters: 'FILTERS',
    started_date: 'START DATE',
    end_date: 'END DATE',
    submit: 'Show Results',
    balance: 'Balance',
    refund: 'Refund',
    payment_refund: 'Spending Refund',
    deposit_refund: 'Load Refund',
    cash_deposit_refund: 'Cash Load Refund',
    capture: 'Balance Spending',
    top_up: 'Balance Load',
    date_time_place_holder: 'e.g., 07/07/2023',
  },
  AccountActivitySectionList: {
    empty_sheet_title: 'No Account Activity',
    empty_sheet_description: 'No account activity found',
  },
  AccountActivityDetail: {
    content_header_title: 'Transaction Details',
    content_header_description: 'Access all transaction details easily from here',
    refund_reason: 'Refund Reason',
    customer_comment: 'Customer Comment',
    company_comment: 'Company Comment',
    reference_number: 'Request No',
    refund_button_title: 'Request Refund',
    branch_name: 'Branch Name',
    cashier: 'Cashier',
    total_earned_points: 'Total Earned Points',
    point_group: 'Point Group',
    earned_points: 'Earned Points',
    earned_wallet: 'Earned Wallet',
    top_up: 'Load',
    capture: 'Capture',
    process_type: 'Process Type',
    spent_amount: 'Spent Amount',
    order_id: 'Order ID',
    date: 'Date',
    spent_point: 'Spent Points',
    wallet_type: 'Wallet Type',
    shared_wallet: 'Shared Wallet',
    sharer: 'Sharer',
    shared: 'Shared',
    expire_date: 'Expiration Date',
    description: 'Description',
    gift_card: 'Gift Card',
    undefined_user: 'Undefined User',
    loaded_amount: 'Loaded Amount',
    bank_amount: 'Bank Amount',
    point_deducted_balance: 'Point Deducted from Balance',
    reference_code: 'Reference Code',
    loaded_type: 'Load Type',
    cash: 'Cash Load',
    dcb: 'Reflect on Invoice',
    card_number: 'Card Number',
    payment_refund: 'Payment Refund',
    deposit_refund: 'Deposit Refund',
    cash_deposit_refund: 'Cash Deposit Refund',
    refunded_amount: 'Refunded Amount',
    transaction_details: 'Transaction Details',
    branch_details: 'Branch Details',
    additional_details: 'Additional Details',
    installment_count: 'Number of Installments',
    total_installment_amount: 'Total Installment Amount',
  },
}
