export const splitOrderId = (orderId: number): string => {
  if (!orderId) return ''

  const orderIdText = orderId.toString()

  let result = ''

  for (let i = 0; i < orderIdText.length; i++) {
    if (i % 2 === 0 && i !== 0) {
      result += ' '
    }

    result += orderIdText[i]
  }

  return result
}

export default {
  splitOrderId: splitOrderId,
}
