export type StatusCodes = number[] | '*'

export interface IgnoredEndpoint {
  pattern: string | RegExp
  statusCodes: StatusCodes
}

const ignoredEndpoints: IgnoredEndpoint[] = [
  {
    pattern: '/waas/login/send-sms',
    statusCodes: [404],
  },
  {
    pattern: /^\/v2\/wallet\/by-company\/\d+$/,
    statusCodes: [404],
  },
  {
    pattern: /^\/waas\/app-start\/.+$/,
    statusCodes: [404],
  },
  {
    pattern: '/v2/deposit/installment/info',
    statusCodes: [406, 412],
  },
]

export default {
  ignoredEndpoints: ignoredEndpoints,
}
