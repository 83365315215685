import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { find, map } from 'lodash'
import { useTranslation } from 'react-i18next'
import { type MasterPassCard } from '@macellan/masterpass-sdk/src'
import toast from '@/context/ToastContext/Toast.utils'
import { useAppSelector } from '@/store'
import { Button } from '@/components/Button'
import { ContentHeader } from '@/components/ContentHeader'
import { DepositCard } from '@/components/DepositCard'
import { Header } from '@/components/Header'
import { Row } from '@/components/Row'
import { Section } from '@/components/Section'
import { Spinner } from '@/components/Spinner'
import depositApi from '@/pages/deposit/api'
import { MasterPassService } from '@/pages/deposit/services'
import { DepositSection } from '../../components/DepositSection'

import masterpassLogo from '@/assets/images/MasterPassLogo.jpeg'

const MasterPassCards: React.FC = () => {
  const navigate = useNavigate()
  const location = useLocation()

  const { t } = useTranslation()
  const [selectedCardLoading, setSelectedCardLoading] = React.useState<boolean>(false)

  const cardListQuery = depositApi.useUserCardListQuery(undefined)

  const { mpCards, mpCardsLoading, mpAccountType, mpAccountLoading, mpDeleteLoading } = useAppSelector(
    state => state.depositSlice,
  )

  const selectedMpCard = React.useMemo(() => {
    if (!cardListQuery.data?.data) return null
    return find(cardListQuery.data?.data, item => item.type === 'masterpass')
  }, [cardListQuery.data?.data])

  const selectMpCard = React.useCallback(
    async (item: MasterPassCard) => {
      setSelectedCardLoading(true)
      await MasterPassService.selectCard(item)
      navigate('/topup', {
        replace: true,
        state: {
          selectedLoading: true,
          walletId: location.state?.walletId,
        },
      })
    },
    [navigate, location.state?.walletId],
  )

  const deleteMpCard = React.useCallback(
    async (accountAliasName: string) => {
      await MasterPassService.deleteCards(accountAliasName)
        .then(result => {
          if (!result.length) navigate(-1)
        })
        .catch(error => {
          toast.error({ message: error.data.message, icon: 'warning' })
        })
        .finally(() => {
          cardListQuery.refetch()
        })
    },
    [navigate, cardListQuery],
  )

  const showDeleteAlert = React.useCallback(
    (accountAliasName: string) => {
      toast.modal({
        title: t('@deposit.MasterPassCards.mp_delete_alert_title'),
        description: t('@deposit.MasterPassCards.mp_delete_alert_message'),
        variant: 'suggestion',
        icon: 'warning',
        actions: [
          {
            variant: 'primary',
            size: 'large',
            radiusType: 'rounded',
            children: t('@deposit.MasterPassCards.mp_delete_alert_approve_button'),
            onClick: () => deleteMpCard(accountAliasName),
          },
          {
            size: 'large',
            radiusType: 'rounded',
            variant: 'secondary',
            children: t('@deposit.MasterPassCards.mp_delete_alert_cancel_button'),
          },
        ],
      })
    },
    [deleteMpCard, t],
  )

  const getMasterPassCards = React.useCallback(async () => {
    await MasterPassService.checkMasterPass()
    if (mpAccountType !== 'registered') return
    await MasterPassService.listCards()
  }, [mpAccountType])

  React.useEffect(() => {
    getMasterPassCards()
  }, [getMasterPassCards])

  return (
    <React.Fragment>
      <Header
        variant="secondary"
        title={t('@deposit.MasterPassCards.route_header_title')}
        color="black"
        goBack={() => navigate('/topup', { state: { walletId: location.state?.walletId } })}
        divider={false}
        rightAction={selectedCardLoading ? <Spinner size={24} /> : undefined}
      />
      <Section>
        <ContentHeader description={t('@deposit.MasterPassCards.content_header_description')} />
      </Section>
      <DepositSection type="masterpass" isUnlinkButtonHidden={true} />
      <Section className="!py-0">
        {!mpDeleteLoading &&
          map(mpCards, (item, key) => (
            <div key={key} className="py-2">
              <DepositCard
                title={item.Name}
                selected={item.Name === selectedMpCard?.name}
                description={item.Value1}
                avatarImageSource={masterpassLogo}
                LayoutRightComponent={
                  <Button
                    className="min-w-[40px]"
                    variant="primary"
                    size="tiny"
                    radiusType="pill"
                    onClick={() => showDeleteAlert(item.Name)}>
                    {t('@deposit.MasterPassCards.delete_alert_button')}
                  </Button>
                }
                onClick={() => selectMpCard(item)}
              />
            </div>
          ))}
        {mpDeleteLoading || (mpCardsLoading && mpCards.length === 0) || (mpAccountLoading && mpCards.length === 0) ? (
          <React.Fragment>
            {map([...Array(1)], (_, index) => (
              <DepositCard.Loader key={index.toString()} />
            ))}
          </React.Fragment>
        ) : null}
        {mpCards.length < 7 ? (
          <Row className="py-2">
            <Row.Item
              title={t('@deposit.MasterPassCards.register_card_row_item_title')}
              icon="credit-card"
              onClick={() =>
                navigate('/masterpass-card-register', {
                  replace: true,
                  state: {
                    walletId: location.state?.walletId,
                  },
                })
              }
            />
          </Row>
        ) : undefined}
      </Section>
    </React.Fragment>
  )
}

export default MasterPassCards
