import * as React from 'react'
import type { LoadingIndicatorProps } from './Loading.types'
import ThemeConfig from '~/tailwind.config'
import { Spinner } from '../Spinner'

const LoadingIndicator: React.FC<LoadingIndicatorProps> = ({ children, loading, ...props }) => {
  const { colors } = ThemeConfig.theme.extend
  if (!loading) return <React.Fragment>{children}</React.Fragment>

  return (
    <React.Fragment>
      <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2">
        <Spinner color={colors.semantic.content.inkMedium} {...props} />
      </div>
      {React.Children.map(children, element => {
        return React.cloneElement(element as JSX.Element, {
          style: { opacity: 0 },
        })
      })}
    </React.Fragment>
  )
}

export default LoadingIndicator
