import React from 'react'
import cn from 'classnames'
import { motion } from 'framer-motion'
import type { IHeaderProps } from './Header.types'
import styles from './Header.module.css'
import { getBackgroundColor, GetColors, handleScroll, VariantDividers } from './Header.utils'
import ThemeConfig from '~/tailwind.config'
import Icon from '../Icons/Icon'

const Header: React.FC<IHeaderProps> = props => {
  const { colors } = ThemeConfig.theme.extend
  const {
    title,
    logo,
    variant,
    rightIcon,
    color = 'black',
    bgColor = colors.solid.core.white,
    divider = true,
    className,
    rightAction,
    goBack,
  } = props
  const [isScrolled, setIsScrolled] = React.useState<boolean>(false)

  React.useEffect(() => {
    const onScroll = () => handleScroll(setIsScrolled, bgColor)
    window.addEventListener('scroll', onScroll)
    return () => window.removeEventListener('scroll', onScroll)
  }, [bgColor])

  return (
    <motion.header
      initial="hidden"
      animate="visible"
      variants={{ hidden: { opacity: 0 }, visible: { opacity: 1 } }}
      transition={{ duration: 0.3, ease: 'easeInOut' }}
      className={`${cn(styles.Header, className)} ${divider ? '' : styles.NoneDivider} ${
        variant ? VariantDividers[color] : ''
      }`}
      style={{
        backgroundColor: getBackgroundColor(isScrolled, bgColor, color),
        transition: 'background-color 0.3s ease',
      }}>
      <div className={styles.LeftWrapper}>
        {goBack && (
          <button onClick={goBack}>
            <Icon name="arrow-left-bold" size={24} color={GetColors[color]} />
          </button>
        )}
      </div>
      {variant === 'secondary' && (
        <div className={styles.CenterWrapper}>
          <h1 className="text-display-xs" style={{ color: GetColors[color] }}>
            {title}
          </h1>
        </div>
      )}
      <div className={styles.RightWrapper}>
        {variant === 'primary' && (
          <div className={styles.Logo}>
            <h1 className="text-display-sm" style={{ color: GetColors[color] }}>
              {title}
            </h1>
            <img src={logo} alt={title} />
          </div>
        )}
        {typeof rightAction === 'function' ? rightAction() : rightAction}
        {variant === 'secondary' && rightIcon && <Icon name={rightIcon} size={24} color={GetColors[color]} />}
      </div>
    </motion.header>
  )
}

export default Header
