import React from 'react'
import { map, times } from 'lodash'
import dayjs from 'dayjs'
import type { CalendarProps } from './DateTimeField.types'
import styles from './DateTimeField.module.css'
import { getCells } from './Calendar.utils'
import ThemeConfig from '~/tailwind.config'
import { IconButton } from '../IconButton'

const Calendar: React.FC<CalendarProps> = props => {
  const { selectedDate, setCurrentMonth, currentMonth, showDatePicker, handleDateSelect } = props
  const { colors } = ThemeConfig.theme.extend
  const daysOfWeek = times(7, idx =>
    dayjs()
      .day(idx + 1)
      .format('ddd'),
  )

  if (!showDatePicker) return null

  return (
    <div className={styles.CalendarContainer}>
      <div className={styles.CalendarHeader}>
        <IconButton
          name="arrow-left"
          size={16}
          color={colors.solid.brand[5]}
          className="!h-8 !w-8 bg-solid-brand-10/10"
          onClick={() => setCurrentMonth(prev => prev.subtract(1, 'month'))}
        />
        <span>{currentMonth.format('MMMM YYYY')}</span>
        <IconButton
          name="arrow-right"
          size={16}
          color={colors.solid.brand[5]}
          className="!h-8 !w-8 bg-solid-brand-10/10"
          onClick={() => setCurrentMonth(prev => prev.add(1, 'month'))}
        />
      </div>
      <div className={styles.CalendarDays}>
        {map(daysOfWeek, (day, idx) => (
          <div key={idx}>{day}</div>
        ))}
      </div>
      <div className={styles.CalendarCellsContainer}>{getCells(currentMonth, selectedDate, handleDateSelect)}</div>
    </div>
  )
}

export default Calendar
