import { map } from 'lodash'
import type {
  BalanceHistoryDetailsData,
  BalanceHistoryDetailsResponse,
  BalanceHistoryListData,
  BalanceHistoryListResponse,
  PointCouponApplyData,
  PointCouponApplyResponse,
  WalletDetailsData,
  WalletDetailsResponse,
  WalletLastEarnedDepositGiftPointsData,
  WalletLastEarnedDepositGiftPointsResponse,
  WalletPointWonCampaignData,
  WalletPointWonCampaignResponse,
} from './WalletApi.types'
import WalletApiUtils from './WalletApi.utils'
import baseApi from '@/api'

const walletApi = baseApi.injectEndpoints({
  endpoints: builder => ({
    walletDetails: builder.query<WalletDetailsResponse, WalletDetailsData>({
      query: data => {
        const walletId = data.wallet_id ?? localStorage.getItem('user.wallet_id')
        return {
          url: 'v2/wallet/details/' + walletId,
          method: 'GET',
        }
      },
      providesTags: ['WalletDetail'],
      transformResponse: WalletApiUtils.transformWalletDetailsResponse,
    }),
    pointCouponApply: builder.mutation<PointCouponApplyResponse, PointCouponApplyData>({
      query: data => ({
        url: 'v2/wallet/point/coupon-apply',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: (_, error) => (error ? [] : ['Wallets']),
    }),
    balanceHistoryList: builder.query<BalanceHistoryListResponse, BalanceHistoryListData>({
      query: data => {
        const filters = data.filters ? Object.values(data.filters) : []
        const balanceFilters = map(filters, (item, index) => 'balance_filters[' + index + ']=' + item).join('&')
        return {
          url: `v2/wallet/balance-history/list?${balanceFilters}`,
          method: 'GET',
          params: data,
        }
      },
      keepUnusedDataFor: 1,
    }),
    balanceHistoryDetails: builder.query<BalanceHistoryDetailsResponse, BalanceHistoryDetailsData>({
      query: data => ({
        url: `v2/wallet/balance-history/details/${data.id}`,
        method: 'GET',
      }),
      providesTags: ['BalanceHistoryDetail'],
    }),
    walletPointWonCampaign: builder.query<WalletPointWonCampaignResponse, WalletPointWonCampaignData>({
      query: data => ({
        url: 'v2/wallet/point/won/campaign',
        method: 'GET',
        params: data,
      }),
    }),
    walletLastEarnedDepositGiftPoints: builder.query<
      WalletLastEarnedDepositGiftPointsResponse,
      WalletLastEarnedDepositGiftPointsData
    >({
      query: data => ({
        url: `v2/wallet/${data.wallet_id}/last-earned-deposit-gift-points`,
        method: 'GET',
      }),
    }),
  }),
  overrideExisting: true,
})

export default walletApi
