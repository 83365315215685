import React from 'react'
import { Helmet } from 'react-helmet'
import type { CampaignDetailHelmetProps } from './CampaignDetail.types'

const CampaignDetailHelmet: React.FC<CampaignDetailHelmetProps> = props => {
  const { title, description, image } = props
  return (
    <Helmet>
      <title>{title} - Macellan SuperApp</title>
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={image} />
      <meta property="og:url" content={window.location.href} />
      <meta property="og:type" content="website" />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={image} />
    </Helmet>
  )
}

export default CampaignDetailHelmet
