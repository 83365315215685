import React from 'react'
import InputMask, { type ReactInputMask } from 'react-input-mask'
import type { TextFieldProps } from './TextField.types'
import styles from './TextField.module.css'
import { useMaskTextInput } from '../TextInput/TextInput.hooks'
import { getClassNames, Helper, Input, Label } from './TextField.utils'
import { Container } from '../Container'

const TextField: React.FC<TextFieldProps> = React.forwardRef<ReactInputMask | HTMLInputElement, TextFieldProps>(
  (props, ref) => {
    const { mask, maskType, maskOptions, ...rest } = props

    const textInputMask = useMaskTextInput(maskType, props.value, maskOptions)

    if (textInputMask || mask) {
      return (
        <Container>
          <div className={`${getClassNames(rest)}`}>
            <Label {...rest} />
            <div className={`${styles.InputContainer}`}>
              <InputMask
                className={`${styles.Input}`}
                {...rest}
                mask={textInputMask ?? mask}
                ref={ref as React.Ref<ReactInputMask>}
              />
            </div>
            <Helper {...rest} />
          </div>
        </Container>
      )
    }
    return (
      <Container>
        <div className={`${getClassNames(props)}`}>
          <Label {...rest} />
          <Input ref={ref as React.Ref<HTMLInputElement>} {...rest} />
          <Helper {...rest} />
        </div>
      </Container>
    )
  },
)

export default TextField
