import React from 'react'
import Iframe from 'react-iframe'
import { useLocation, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import styles from './MasterPass3D.module.css'
import { useCompletePurchase } from '../TopUp/TopUp.hooks'
import baseApi from '@/api'
import toast from '@/context/ToastContext/Toast.utils'
import { useAppDispatch } from '@/store'
import { Header } from '@/components/Header'
import depositApi from '../../api'

const MasterPass3D: React.FC = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const handleCompletePurchase = useCompletePurchase()

  const completed = React.useRef<boolean>(false)

  const [mark3DClosed] = depositApi.useMark3DClosedMutation()

  const handleBack = React.useCallback(() => {
    if (!location.state?.canGoBack) return navigate('/', { replace: true })

    return navigate(location.state?.canGoBack.path, {
      state: {
        ...location.state?.canGoBack,
      },
    })
  }, [location.state?.canGoBack, navigate])

  const handleMessage = (event: MessageEvent) => {
    completed.current = true

    let success: boolean = false

    const result = JSON.parse(event.data.message)

    if (result.status === 1) {
      dispatch(baseApi.util.invalidateTags(['Wallets']))
      success = true
    }

    if (result.status === 0) {
      success = false

      const errorMessage = result.message?.length ? result.message : t('@deposit.MasterPass3D.error_message')
      toast.error({ message: errorMessage, icon: 'warning', duration: 10000 })
    }

    if (success) return handleCompletePurchase(success, location.state?.canGoBack)
    handleBack()
  }

  React.useEffect(
    () => {
      window.addEventListener('message', handleMessage)

      return () => {
        window.removeEventListener('message', handleMessage)

        if (completed) return
        mark3DClosed({ deposit_id: location.state?.depositId })
      }
    }, // eslint-disable-next-line
    [],
  )

  return (
    <React.Fragment>
      <Header
        variant="secondary"
        title={t('@deposit.MasterPass3D.route_header_title')}
        color="black"
        goBack={handleBack}
      />
      <Iframe
        url={location.state.threeDUrl}
        className={styles.Iframe}
        loading="lazy"
        scrolling="no"
        allowFullScreen={true}
      />
    </React.Fragment>
  )
}

export default MasterPass3D
