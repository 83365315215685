import React from 'react'
import { useParams } from 'react-router-dom'
import { last, split } from 'lodash'
import campaignApi from '../../api'
import CampaignDetailContent from './CampaignDetailContent'
import CampaignDetailFooter from './CampaignDetailFooter'
import CampaignDetailHeader from './CampaignDetailHeader'
import CampaignDetailHelmet from './CampaignDetailHelmet'
import CampaignDetailToast from './CampaignDetailToast'
import ConditionParticipation from './ConditionParticipation'
import OtherCampaigns from './OtherCampaigns'

const CampaignDetailPage: React.FC = () => {
  const { slug } = useParams()
  const campaignId = last(split(slug, '-'))

  const campaignDetailQuery = campaignApi.useCampaignDetailsQuery(
    { company_id: Number(campaignId) },
    { refetchOnMountOrArgChange: true },
  )

  const details = React.useMemo(() => {
    return campaignDetailQuery.data?.data.details
  }, [campaignDetailQuery.data?.data.details])

  const otherCampaigns = React.useMemo(() => {
    return campaignDetailQuery.data?.data.other_campaigns
  }, [campaignDetailQuery.data?.data.other_campaigns])

  if (!details || !otherCampaigns) return null

  return (
    <React.Fragment>
      <CampaignDetailHelmet
        title={details.title}
        description={details.campaign.description}
        image={details.background_image}
      />
      <CampaignDetailHeader
        wallet={details?.wallet}
        point={details.campaign.point_amount}
        source={details.background_image}
        logo={details.company.logo}
        title={details.company.name}
        endDate={details.end_date}
        currency={details.company.currency}
      />
      <CampaignDetailContent title={details.title} description={details.html_description} />
      <ConditionParticipation htmlDescription={details.html_participation_terms} />
      <OtherCampaigns otherCampaigns={otherCampaigns} currency={details.company.currency} />
      <CampaignDetailToast
        campaignType={details.campaign.type}
        usageCount={details.campaign_usage_count}
        campaignWon={details.campaign_won}
        userUsed={details.campaign_user_used}
        multipleUsage={details.campaign.multiple_usage}
        processCount={details.campaign_process_count}
        pointAmount={details.campaign.point_amount}
        currency={details.company.currency}
        remainderCount={details.user_process_remainder}
      />
      <CampaignDetailFooter walletId={details.wallet?.id} companyId={details.company_id} />
    </React.Fragment>
  )
}

export default CampaignDetailPage
