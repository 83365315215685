import React from 'react'
import { useNavigate } from 'react-router-dom'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'
import { CurrencyFormatter } from '@macellan/formatter'
import type { CampaignDetailHeaderProps } from './CampaignDetail.types'
import styles from './CampaignDetail.module.css'
import ThemeConfig from '~/tailwind.config'
import { Container } from '@/components/Container'
import { Header } from '@/components/Header'
import { WalletCard } from '@/components/WalletCard'
import i18next from '@/translations'

const CampaignDetailHeader: React.FC<CampaignDetailHeaderProps> = props => {
  const { point, source, logo, title, endDate, currency } = props
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { colors } = ThemeConfig.theme.extend

  const formattedPoint = React.useMemo(() => {
    return CurrencyFormatter.format(point, i18next.getLocale(), currency)
  }, [currency, point])

  const formattedDate = React.useMemo(() => {
    return dayjs(endDate).format('DD MMMM')
  }, [endDate])

  return (
    <React.Fragment>
      <Header
        title={t('@campaign.CampaignDetail.route_header_title')}
        variant="secondary"
        color={'white'}
        logo={logo}
        bgColor={colors.solid.core.transparent}
        divider={false}
        className="!fixed w-full"
        goBack={() => navigate(-1)}
      />
      <WalletCard.Header logo={logo} name={title} source={source} />
      <Container>
        <div className={styles.HeaderContentContainer}>
          <div className={styles.HeaderContentItem}>
            <div>
              <h1>{formattedDate}</h1>
              <h6>{t('@campaign.CampaignDetail.content_header_left_title')}</h6>
            </div>
          </div>
          <div className={styles.HeaderContentItem}>
            <div>
              <h1>{formattedPoint}</h1>
              <h6>{t('@campaign.CampaignDetail.content_header_right_title')}</h6>
            </div>
          </div>
        </div>
      </Container>
    </React.Fragment>
  )
}

export default CampaignDetailHeader
