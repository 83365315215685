import { Container } from '@/components/Container'

const AccountActivityDetailSkeleton: React.FC = () => {
  return (
    <Container>
      <div className="animate-pulse">
        <div className="flex items-center justify-between bg-semantic-background-primary p-4">
          <div className="h-6 w-6 rounded-md bg-semantic-background-tertiary" />
          <div className="flex items-center gap-4">
            <div className="h-6 w-[175px] rounded-md bg-semantic-background-tertiary" />
            <div className="h-8 w-8 rounded-full bg-semantic-background-tertiary" />
          </div>
        </div>
        <div className="px-6 py-4 pb-0 pt-4">
          <div className="flex flex-col gap-2 py-4">
            <div className="h-8 w-[175px] rounded-lg bg-semantic-background-secondary" />
            <div className="h-4 w-full rounded-md bg-semantic-background-secondary" />
          </div>
        </div>
        <div className="border-t border-semantic-background-secondary p-6">
          <div className="mb-3 h-6 w-[120px] rounded-lg bg-semantic-background-secondary" />
          <div className="flex flex-col gap-2">
            {Array.from({ length: 5 }).map((_, index) => (
              <div key={index} className="grid grid-cols-3 gap-6">
                <div className={`col-span-2 h-4 w-full rounded-md bg-semantic-background-secondary`} />
                <div className={`col-span-1 h-4 w-full rounded-md bg-semantic-background-secondary`} />
              </div>
            ))}
          </div>
        </div>
        <div className="border-t border-semantic-background-secondary p-6">
          <div className="mb-3 h-6 w-[120px] rounded-lg bg-semantic-background-secondary" />
          <div className="flex flex-col gap-2">
            {Array.from({ length: 2 }).map((_, index) => (
              <div key={index} className="grid grid-cols-3 gap-6">
                <div className={`col-span-2 h-4 w-full rounded-md bg-semantic-background-secondary`} />
                <div className={`col-span-1 h-4 w-full rounded-md bg-semantic-background-secondary`} />
              </div>
            ))}
          </div>
        </div>
      </div>
    </Container>
  )
}

export default AccountActivityDetailSkeleton
