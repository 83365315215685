import type { InitializeResponse } from '@/api'

export enum RedirectPage {
  Home = 'home',
  Deposit = 'deposit',
}

export interface StateProps {
  authData?: InitializeResponse['data']['auth_data']
  redirectPage?: InitializeResponse['data']['redirect_page']
  walletId?: InitializeResponse['data']['wallet_id']
  companyId?: InitializeResponse['data']['company_id']
  triggerData?: InitializeResponse['data']['trigger_data']
}
