import React from 'react'
import { useTranslation } from 'react-i18next'
import type { MoneyWrapperProps } from './MoneyWrapper.types'
import styles from './MoneyWrapper.module.css'
import { Container } from '@/components/Container'

const MoneyWrapper: React.FC<MoneyWrapperProps> = ({ symbol = '₺', balance = 0 }) => {
  const { t } = useTranslation()

  return (
    <Container>
      <div className={styles.Container}>
        <p className={styles.Balance}>{t('@wallet.WalletDetail.header_title')}</p>
        <div className={styles.MoneyWrapper}>
          <span className={styles.Symbol}>{symbol}</span>
          <span>{balance}</span>
        </div>
      </div>
    </Container>
  )
}

export default MoneyWrapper
