import React from 'react'
import { debounce } from 'lodash'
import cn from 'classnames'
import type { SearchFieldProps } from './SearchField.types'
import styles from './SearchField.module.css'
import ThemeConfig from '~/tailwind.config'
import { Container } from '../Container'
import FilterButton from '../FilterButton/FilterButton'
import { Icon } from '../Icons'
import { Spinner } from '../Spinner'

const SearchField: React.FC<SearchFieldProps> = props => {
  const {
    debounceTimeOut = 500,
    loading,
    onFilterButtonPress,
    onChangeText,
    onChangeDebouncedText,
    className,
    ...rest
  } = props
  const { colors } = ThemeConfig.theme.extend

  // eslint-disable-next-line
  const handleDebouncedText = React.useCallback(
    debounce(text => onChangeDebouncedText?.(text), debounceTimeOut),
    [],
  )

  return (
    <Container>
      <div className={cn(styles.SearchFieldContainer, className)}>
        <div className={styles.SearchFieldContainerLeft}>
          {loading ? <Spinner size={24} /> : <Icon name="search" size={24} color={colors.semantic.content.disabled} />}
        </div>
        <input
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            onChangeText?.(e.target.value)
            handleDebouncedText(e.target.value)
          }}
          {...rest}
        />
        {onFilterButtonPress && (
          <div className={styles.SearchFieldContainerRight}>
            <FilterButton onClick={onFilterButtonPress} />
          </div>
        )}
      </div>
    </Container>
  )
}

export default SearchField
