import React from 'react'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import type { CountryCode } from 'libphonenumber-js'
import { find } from 'lodash'
import { useTranslation } from 'react-i18next'
import type { AuthType, StateProps } from './Auth.types'
import { authSubmitByType, getAuthRouteTitle } from './Auth.utils'
import ThemeConfig from '~/tailwind.config'
import { Button } from '@/components/Button'
import { ContentHeader } from '@/components/ContentHeader'
import { Header } from '@/components/Header'
import { PhoneField } from '@/components/PhoneField'
import { Section } from '@/components/Section'
import { TimerUtils } from '@/utils'
import { CountryConstants } from '@/constants'

const Auth: React.FC = () => {
  const navigate = useNavigate()
  const { state } = useLocation() as StateProps
  const [searchParams, setSearchParams] = useSearchParams()
  const { t } = useTranslation()
  const { colors } = ThemeConfig.theme.extend

  const [loading, setLoading] = React.useState<boolean>(false)
  const [isValid, setValid] = React.useState<boolean>(false)
  const [phone, setPhone] = React.useState<string>('')
  const [defaultCountryCode, setDefaultCountryCode] = React.useState<CountryCode>('TR')

  const actionType = React.useMemo(() => {
    const params = searchParams.get('redirectPage') as AuthType
    const stateType = state?.redirectPage
    return params || stateType || 'login'
  }, [searchParams, state?.redirectPage])

  const handleDefaultCountryCode = React.useCallback(
    () => {
      if (state?.authData?.phone?.country_code) {
        const defaultCountryCode = find(CountryConstants.phones, { key: String(state?.authData?.phone?.country_code) })
        return setDefaultCountryCode(defaultCountryCode?.code as CountryCode)
      }

      const defaultCountryCode = navigator.language.split('-')[1] as CountryCode
      return setDefaultCountryCode(defaultCountryCode)
    },
    // eslint-disable-next-line
    [],
  )

  const handleSubmitAuth = React.useCallback(
    async (type: AuthType) => {
      setLoading(true)

      await authSubmitByType({ phone: phone })
        .then(response => {
          const duration = TimerUtils.calculateDuration(response.data.expires_at, response.data.server_time)

          navigate('/otp-verify', {
            state: {
              authData: state?.authData,
              actionType: type,
              phone: phone,
              duration: duration,
              code: response.data.code,
              walletId: state?.walletId,
              companyId: state?.companyId,
            },
          })
        })
        .catch(error => {
          if (type === 'register' && error.status === 406) {
            setSearchParams({ redirectPage: 'login' })
            return handleSubmitAuth('login')
          }
        })
        .finally(() => setLoading(false))
    },
    [navigate, phone, setSearchParams, state?.companyId, state?.walletId, state?.authData],
  )

  React.useLayoutEffect(() => {
    handleDefaultCountryCode()
  }, [handleDefaultCountryCode])

  return (
    <React.Fragment>
      <Header variant="secondary" title={getAuthRouteTitle()} />
      <Section>
        <ContentHeader
          className="mb-10 gap-4"
          title={t('@user.Auth.content_header_title')}
          description={t(`@user.Auth.content_header_${actionType}_description`)}
        />
        <PhoneField
          defaultCountryCode={defaultCountryCode}
          defaultValue={phone || state?.authData?.phone?.national_number}
          textFieldStyle={{ color: colors.semantic.content.ink }}
          textFieldProps={{ disabled: true }}
          dropdownFieldProps={{ disabled: true }}
          onChangeValue={setPhone}
          onValidation={setValid}
        />
        <div className="mt-8 flex justify-end">
          <Button
            variant="primary"
            size="large"
            radiusType="rounded"
            className="w-[140px]"
            loading={loading}
            disabled={!isValid || loading}
            onClick={() => handleSubmitAuth(actionType)}>
            {t('@user.Auth.submit_button')}
          </Button>
        </div>
      </Section>
    </React.Fragment>
  )
}

export default Auth
