import type { RadioButtonProps } from './RadioGroup.types'
import styles from './RadioGroup.module.css'
import { Container } from '../Container'

const RadioButton: React.FC<RadioButtonProps> = porps => {
  // eslint-disable-next-line
  const { value, size, ...rest } = porps

  return (
    <Container>
      <div className={styles.RadioButtonContainer}>
        <input readOnly type="radio" checked={value} className={styles.RadioButtonInput} {...rest} />
        <label htmlFor="radioButton" className={styles.RadioButtonLabel}></label>
      </div>
    </Container>
  )
}
export default RadioButton
