export default {
  CompanyWalletSubscribe: {
    route_header_title: 'Add Wallet',
    add_button_title: 'Add Wallet',
    agreement_title: 'Wallet Agreement',
    agreement_description:
      'By adding the wallet, you are deemed to have accepted the <0 href="{{agreement_url}}">Wallet Agreement</0>',
    success_message: 'Wallet created successfully',
  },
}
