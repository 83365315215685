import React from 'react'
import cn from 'classnames'
import type { ButtonProps } from './Button.types'
import styles from './Button.module.css'
import { ButtonSize, ButtonType, getIconComponents, getRadiusStyle, IconColor, IconSize } from './Button.utils'
import { Container } from '../Container'
import { Spinner } from '../Spinner'

const Button: React.FC<ButtonProps> = props => {
  const {
    variant = 'primary',
    size = 'small',
    radiusType = 'rounded',
    children,
    loading,
    disabled,
    leftIcon,
    rightIcon,
    className,
    containerClassName,
    ...rest
  } = props

  const { leftIconComponent, rightIconComponent } = getIconComponents({
    leftIcon,
    rightIcon,
    iconSize: IconSize[size],
    iconColor: IconColor[variant],
  })

  const ButtonConnectClassnames = cn(
    `${styles.Btn} ${ButtonType[variant]} ${getRadiusStyle(radiusType, size)} ${ButtonSize[size]}`,
    className,
  )

  return (
    <Container className={containerClassName || 'grid'}>
      <button className={ButtonConnectClassnames} disabled={loading || disabled} {...rest}>
        {!loading && leftIconComponent}
        {!loading && children}
        {!loading && rightIconComponent}
        {loading && <Spinner size={IconSize[size]} />}
      </button>
    </Container>
  )
}

export default Button
