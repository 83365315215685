import dayjs from 'dayjs'
import { CurrencyFormatter } from '@macellan/formatter'
import type { DetailItemSection } from './AccountActivityDetail.types'
import i18next from '@/translations'
import type { BalanceHistoryDetailsResponse } from '../../api'

import type { TFunction } from 'i18next'

export const useDetailsByType = (t: TFunction, detail?: BalanceHistoryDetailsResponse['data']) => {
  if (!detail) return []

  const transactionDetails: DetailItemSection[] = []
  const branchDetails: DetailItemSection[] = []
  const additionalDetails: DetailItemSection[] = []

  if (detail.branch?.name) {
    branchDetails.push({
      title: t('@wallet.AccountActivityDetail.branch_name'),
      value: detail.branch.name,
    })
  }

  if (detail.cashier?.full_name) {
    branchDetails.push({
      title: t('@wallet.AccountActivityDetail.cashier'),
      value: detail.cashier.full_name,
    })
  }

  if (detail.total_earned_points) {
    additionalDetails.push({
      title: t('@wallet.AccountActivityDetail.total_earned_points'),
      value: CurrencyFormatter.format(detail.total_earned_points, i18next.getLocale(), detail.wallet.company.currency),
    })
  }

  detail.earned_points?.map(item => {
    additionalDetails.push(
      ...[
        {
          title: t('@wallet.AccountActivityDetail.point_group'),
          value: item.win_name,
        },
        {
          title: t('@wallet.AccountActivityDetail.earned_points'),
          value: CurrencyFormatter.format(item.amount, i18next.getLocale(), detail.wallet.company.currency),
        },
      ],
    )

    if (item.target_company) {
      additionalDetails.push({
        title: t('@wallet.AccountActivityDetail.earned_wallet'),
        value: item.target_company.name,
      })
    }
  })

  if (detail.type === 'capture') {
    transactionDetails.push(
      ...[
        {
          title: t('@wallet.AccountActivityDetail.process_type'),
          value: t('@wallet.AccountActivityDetail.capture'),
        },
        {
          title: t('@wallet.AccountActivityDetail.spent_amount'),
          value: CurrencyFormatter.format(
            detail.historyable.final_total ?? 0,
            i18next.getLocale(),
            detail.wallet.company.currency,
          ),
        },
        {
          title: t('@wallet.AccountActivityDetail.order_id'),
          value: detail.historyable.order_id ?? 0,
        },
        {
          title: t('@wallet.AccountActivityDetail.date'),
          value: dayjs(detail.historyable.payment_time).format('DD.MM.YYYY - HH:mm'),
        },
      ],
    )
    if (detail.historyable.point) {
      transactionDetails.push(
        ...[
          {
            title: t('@wallet.AccountActivityDetail.spent_point'),
            value: CurrencyFormatter.format(
              detail.historyable.point,
              i18next.getLocale(),
              detail.wallet.company.currency,
            ),
          },
        ],
      )
    }

    if (detail.is_wallet_share) {
      additionalDetails.push({
        title: t('@wallet.AccountActivityDetail.wallet_type'),
        value: t('@wallet.AccountActivityDetail.shared_wallet'),
      })
    }

    if (detail.sharer) {
      additionalDetails.push({
        title: t('@wallet.AccountActivityDetail.sharer'),
        value: detail.sharer as string,
      })
    }

    if (detail.shared) {
      additionalDetails.push({
        title: t('@wallet.AccountActivityDetail.shared'),
        value: detail.shared as string,
      })
    }

    if (detail.ws_expire_date) {
      additionalDetails.push({
        title: t('@wallet.AccountActivityDetail.expire_date'),
        value: dayjs(detail.ws_expire_date).format('DD.MM.YYYY - HH:mm'),
      })
    }

    if (detail.description === 'iap') {
      additionalDetails.push(
        ...[
          {
            title: t('@wallet.AccountActivityDetail.description'),
            value: t('@wallet.AccountActivityDetail.gift_card'),
          },
          {
            title: t('@wallet.AccountActivityDetail.shared'),
            value:
              detail.historyable?.related?.receiver?.full_name ||
              detail.historyable?.related?.receiver_phone ||
              t('@wallet.AccountActivityDetail.undefined_user'),
          },
        ],
      )
    }
  }

  if (detail.type === 'top-up') {
    transactionDetails.push(
      ...[
        {
          title: t('@wallet.AccountActivityDetail.process_type'),
          value: t('@wallet.AccountActivityDetail.top_up'),
        },
        {
          title: t('@wallet.AccountActivityDetail.loaded_amount'),
          value: CurrencyFormatter.format(detail.amount, i18next.getLocale(), detail.wallet.company.currency),
        },
        {
          title: t('@wallet.AccountActivityDetail.reference_code'),
          value: detail.historyable.reference_code ?? 0,
        },
        {
          title: t('@wallet.AccountActivityDetail.date'),
          value: dayjs(detail.historyable.created_at).format('DD.MM.YYYY - HH:mm'),
        },
      ],
    )

    if (detail.description === 'cash') {
      transactionDetails.push({
        title: t('@wallet.AccountActivityDetail.loaded_type'),
        value: t('@wallet.AccountActivityDetail.cash'),
      })
    } else if (detail.description === 'turkcell_dcb') {
      transactionDetails.push({
        title: t('@wallet.AccountActivityDetail.loaded_type'),
        value: t('@wallet.AccountActivityDetail.dcb'),
      })
    } else {
      transactionDetails.push(
        ...[
          {
            title: t('@wallet.AccountActivityDetail.loaded_type'),
            value: detail.description?.toLocaleUpperCase() ?? '',
          },
          {
            title: t('@wallet.AccountActivityDetail.card_number'),
            value: detail.historyable.card_number,
          },
        ],
      )
    }
  }

  if (detail.type === 'payment_refund') {
    transactionDetails.push(
      ...[
        {
          title: t('@wallet.AccountActivityDetail.process_type'),
          value: t('@wallet.AccountActivityDetail.payment_refund'),
        },
        {
          title: t('@wallet.AccountActivityDetail.refunded_amount'),
          value: CurrencyFormatter.format(
            detail.historyable.amount ?? 0,
            i18next.getLocale(),
            detail.wallet.company.currency,
          ),
        },
        {
          title: t('@wallet.AccountActivityDetail.reference_code'),
          value: detail.historyable.refundRequest?.reference_code ?? '',
        },
        {
          title: t('@wallet.AccountActivityDetail.date'),
          value: dayjs(detail.historyable.created_at).format('DD.MM.YYYY - HH:mm'),
        },
      ],
    )

    if (detail.historyable?.refundRequest?.reason_translate) {
      additionalDetails.push({
        title: t('@wallet.AccountActivityDetail.description'),
        value: detail.historyable.refundRequest.reason_translate,
      })
    }
  }

  if (detail.type === 'deposit_refund') {
    transactionDetails.push(
      ...[
        {
          title: t('@wallet.AccountActivityDetail.process_type'),
          value: t('@wallet.AccountActivityDetail.deposit_refund'),
        },
        {
          title: t('@wallet.AccountActivityDetail.refunded_amount'),
          value: CurrencyFormatter.format(detail.amount ?? 0, i18next.getLocale(), detail.wallet.company.currency),
        },
        {
          title: t('@wallet.AccountActivityDetail.bank_amount'),
          value: CurrencyFormatter.format(
            detail.historyable.bank_amount_float ?? 0,
            i18next.getLocale(),
            detail.wallet.company.currency,
          ),
        },
        {
          title: t('@wallet.AccountActivityDetail.point_deducted_balance'),
          value: CurrencyFormatter.format(
            detail.historyable.point_deducted_balance_float ?? 0,
            i18next.getLocale(),
            detail.wallet.company.currency,
          ),
        },
        {
          title: t('@wallet.AccountActivityDetail.reference_code'),
          value: detail.historyable.reference_code ?? '',
        },
        {
          title: t('@wallet.AccountActivityDetail.date'),
          value: dayjs(detail.historyable.refunded_date).format('DD.MM.YYYY - HH:mm'),
        },
      ],
    )
  }

  if (detail.type === 'cash_deposit_refund') {
    transactionDetails.push(
      ...[
        {
          title: t('@wallet.AccountActivityDetail.process_type'),
          value: t('@wallet.AccountActivityDetail.cash_deposit_refund'),
        },
        {
          title: t('@wallet.AccountActivityDetail.loaded_amount'),
          value: CurrencyFormatter.format(
            detail.historyable.amount ?? 0,
            i18next.getLocale(),
            detail.wallet.company.currency,
          ),
        },
        {
          title: t('@wallet.AccountActivityDetail.refunded_amount'),
          value: CurrencyFormatter.format(detail.amount ?? 0, i18next.getLocale(), detail.wallet.company.currency),
        },
        {
          title: t('@wallet.AccountActivityDetail.date'),
          value: dayjs(detail.created_at).format('DD.MM.YYYY - HH:mm'),
        },
      ],
    )
  }

  if (detail.historyable?.installment_count > 1) {
    transactionDetails.push(
      {
        title: t('@wallet.AccountActivityDetail.installment_count'),
        value: detail.historyable?.installment_count.toString(),
      },
      {
        title: t('@wallet.AccountActivityDetail.total_installment_amount'),
        value: CurrencyFormatter.format(
          detail.historyable.final_total_float,
          i18next.getLocale(),
          detail.wallet.company.currency,
        ),
      },
    )
  }

  return [
    transactionDetails.length
      ? {
          title: t('@wallet.AccountActivityDetail.transaction_details'),
          sections: transactionDetails,
        }
      : undefined,
    branchDetails.length
      ? {
          title: t('@wallet.AccountActivityDetail.branch_details'),
          sections: branchDetails,
        }
      : undefined,
    additionalDetails.length
      ? {
          title: t('@wallet.AccountActivityDetail.additional_details'),
          sections: additionalDetails,
        }
      : undefined,
  ]
}
