import type { IconsNameType } from '../Icons/Icon.types'
import type { ButtonAppearanceProps, ButtonSizeProps, GetIconComponentProps } from './Button.types'
import styles from './Button.module.css'
import ThemeConfig from '~/tailwind.config'
import { Icon } from '../Icons'

const { colors } = ThemeConfig.theme.extend

export const getIconComponents = (props: GetIconComponentProps) => {
  const { leftIcon, rightIcon, iconSize, iconColor } = props

  const generateIcon = (iconName: IconsNameType) => <Icon name={iconName} size={iconSize} color={iconColor} />

  return {
    leftIconComponent: leftIcon && generateIcon(leftIcon),
    rightIconComponent: rightIcon && generateIcon(rightIcon),
  }
}

export const ButtonType = {
  'primary': styles.Primary,
  'primary-tonal': styles.PrimaryTonal,
  'secondary': styles.Secondary,
  'secondary-on-dark': styles.SecondaryOnDark,
  'ink': styles.Ink,
  'ink-tonal': styles.InkTonal,
  'ink-inverse': styles.InkInverse,
  'ghost-on-dark': styles.GhostOnDark,
  'ghost-on-light': styles.GhostOnLight,
  'bg-blurred': styles.BgBlurred,
}

export const ButtonSize = {
  tiny: styles.Tiny,
  small: styles.Small,
  medium: styles.Medium,
  large: styles.Large,
}

export const ButtonAppearance = {
  pill: styles.Pill,
  rounded: {
    tiny: styles.RoundedTiny,
    small: styles.RoundedSmall,
    medium: styles.RoundedMedium,
    large: styles.RoundedLarge,
  },
}

export const getRadiusStyle = (radiusType: ButtonAppearanceProps, size: ButtonSizeProps) =>
  radiusType === 'pill' ? ButtonAppearance.pill : ButtonAppearance.rounded[size] ?? ButtonAppearance.pill

export const IconSize = {
  tiny: 12,
  small: 16,
  medium: 24,
  large: 24,
}

export const IconColor = {
  'primary': colors.solid.core.white,
  'primary-tonal': colors.solid.brand[5],
  'secondary': colors.solid.ink[9],
  'secondary-on-dark': colors.semantic.content.inkInverse,
  'ink': colors.semantic.content.inkInverse,
  'ink-tonal': colors.semantic.content.ink,
  'ink-inverse': colors.semantic.content.ink,
  'ghost-on-dark': colors.semantic.content.inkInverse,
  'ghost-on-light': colors.semantic.content.ink,
  'bg-blurred': colors.semantic.content.ink,
}
