import React from 'react'
import type { PinInputProps } from './PinInput.types'
import styles from './PinInput.module.css'
import { Container } from '../Container'

const PinInput: React.FC<PinInputProps> = props => {
  const { value = '', inputLength = 6, onChangeValue, onSubmitValue, ...rest } = props
  const textInput = React.useRef<HTMLInputElement | null>(null)

  const [pinValues, setPinValues] = React.useState<string[]>([])

  const handleFocusTextInput = React.useCallback(() => {
    textInput.current?.focus()
  }, [])

  React.useEffect(() => {
    setPinValues(value.split(''))

    if (value.length === inputLength && onSubmitValue) {
      onSubmitValue(value)
    }
  }, [value, inputLength, onSubmitValue])

  return (
    <Container>
      <div className={styles.PinInputContainer} onClick={handleFocusTextInput}>
        <input
          ref={textInput}
          className={styles.PinHiddenInput}
          value={value}
          maxLength={inputLength}
          type="number"
          inputMode="numeric"
          pattern="[0-9]*"
          onChange={e => onChangeValue(e.target.value)}
          {...rest}
        />
        {[...Array(inputLength).keys()].map((_item, index) => (
          <div
            key={index.toString()}
            className={`${styles.PinTextInputContainer} ${
              pinValues[index]?.length ? styles.Active : styles.NotActive
            }  ${
              pinValues[index]?.length
                ? styles.ActiveBorder
                : pinValues.length === index
                  ? styles.SetActiveBorder
                  : styles.NotActiveBorder
            }`}>
            <span>{pinValues[index]}</span>
          </div>
        ))}
      </div>
    </Container>
  )
}

export default PinInput
