import * as React from 'react'
import cn from 'classnames'
import type { DepositCardLoaderProps } from './DepositCard.types'
import styles from './DepositCard.module.css'

const DepositCardLoader: React.FC<DepositCardLoaderProps> = props => {
  const { className, logoSize = 48, description = true, ...rest } = props
  return (
    <div className={cn(styles.Skeleton, className)} {...rest}>
      <div className={styles.SkeletonContent}>
        <div className={styles.SkeletonLogo} style={{ width: logoSize, height: logoSize }} />
        <div>
          <div className={styles.SkeletonHeading} />
          {description ? <div className={styles.SkeletonDescription} /> : null}
        </div>
      </div>
    </div>
  )
}

DepositCardLoader.displayName = 'DepositCard.Loader'

export default DepositCardLoader
