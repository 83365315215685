import React from 'react'
import ToastContext from '@/context/ToastContext/ToastContext'

const useToast = () => {
  const context = React.useContext(ToastContext)
  if (context === undefined) {
    throw new Error('useToast must be used within a ToastProvider')
  }
  return context
}

export default useToast
