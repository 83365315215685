import React from 'react'
import { useTranslation } from 'react-i18next'
import type { AmountSectionProps } from './TopUp.types'
import { Row } from '@/components/Row'
import { Section } from '@/components/Section'
import { DepositChips } from '../../components/DepositChips'

const AmountSection: React.FC<AmountSectionProps> = props => {
  const { amount, onChangeValue, walletId } = props
  const { t } = useTranslation()

  return (
    <React.Fragment>
      <Row className="flex flex-col gap-4 pb-4 pt-10">
        <Section className="!py-0">
          <Row.Title title={t('@deposit.TopUp.footer_balance_title')} />
        </Section>
        <DepositChips amount={amount} onChangeValue={onChangeValue} walletId={walletId} />
      </Row>
    </React.Fragment>
  )
}

export default AmountSection
