import type { IconsNameType } from '../Icons/Icon.types'
import type { GetBadgeIconComponentProps } from './Badge.types'
import styles from './Badge.module.css'
import ThemeConfig from '~/tailwind.config'
import { Icon } from '../Icons'

const { colors } = ThemeConfig.theme.extend

export const BadgeSize = {
  tiny: styles.Tiny,
  small: styles.Small,
  medium: styles.Medium,
  large: styles.Large,
  huge: styles.Huge,
}

export const BadgeType = {
  'brand': styles.Brand,
  'brand-tonal': styles.BrandTonal,
  'ink': styles.Ink,
  'ink-tonal': styles.InkTonal,
  'yellow': styles.Yellow,
  'ink-inverse': styles.InkInverse,
  'ink-inverse-tonal': styles.InkInverseTonal,
  'outlined': styles.Outlined,
  'bg-blurred': styles.BgBlurred,
}

export const IconSize = {
  tiny: 12,
  small: 16,
  medium: 16,
  large: 24,
  huge: 24,
}

export const getBadgetIconComponents = (props: GetBadgeIconComponentProps) => {
  const { icon, iconSize, iconColor } = props

  const generateIcon = (icon: IconsNameType) => <Icon name={icon} size={iconSize} color={iconColor} />

  return {
    leftIconComponent: icon && generateIcon(icon),
  }
}

export const IconColor = {
  'brand': colors.solid.core.white,
  'brand-tonal': colors.solid.brand[5],
  'ink': colors.semantic.content.inkInverse,
  'ink-tonal': colors.semantic.content.inkLight,
  'yellow': colors.semantic.content.ink,
  'ink-inverse': colors.semantic.content.ink,
  'ink-inverse-tonal': colors.semantic.content.inkInverse,
  'outlined': colors.semantic.content.ink,
  'bg-blurred': colors.solid.brand[5],
}
