import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import styles from './WalletAgreement.module.css'
import { Header } from '@/components/Header'
import { Section } from '@/components/Section'
import companyApi from '@/pages/company/api/CompanyApi'

const WalletAgreement: React.FC = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const { t } = useTranslation()

  const detailsQuery = companyApi.useDetailsQuery({
    company_id: location.state.companyId,
  })

  if (!detailsQuery.data) return null

  return (
    <React.Fragment>
      <Header
        variant="secondary"
        title={t('@wallet.WalletAgreement.route_header_title')}
        bgColor={detailsQuery.data.data.color}
        color="white"
        divider={false}
        goBack={() => navigate(-1)}
      />
      <Section>
        <div
          className={styles.WalletAgreement}
          dangerouslySetInnerHTML={{ __html: detailsQuery.data.data.agreement.html }}
        />
      </Section>
    </React.Fragment>
  )
}

export default WalletAgreement
