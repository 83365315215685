import React from 'react'
import type { DepositCardProps, DepositCardStaticProperties } from './DepositCard.types'
import styles from './DepositCard.module.css'
import ThemeConfig from '~/tailwind.config'
import DepositCardOther from '@/components/DepositCard/DepositCardOther.tsx'
import { Icon } from '@/components/Icons'
import { Container } from '../Container'
import RadioButton from '../RadioGroup/RadioButton'
import DepositCardLoader from './DepositCardLoader'

const DepositCard: React.FC<DepositCardProps> & DepositCardStaticProperties = props => {
  const { colors } = ThemeConfig.theme.extend
  const { title, selected, avatarImageSource, description, LayoutRightComponent, layoutRightIconProps, ...rest } = props

  const LayoutRightDefaultComponent = React.useMemo(() => {
    return <Icon name="caret-right" size={24} color={colors.semantic.content.inkLight} {...layoutRightIconProps} />
  }, [colors.semantic.content.inkLight, layoutRightIconProps])

  return (
    <Container>
      <div className={styles.DepositCard}>
        <button className={styles.DepositCardLeft} {...rest}>
          {selected !== undefined ? <RadioButton value={selected} size="small" /> : null}
          <img src={avatarImageSource} alt={title} />
          <div className={styles.DepositCardLeftContent}>
            <h6>{title}</h6>
            <p>{description}</p>
          </div>
        </button>
        <div className={styles.DepositCardRight}>
          {LayoutRightComponent ? LayoutRightComponent : LayoutRightDefaultComponent}
        </div>
      </div>
    </Container>
  )
}

DepositCard.displayName = 'DepositCard'
DepositCard.Loader = DepositCardLoader
DepositCard.Other = DepositCardOther

export default DepositCard
