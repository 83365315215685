import React from 'react'
import { useNavigate } from 'react-router-dom'
import { filter, kebabCase, map } from 'lodash'
import { useTranslation } from 'react-i18next'
import type { CampaignSegmentedListProps, CampaignSegmentedListTabKey } from './CampaignSegmentedList.types'
import { CampaignItem } from '@/components/CampaignItem'
import { EmblaCarousel } from '@/components/EmblaCarousel'
import type { SegmentedItem } from '@/components/SegmentedControl'
import { SegmentedControl } from '@/components/SegmentedControl'
import type { CampaignNewsListItem } from '@/pages/campaign/api'
import campaignApi from '@/pages/campaign/api'

const CampaignSegmentedList: React.FC<CampaignSegmentedListProps> = ({ companyId }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [activeTab, setActiveTab] = React.useState<CampaignSegmentedListTabKey | null>(null)

  const campaignNewsListQuery = campaignApi.useCampaignNewsListQuery(
    { page: 1, company_id: companyId, campaign_types: undefined },
    { refetchOnMountOrArgChange: true },
  )

  const campaignNewsListData = React.useMemo(() => {
    return campaignNewsListQuery.data?.data ?? []
  }, [campaignNewsListQuery.data?.data])

  const depositGiftCampaignNewsList = React.useMemo(() => {
    return filter(
      campaignNewsListData,
      (campaignNews: CampaignNewsListItem) =>
        campaignNews.campaign.type === 'deposit_gift' || campaignNews.campaign.type === 'cross_marketing_deposit_gift',
    )
  }, [campaignNewsListData])

  const paymentCountGiftCampaignNewsList = React.useMemo(() => {
    return filter(
      campaignNewsListData,
      (campaignNews: CampaignNewsListItem) => campaignNews.campaign.type === 'payment_count_gift',
    )
  }, [campaignNewsListData])

  const filtered = React.useMemo(() => {
    if (activeTab === 'deposit_gift') return depositGiftCampaignNewsList

    if (activeTab === 'payment_count_gift') return paymentCountGiftCampaignNewsList

    return []
  }, [activeTab, depositGiftCampaignNewsList, paymentCountGiftCampaignNewsList])

  const segmentedTabItems = React.useMemo(() => {
    const tabs = []

    if (depositGiftCampaignNewsList.length) {
      tabs.push({
        key: 'deposit_gift',
        label: t('@campaign.CampaignSegmentedList.segmented_section_topup_title'),
      })
    }

    if (paymentCountGiftCampaignNewsList.length) {
      tabs.push({
        key: 'payment_count_gift',
        label: t('@campaign.CampaignSegmentedList.segmented_section_capture_title'),
      })
    }

    return tabs
  }, [depositGiftCampaignNewsList.length, paymentCountGiftCampaignNewsList.length, t])

  const handleOnChangeItem = React.useCallback((item: SegmentedItem) => {
    setActiveTab(item.key as CampaignSegmentedListTabKey)
  }, [])

  React.useEffect(() => {
    setActiveTab(() => {
      if (depositGiftCampaignNewsList.length) return 'deposit_gift'

      if (paymentCountGiftCampaignNewsList.length) return 'payment_count_gift'

      return null
    })
  }, [depositGiftCampaignNewsList.length, paymentCountGiftCampaignNewsList.length])

  if (!segmentedTabItems.length && !filtered.length) return null

  return (
    <React.Fragment>
      <SegmentedControl.Tab activeTabKey={activeTab} items={segmentedTabItems} onChangeItem={handleOnChangeItem} />
      <EmblaCarousel slideSize={50}>
        {map(filtered, (item: CampaignNewsListItem) => (
          <CampaignItem.Box
            key={item.id}
            point={item.campaign.point_amount}
            currency={item.company.currency}
            description={item.title}
            isUsed={!item.campaign.multiple_usage && item.campaign.user_used}
            actionButtonClick={() => {
              navigate(`/campaign/${kebabCase(item.title)}-${item.id}`)
            }}
          />
        ))}
      </EmblaCarousel>
    </React.Fragment>
  )
}

export default CampaignSegmentedList
