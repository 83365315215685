import bkmLogo from '@/assets/images/BKMLogo.png'
import masterpassLogo from '@/assets/images/MasterPassLogo.jpeg'
import paycellLogo from '@/assets/images/PaycellLogo.png'

const Titles = {
  masterpass: 'MasterPass',
  paycell: 'Paycell',
  bkm: 'BKM Express',
}

const Logos = {
  masterpass: masterpassLogo,
  paycell: paycellLogo,
  bkm: bkmLogo,
}

export default {
  Titles: Titles,
  Logos: Logos,
}
