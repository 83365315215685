import * as React from 'react'
import type { InstallmentCardFooterProps } from './InstallmentCard.types'
import { IconButton } from '@/components/IconButton'

const InstallmentCardFooter: React.FC<InstallmentCardFooterProps> = ({ canOpen, isOpened, buttonText, ...props }) => {
  return (
    <div className="z-10 flex gap-2 bg-white py-5" {...props}>
      <span className="text-body-lg-b text-semantic-content-ink">{!isOpened ? buttonText.more : buttonText.hide}</span>
      {canOpen ? (
        <IconButton
          name={!isOpened ? 'caret-down' : 'caret-up'}
          size={12}
          className="!h-6 !w-6 bg-semantic-background-primary"
        />
      ) : null}
    </div>
  )
}
export default InstallmentCardFooter
