import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { EmptySheet } from '@/components/EmptySheet'

const CallbackWaiting: React.FC = () => {
  const { t } = useTranslation()

  return (
    <EmptySheet
      className="!mt-0 h-screen-size"
      loading={true}
      icon="qr-scan"
      title={t('@payment.Capture.empty_sheet_title')}
      description={t('@payment.Capture.empty_sheet_description')}
    />
  )
}

export default CallbackWaiting
