import React from 'react'
import { useTranslation } from 'react-i18next'
import type { CaptureWalletProps } from './Capture.types'
import styles from './Capture.module.css'
import toast from '@/context/ToastContext/Toast.utils'
import { Row } from '@/components/Row'
import { Section } from '@/components/Section'
import { Switch } from '@/components/Switch'
import { WalletCard } from '@/components/WalletCard'
import type { WalletSharer } from '@/pages/wallet/api'
import paymentApi from '../../api/PaymentApi'
import DepositToast from './DepositToast'

const CaptureWallet: React.FC<CaptureWalletProps> = props => {
  const { paymentId, payment, wallet, status, onChangePointUsage, onRefectWallet } = props
  const { t } = useTranslation()

  const [shared] = React.useState<WalletSharer | null>(null)
  const [isPointChecked, setPointChecked] = React.useState<boolean>(false)
  const [disabledPointChecked, setDisabledPointChecked] = React.useState<boolean>(false)

  const [assignWallet] = paymentApi.useAssignWalletMutation()
  const [preApproval, preApprovalResult] = paymentApi.useLazyPreApprovalQuery()

  const pointUsage = React.useMemo(() => {
    return preApprovalResult.data?.data.point_usage
  }, [preApprovalResult.data?.data.point_usage])

  const depositAmount = React.useMemo(() => {
    const preApprovalResultData = preApprovalResult.data?.data

    if (!preApprovalResultData) return 0
    return isPointChecked ? preApprovalResultData.deposit_point_and_balance : preApprovalResultData.deposit_balance
  }, [isPointChecked, preApprovalResult.data?.data])

  const handlePointChecked = React.useCallback(
    (value: boolean) => {
      if (!pointUsage) return

      if (!pointUsage.status) {
        toast.default({ message: pointUsage.message, icon: 'warning' })
        setDisabledPointChecked(true)
      }

      const checkResult = pointUsage.status ? value : false
      setPointChecked(checkResult)
      onChangePointUsage(checkResult)
    },
    [onChangePointUsage, pointUsage],
  )

  React.useEffect(() => {
    if (status !== 'approval') return

    assignWallet({
      paymentId: paymentId,
      wallet_id: wallet.id,
      wallet_share_id: shared ? shared.id : undefined,
    }).then(() => preApproval({ paymentId: paymentId }))
  }, [paymentId, preApproval, shared, assignWallet, status, wallet.id])

  return (
    <Section className="!pt-10">
      <Row className="mb-4 flex flex-col gap-3">
        <Row.Title title={t('@payment.Capture.wallet_row_title')} />
        <WalletCard.Loyalty
          name={wallet.company.name}
          logo={wallet.company.logo}
          color={wallet.company.color}
          amount={wallet.balance}
          point={wallet.point}
          currency={wallet.company.currency}
          disabled={!wallet.shared.length}
        />
      </Row>
      <div className="flex items-center justify-between">
        <span className={styles.CaptureWalletSwitchTitle}>{t('@payment.Capture.wallet_switch_title')}</span>
        <Switch disabled={disabledPointChecked} value={isPointChecked} onChangeValue={handlePointChecked} />
      </div>
      <DepositToast
        walletId={wallet.id}
        depositAmount={depositAmount}
        visible={!shared}
        getUpdatedWallet={onRefectWallet}
        payment={payment}
        wallet={wallet}
      />
    </Section>
  )
}

export default CaptureWallet
