const phones = [
  { key: '93', text: '+93', title: 'Afghanistan', description: '+93', code: 'AF' },
  { key: '358', text: '+358', title: 'Aland Islands', description: '+358', code: 'AX' },
  { key: '355', text: '+355', title: 'Albania', description: '+355', code: 'AL' },
  { key: '213', text: '+213', title: 'Algeria', description: '+213', code: 'DZ' },
  {
    key: '1684',
    text: '+1684',
    title: 'American Samoa',
    description: '+1684',
    code: 'AS',
  },
  { key: '376', text: '+376', title: 'Andorra', description: '+376', code: 'AD' },
  { key: '244', text: '+244', title: 'Angola', description: '+244', code: 'AO' },
  { key: '1264', text: '+1264', title: 'Anguilla', description: '+1264', code: 'AI' },
  { key: '672', text: '+672', title: 'Antarctica', description: '+672', code: 'AQ' },
  {
    key: '1268',
    text: '+1268',
    title: 'Antigua and Barbuda',
    description: '+1268',
    code: 'AG',
  },
  { key: '54', text: '+54', title: 'Argentina', description: '+54', code: 'AR' },
  { key: '374', text: '+374', title: 'Armenia', description: '+374', code: 'AM' },
  { key: '297', text: '+297', title: 'Aruba', description: '+297', code: 'AW' },
  { key: '61', text: '+61', title: 'Australia', description: '+61', code: 'AU' },
  { key: '43', text: '+43', title: 'Austria', description: '+43', code: 'AT' },
  { key: '994', text: '+994', title: 'Azerbaijan', description: '+994', code: 'AZ' },
  { key: '1242', text: '+1242', title: 'Bahamas', description: '+1242', code: 'BS' },
  { key: '973', text: '+973', title: 'Bahrain', description: '+973', code: 'BH' },
  { key: '880', text: '+880', title: 'Bangladesh', description: '+880', code: 'BD' },
  { key: '1246', text: '+1246', title: 'Barbados', description: '+1246', code: 'BB' },
  { key: '375', text: '+375', title: 'Belarus', description: '+375', code: 'BY' },
  { key: '32', text: '+32', title: 'Belgium', description: '+32', code: 'BE' },
  { key: '501', text: '+501', title: 'Belize', description: '+501', code: 'BZ' },
  { key: '229', text: '+229', title: 'Benin', description: '+229', code: 'BJ' },
  { key: '1441', text: '+1441', title: 'Bermuda', description: '+1441', code: 'BM' },
  { key: '975', text: '+975', title: 'Bhutan', description: '+975', code: 'BT' },
  {
    key: '591',
    text: '+591',
    title: 'Bolivia, Plurinational State of',
    description: '+591',
    code: 'BO',
  },
  {
    key: '387',
    text: '+387',
    title: 'Bosnia and Herzegovina',
    description: '+387',
    code: 'BA',
  },
  { key: '267', text: '+267', title: 'Botswana', description: '+267', code: 'BW' },
  { key: '55', text: '+55', title: 'Brazil', description: '+55', code: 'BR' },
  {
    key: '246',
    text: '+246',
    title: 'British Indian Ocean Territory',
    description: '+246',
    code: 'IO',
  },
  {
    key: '673',
    text: '+673',
    title: 'Brunei Darussalam',
    description: '+673',
    code: 'BN',
  },
  { key: '359', text: '+359', title: 'Bulgaria', description: '+359', code: 'BG' },
  { key: '226', text: '+226', title: 'Burkina Faso', description: '+226', code: 'BF' },
  { key: '257', text: '+257', title: 'Burundi', description: '+257', code: 'BI' },
  { key: '855', text: '+855', title: 'Cambodia', description: '+855', code: 'KH' },
  { key: '237', text: '+237', title: 'Cameroon', description: '+237', code: 'CM' },
  { key: '1', text: '+1', title: 'Canada', description: '+1', code: 'CA' },
  { key: '238', text: '+238', title: 'Cape Verde', description: '+238', code: 'CV' },
  {
    key: ' 345',
    text: '+ 345',
    title: 'Cayman Islands',
    description: '+ 345',
    code: 'KY',
  },
  {
    key: '236',
    text: '+236',
    title: 'Central African Republic',
    description: '+236',
    code: 'CF',
  },
  { key: '235', text: '+235', title: 'Chad', description: '+235', code: 'TD' },
  { key: '56', text: '+56', title: 'Chile', description: '+56', code: 'CL' },
  { key: '86', text: '+86', title: 'China', description: '+86', code: 'CN' },
  { key: '61', text: '+61', title: 'Christmas Island', description: '+61', code: 'CX' },
  {
    key: '61',
    text: '+61',
    title: 'Cocos (Keeling) Islands',
    description: '+61',
    code: 'CC',
  },
  {
    key: '57',
    text: '+57',
    title: 'Colombia',
    description: '+57',
    code: 'CO',
  },
  { key: '269', text: '+269', title: 'Comoros', description: '+269', code: 'KM' },
  {
    key: '242',
    text: '+242',
    title: 'Congo',
    description: '+242',
    code: 'CG',
  },
  {
    key: '243',
    text: '+243',
    title: 'Congo, the Democratic Republic of the',
    description: '+243',
    code: 'CD',
  },
  { key: '682', text: '+682', title: 'Cook Islands', description: '+682', code: 'CK' },
  { key: '506', text: '+506', title: 'Costa Rica', description: '+506', code: 'CR' },
  { key: '225', text: '+225', title: 'Cote dIvoire', description: '+225', code: 'CI' },
  { key: '385', text: '+385', title: 'Croatia', description: '+385', code: 'HR' },
  { key: '53', text: '+53', title: 'Cuba', description: '+53', code: 'CU' },
  { key: '357', text: '+357', title: 'Cyprus', description: '+357', code: 'CY' },
  { key: '420', text: '+420', title: 'Czech Republic', description: '+420', code: 'CZ' },
  { key: '45', text: '+45', title: 'Denmark', description: '+45', code: 'DK' },
  { key: '253', text: '+253', title: 'Djibouti', description: '+253', code: 'DJ' },
  { key: '1767', text: '+1767', title: 'Dominica', description: '+1767', code: 'DM' },
  {
    key: '1849',
    text: '+1849',
    title: 'Dominican Republic',
    description: '+1849',
    code: 'DO',
  },
  { key: '593', text: '+593', title: 'Ecuador', description: '+593', code: 'EC' },
  { key: '20', text: '+20', title: 'Egypt', description: '+20', code: 'EG' },
  { key: '503', text: '+503', title: 'El Salvador', description: '+503', code: 'SV' },
  {
    key: '240',
    text: '+240',
    title: 'Equatorial Guinea',
    description: '+240',
    code: 'GQ',
  },
  { key: '291', text: '+291', title: 'Eritrea', description: '+291', code: 'ER' },
  { key: '372', text: '+372', title: 'Estonia', description: '+372', code: 'EE' },
  { key: '251', text: '+251', title: 'Ethiopia', description: '+251', code: 'ET' },
  {
    key: '500',
    text: '+500',
    title: 'Falkland Islands (Malvinas)',
    description: '+500',
    code: 'FK',
  },
  { key: '298', text: '+298', title: 'Faroe Islands', description: '+298', code: 'FO' },
  { key: '679', text: '+679', title: 'Fiji', description: '+679', code: 'FJ' },
  { key: '358', text: '+358', title: 'Finland', description: '+358', code: 'FI' },
  { key: '33', text: '+33', title: 'France', description: '+33', code: 'FR' },
  { key: '594', text: '+594', title: 'French Guiana', description: '+594', code: 'GF' },
  { key: '689', text: '+689', title: 'French Polynesia', description: '+689', code: 'PF' },
  {
    key: '262',
    text: '+262',
    title: 'French Southern Territories',
    description: '+262',
    code: 'TF',
  },
  { key: '241', text: '+241', title: 'Gabon', description: '+241', code: 'GA' },
  { key: '220', text: '+220', title: 'Gambia', description: '+220', code: 'GM' },
  { key: '995', text: '+995', title: 'Georgia', description: '+995', code: 'GE' },
  { key: '49', text: '+49', title: 'Germany', description: '+49', code: 'DE' },
  { key: '233', text: '+233', title: 'Ghana', description: '+233', code: 'GH' },
  { key: '350', text: '+350', title: 'Gibraltar', description: '+350', code: 'GI' },
  { key: '30', text: '+30', title: 'Greece', description: '+30', code: 'GR' },
  { key: '299', text: '+299', title: 'Greenland', description: '+299', code: 'GL' },
  { key: '1473', text: '+1473', title: 'Grenada', description: '+1473', code: 'GD' },
  { key: '590', text: '+590', title: 'Guadeloupe', description: '+590', code: 'GP' },
  { key: '1671', text: '+1671', title: 'Guam', description: '+1671', code: 'GU' },
  { key: '502', text: '+502', title: 'Guatemala', description: '+502', code: 'GT' },
  { key: '44', text: '+44', title: 'Guernsey', description: '+44', code: 'GG' },
  { key: '224', text: '+224', title: 'Guinea', description: '+224', code: 'GN' },
  { key: '245', text: '+245', title: 'Guinea-Bissau', description: '+245', code: 'GW' },
  { key: '595', text: '+595', title: 'Guyana', description: '+595', code: 'GY' },
  { key: '509', text: '+509', title: 'Haiti', description: '+509', code: 'HT' },
  { key: '379', text: '+379', title: 'Holy See (Vatican City State)', description: '+379', code: 'VA' },
  { key: '504', text: '+504', title: 'Honduras', description: '+504', code: 'HN' },
  { key: '36', text: '+36', title: 'Hungary', description: '+36', code: 'HU' },
  { key: '354', text: '+354', title: 'Iceland', description: '+354', code: 'IS' },
  { key: '91', text: '+91', title: 'India', description: '+91', code: 'IN' },
  { key: '62', text: '+62', title: 'Indonesia', description: '+62', code: 'ID' },
  { key: '98', text: '+98', title: 'Iran, Islamic Republic of', description: '+98', code: 'IR' },
  { key: '964', text: '+964', title: 'Iraq', description: '+964', code: 'IQ' },
  { key: '353', text: '+353', title: 'Ireland', description: '+353', code: 'IE' },
  { key: '44', text: '+44', title: 'Isle of Man', description: '+44', code: 'IM' },
  { key: '972', text: '+972', title: 'Israel', description: '+972', code: 'IL' },
  { key: '39', text: '+39', title: 'Italy', description: '+39', code: 'IT' },
  { key: '1876', text: '+1876', title: 'Jamaica', description: '+1876', code: 'JM' },
  { key: '81', text: '+81', title: 'Japan', description: '+81', code: 'JP' },
  { key: '44', text: '+44', title: 'Jersey', description: '+44', code: 'JE' },
  { key: '962', text: '+962', title: 'Jordan', description: '+962', code: 'JO' },
  { key: '7', text: '+7', title: 'Kazakhstan', description: '+7', code: 'KZ' },
  { key: '254', text: '+254', title: 'Kenya', description: '+254', code: 'KE' },
  { key: '686', text: '+686', title: 'Kiribati', description: '+686', code: 'KI' },
  { key: '850', text: '+850', title: 'Korea, Democratic Peoples Republic of', description: '+850', code: 'KP' },
  { key: '82', text: '+82', title: 'Korea, Republic of', description: '+82', code: 'KR' },
  { key: '383', text: '+383', title: 'Kosovo', description: '+383', code: 'XK' },
  { key: '965', text: '+965', title: 'Kuwait', description: '+965', code: 'KW' },
  { key: '996', text: '+996', title: 'Kyrgyzstan', description: '+996', code: 'KG' },
  { key: '856', text: '+856', title: 'Lao Peoples Democratic Republic', description: '+856', code: 'LA' },
  { key: '371', text: '+371', title: 'Latvia', description: '+371', code: 'LV' },
  { key: '961', text: '+961', title: 'Lebanon', description: '+961', code: 'LB' },
  { key: '266', text: '+266', title: 'Lesotho', description: '+266', code: 'LS' },
  { key: '231', text: '+231', title: 'Liberia', description: '+231', code: 'LR' },
  { key: '218', text: '+218', title: 'Libyan Arab Jamahiriya', description: '+218', code: 'LY' },
  { key: '423', text: '+423', title: 'Liechtenstein', description: '+423', code: 'LI' },
  { key: '370', text: '+370', title: 'Lithuania', description: '+370', code: 'LT' },
  { key: '352', text: '+352', title: 'Luxembourg', description: '+352', code: 'LU' },
  { key: '853', text: '+853', title: 'Macao', description: '+853', code: 'MO' },
  { key: '389', text: '+389', title: 'Macedonia, the former Yugoslav Republic of', description: '+389', code: 'MK' },
  { key: '261', text: '+261', title: 'Madagascar', description: '+261', code: 'MG' },
  { key: '265', text: '+265', title: 'Malawi', description: '+265', code: 'MW' },
  { key: '60', text: '+60', title: 'Malaysia', description: '+60', code: 'MY' },
  { key: '960', text: '+960', title: 'Maldives', description: '+960', code: 'MV' },
  { key: '223', text: '+223', title: 'Mali', description: '+223', code: 'ML' },
  { key: '356', text: '+356', title: 'Malta', description: '+356', code: 'MT' },
  { key: '692', text: '+692', title: 'Marshall Islands', description: '+692', code: 'MH' },
  { key: '596', text: '+596', title: 'Martinique', description: '+596', code: 'MQ' },
  { key: '222', text: '+222', title: 'Mauritania', description: '+222', code: 'MR' },
  { key: '230', text: '+230', title: 'Mauritius', description: '+230', code: 'MU' },
  { key: '262', text: '+262', title: 'Mayotte', description: '+262', code: 'YT' },
  { key: '52', text: '+52', title: 'Mexico', description: '+52', code: 'MX' },
  { key: '691', text: '+691', title: 'Micronesia, Federated States of', description: '+691', code: 'FM' },
  { key: '373', text: '+373', title: 'Moldova, Republic of', description: '+373', code: 'MD' },
  { key: '377', text: '+377', title: 'Monaco', description: '+377', code: 'MC' },
  { key: '976', text: '+976', title: 'Mongolia', description: '+976', code: 'MN' },
  { key: '382', text: '+382', title: 'Montenegro', description: '+382', code: 'ME' },
  { key: '1664', text: '+1664', title: 'Montserrat', description: '+1664', code: 'MS' },
  { key: '212', text: '+212', title: 'Morocco', description: '+212', code: 'MA' },
  { key: '258', text: '+258', title: 'Mozambique', description: '+258', code: 'MZ' },
  { key: '95', text: '+95', title: 'Myanmar', description: '+95', code: 'MM' },
  { key: '264', text: '+264', title: 'Namibia', description: '+264', code: 'NA' },
  { key: '674', text: '+674', title: 'Nauru', description: '+674', code: 'NR' },
  { key: '977', text: '+977', title: 'Nepal', description: '+977', code: 'NP' },
  { key: '31', text: '+31', title: 'Netherlands', description: '+31', code: 'NL' },
  { key: '599', text: '+599', title: 'Netherlands Antilles', description: '+599', code: 'AN' },
  { key: '687', text: '+687', title: 'New Caledonia', description: '+687', code: 'NC' },
  { key: '64', text: '+64', title: 'New Zealand', description: '+64', code: 'NZ' },
  { key: '505', text: '+505', title: 'Nicaragua', description: '+505', code: 'NI' },
  { key: '227', text: '+227', title: 'Niger', description: '+227', code: 'NE' },
  { key: '234', text: '+234', title: 'Nigeria', description: '+234', code: 'NG' },
  { key: '683', text: '+683', title: 'Niue', description: '+683', code: 'NU' },
  { key: '672', text: '+672', title: 'Norfolk Island', description: '+672', code: 'NF' },
  { key: '1670', text: '+1670', title: 'Northern Mariana Islands', description: '+1670', code: 'MP' },
  { key: '47', text: '+47', title: 'Norway', description: '+47', code: 'NO' },
  { key: '968', text: '+968', title: 'Oman', description: '+968', code: 'OM' },
  { key: '92', text: '+92', title: 'Pakistan', description: '+92', code: 'PK' },
  { key: '680', text: '+680', title: 'Palau', description: '+680', code: 'PW' },
  { key: '970', text: '+970', title: 'Palestinian Territory, Occupied', description: '+970', code: 'PS' },
  { key: '507', text: '+507', title: 'Panama', description: '+507', code: 'PA' },
  { key: '675', text: '+675', title: 'Papua New Guinea', description: '+675', code: 'PG' },
  { key: '595', text: '+595', title: 'Paraguay', description: '+595', code: 'PY' },
  { key: '51', text: '+51', title: 'Peru', description: '+51', code: 'PE' },
  { key: '63', text: '+63', title: 'Philippines', description: '+63', code: 'PH' },
  { key: '48', text: '+48', title: 'Poland', description: '+48', code: 'PL' },
  { key: '351', text: '+351', title: 'Portugal', description: '+351', code: 'PT' },
  { key: '1787', text: '+1787', title: 'Puerto Rico', description: '+1787', code: 'PR' },
  { key: '974', text: '+974', title: 'Qatar', description: '+974', code: 'QA' },
  { key: '262', text: '+262', title: 'Reunion', description: '+262', code: 'RE' },
  { key: '40', text: '+40', title: 'Romania', description: '+40', code: 'RO' },
  { key: '70', text: '+70', title: 'Russian Federation', description: '+70', code: 'RU' },
  { key: '250', text: '+250', title: 'Rwanda', description: '+250', code: 'RW' },
  { key: '590', text: '+590', title: 'Saint Barthelemy', description: '+590', code: 'BL' },
  { key: '290', text: '+290', title: 'Saint Helena', description: '+290', code: 'SH' },
  { key: '1869', text: '+1869', title: 'Saint Kitts and Nevis', description: '+1869', code: 'KN' },
  { key: '1758', text: '+1758', title: 'Saint Lucia', description: '+1758', code: 'LC' },
  { key: '590', text: '+590', title: 'Saint Martin (French part)', description: '+590', code: 'MF' },
  { key: '508', text: '+508', title: 'Saint Pierre and Miquelon', description: '+508', code: 'PM' },
  { key: '1784', text: '+1784', title: 'Saint Vincent and the Grenadines', description: '+1784', code: 'VC' },
  { key: '685', text: '+685', title: 'Samoa', description: '+685', code: 'WS' },
  { key: '378', text: '+378', title: 'San Marino', description: '+378', code: 'SM' },
  { key: '239', text: '+239', title: 'Sao Tome and Principe', description: '+239', code: 'ST' },
  { key: '966', text: '+966', title: 'Saudi Arabia', description: '+966', code: 'SA' },
  { key: '221', text: '+221', title: 'Senegal', description: '+221', code: 'SN' },
  { key: '381', text: '+381', title: 'Serbia', description: '+381', code: 'RS' },
  { key: '248', text: '+248', title: 'Seychelles', description: '+248', code: 'SC' },
  { key: '232', text: '+232', title: 'Sierra Leone', description: '+232', code: 'SL' },
  { key: '65', text: '+65', title: 'Singapore', description: '+65', code: 'SG' },
  { key: '1', text: '+1', title: 'Sint Maarten (Dutch part)', description: '+1', code: 'SX' },
  { key: '421', text: '+421', title: 'Slovakia', description: '+421', code: 'SK' },
  { key: '386', text: '+386', title: 'Slovenia', description: '+386', code: 'SI' },
  { key: '677', text: '+677', title: 'Solomon Islands', description: '+677', code: 'SB' },
  { key: '252', text: '+252', title: 'Somalia', description: '+252', code: 'SO' },
  { key: '27', text: '+27', title: 'South Africa', description: '+27', code: 'ZA' },
  { key: '211', text: '+211', title: 'South Sudan', description: '+211', code: 'SS' },
  { key: '34', text: '+34', title: 'Spain', description: '+34', code: 'ES' },
  { key: '94', text: '+94', title: 'Sri Lanka', description: '+94', code: 'LK' },
  { key: '249', text: '+249', title: 'Sudan', description: '+249', code: 'SD' },
  { key: '597', text: '+597', title: 'Suriname', description: '+597', code: 'SR' },
  { key: '268', text: '+268', title: 'Swaziland', description: '+268', code: 'SZ' },
  { key: '46', text: '+46', title: 'Sweden', description: '+46', code: 'SE' },
  { key: '41', text: '+41', title: 'Switzerland', description: '+41', code: 'CH' },
  { key: '963', text: '+963', title: 'Syrian Arab Republic', description: '+963', code: 'SY' },
  { key: '886', text: '+886', title: 'Taiwan, Province of China', description: '+886', code: 'TW' },
  { key: '992', text: '+992', title: 'Tajikistan', description: '+992', code: 'TJ' },
  { key: '255', text: '+255', title: 'Tanzania, United Republic of', description: '+255', code: 'TZ' },
  { key: '66', text: '+66', title: 'Thailand', description: '+66', code: 'TH' },
  { key: '670', text: '+670', title: 'Timor-Leste', description: '+670', code: 'TL' },
  { key: '228', text: '+228', title: 'Togo', description: '+228', code: 'TG' },
  { key: '690', text: '+690', title: 'Tokelau', description: '+690', code: 'TK' },
  { key: '676', text: '+676', title: 'Tonga', description: '+676', code: 'TO' },
  { key: '1868', text: '+1868', title: 'Trinidad and Tobago', description: '+1868', code: 'TT' },
  { key: '216', text: '+216', title: 'Tunisia', description: '+216', code: 'TN' },
  { key: '90', text: '+90', title: 'Turkey', description: '+90', code: 'TR' },
  { key: '993', text: '+993', title: 'Turkmenistan', description: '+993', code: 'TM' },
  { key: '1649', text: '+1649', title: 'Turks and Caicos Islands', description: '+1649', code: 'TC' },
  { key: '688', text: '+688', title: 'Tuvalu', description: '+688', code: 'TV' },
  { key: '256', text: '+256', title: 'Uganda', description: '+256', code: 'UG' },
  { key: '380', text: '+380', title: 'Ukraine', description: '+380', code: 'UA' },
  { key: '971', text: '+971', title: 'United Arab Emirates', description: '+971', code: 'AE' },
  { key: '44', text: '+44', title: 'United Kingdom', description: '+44', code: 'GB' },
  { key: '1', text: '+1', title: 'United States', description: '+1', code: 'US' },
  { key: '598', text: '+598', title: 'Uruguay', description: '+598', code: 'UY' },
  { key: '998', text: '+998', title: 'Uzbekistan', description: '+998', code: 'UZ' },
  { key: '678', text: '+678', title: 'Vanuatu', description: '+678', code: 'VU' },
  { key: '58', text: '+58', title: 'Venezuela', description: '+58', code: 'VE' },
  { key: '84', text: '+84', title: 'Viet Nam', description: '+84', code: 'VN' },
  { key: '1284', text: '+1284', title: 'Virgin Islands, British', description: '+1284', code: 'VG' },
  { key: '1340', text: '+1340', title: 'Virgin Islands, U.s.', description: '+1340', code: 'VI' },
  { key: '681', text: '+681', title: 'Wallis and Futuna', description: '+681', code: 'WF' },
  { key: '212', text: '+212', title: 'Western Sahara', description: '+212', code: 'EH' },
  { key: '967', text: '+967', title: 'Yemen', description: '+967', code: 'YE' },
  { key: '260', text: '+260', title: 'Zambia', description: '+260', code: 'ZM' },
  { key: '263', text: '+263', title: 'Zimbabwe', description: '+263', code: 'ZW' },
]

export default {
  phones: phones,
}
