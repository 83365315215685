import React from 'react'
import type { WalletCardHeaderProps } from './WalletCard.types'
import styles from './WalletCard.module.css'
import { Container } from '../Container'

const WalletCardHeader: React.FC<WalletCardHeaderProps> = props => {
  const { name, logo, source } = props

  return (
    <Container>
      <div
        className={styles.WalletCardHeader}
        style={{ background: `url(${source}) no-repeat bottom / cover border-box` }}>
        <div className={styles.WalletCardHeader__content}>
          <img src={logo} alt={name} />
          <h1>{name}</h1>
        </div>
      </div>
    </Container>
  )
}

export default WalletCardHeader
