import { Outlet, type RouteObject } from 'react-router-dom'
import { CammonRouter } from '@/pages/cammon/routes'
import { CampaignRouter } from '@/pages/campaign/routes'
import { CompanyRouter } from '@/pages/company/routes'
import { DepositRouter } from '@/pages/deposit/routes'
import { PaymentRouter } from '@/pages/payment/routes'
import { UserRouter } from '@/pages/user/routes'
import { Initialize } from '@/pages/user/views'
import { WalletRouter } from '@/pages/wallet/routes'
import AuthMiddleware from './middleware/Auth'

const routes: RouteObject[] = [
  {
    path: '/init',
    element: <Initialize />,
  },
  {
    element: <AuthMiddleware />,
    children: [
      {
        element: <Outlet />,
        children: [...WalletRouter, ...DepositRouter, ...CampaignRouter, ...PaymentRouter, ...CompanyRouter],
      },
      {
        element: <Outlet />,
        children: [...UserRouter],
      },
    ],
  },
  {
    children: [...CammonRouter],
  },
  {
    path: '*',
    element: <div>404</div>,
  },
]

export default routes
