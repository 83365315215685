import cn from 'classnames'
import type { ContentHeaderProps } from './ContentHeader.types'
import styles from './ContentHeader.module.css'
import { Container } from '../Container'

const ContentHeader: React.FC<ContentHeaderProps> = props => {
  const { title, description, className } = props
  return (
    <Container>
      <div className={cn(styles.ContentHeader, className)}>
        {title ? <h2>{title}</h2> : null}
        <p>{description}</p>
      </div>
    </Container>
  )
}

export default ContentHeader
