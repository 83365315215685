import * as React from 'react'
import { useTranslation } from 'react-i18next'
import ThemeConfig from '~/tailwind.config'
import type { BadgeType } from '@/components/Badge'
import type { IconsNameType } from '@/components/Icons'
import i18n from '@/translations'

const useContentConfig = () => {
  const { t } = useTranslation()
  const { colors } = ThemeConfig.theme.extend

  const contents = React.useMemo(() => {
    const data = {
      locale: i18n.getLocale(),
      historyItem: {
        types: [
          {
            key: 'payment_refund',
            icon: 'refund' as IconsNameType,
            title: t('content_config.HistoryItem.refund'),
            amountColor: colors.semantic.content.error,
            pointChipType: 'brand' as BadgeType,
          },
          {
            key: 'deposit_refund',
            icon: 'refund' as IconsNameType,
            title: t('content_config.HistoryItem.refund'),
            amountColor: colors.semantic.content.error,
            pointChipType: 'brand' as BadgeType,
          },
          {
            key: 'cash_deposit_refund',
            icon: 'refund' as IconsNameType,
            title: t('content_config.HistoryItem.refund'),
            amountColor: colors.semantic.content.error,
            pointChipType: 'brand' as BadgeType,
          },
          {
            key: 'top-up',
            icon: 'top-up' as IconsNameType,
            title: t('content_config.HistoryItem.topUp'),
            amountColor: colors.semantic.content.success,
            pointChipType: 'yellow' as BadgeType,
          },
          {
            key: 'capture',
            icon: 'fullscreen' as IconsNameType,
            title: t('content_config.HistoryItem.capture'),
            amountColor: colors.semantic.content.ink,
            pointChipType: 'yellow' as BadgeType,
          },
          {
            key: 'approve',
            icon: 'warning' as IconsNameType,
            title: t('content_config.HistoryItem.refund_approval'),
            amountColor: colors.semantic.content.ink,
            pointChipType: 'brand' as BadgeType,
          },
          {
            key: 'close',
            icon: 'warning' as IconsNameType,
            title: t('content_config.HistoryItem.refund_reject'),
            amountColor: colors.semantic.content.error,
            pointChipType: 'brand' as BadgeType,
          },
        ],
      },
      campaignItem: {
        detailsButtonText: t('content_config.CampaignItem.details_button_text'),
        disabledButtonText: t('content_config.CampaignItem.disabled_button_text'),
        remainingTime: t('content_config.CampaignItem.remaining_time'),
        day: t('content_config.CampaignItem.day'),
        pointSuffix: t('content_config.CampaignItem.point_suffix'),
      },
      rewardChart: {
        tableTypeTitle: t('content_config.RewardChart.table_type_title'),
        progressTypeTitle: t('content_config.RewardChart.progress_type_title'),
        progressTypeDescription: t('content_config.RewardChart.progress_type_description'),
        tableTypeDescription: t('content_config.RewardChart.table_type_description'),
      },
      phoneField: {
        placeholders: {
          countryDropdownFieldLabel: t('content_config.PhoneField.placeholders_country_dropdown_field_label'),
          countryDropdownFieldModalHeaderTitle: t(
            'content_config.PhoneField.placeholders_country_dropdown_field_modal_header_title',
          ),
          phoneNumberTextFieldLabel: t('content_config.PhoneField.placeholders_phone_number_text_field_label'),
        },
      },
    }
    return data
  }, [t, colors])

  return contents
}

export default useContentConfig
