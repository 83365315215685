import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import type { PayData, PaymentCheckData, PaymentCheckResponse, PayResponse } from './GoldFramerApi.types'

const goldframerApi = createApi({
  reducerPath: 'goldframerApi',
  baseQuery: fetchBaseQuery({
    baseUrl: 'https://goldframer-payment.macellan.app/',
  }),
  endpoints: builder => ({
    preCheck: builder.mutation<PaymentCheckResponse, PaymentCheckData>({
      query: data => ({
        url: 'pay/check',
        method: 'POST',
        body: data,
      }),
    }),
    pay: builder.mutation<PayResponse, PayData>({
      query: data => ({
        url: 'pay',
        method: 'POST',
        body: data,
      }),
    }),
  }),
})

export default goldframerApi
