import CammonTranslations from '@/pages/cammon/translations'
import CampaignTranslations from '@/pages/campaign/translations'
import CompanyTranslations from '@/pages/company/translations'
import DepositTranslations from '@/pages/deposit/translations'
import PaymentTranslations from '@/pages/payment/translations'
import UserTranslations from '@/pages/user/translations'
import WalletTranslations from '@/pages/wallet/translations'

export default {
  '@wallet': WalletTranslations.en,
  '@company': CompanyTranslations.en,
  '@deposit': DepositTranslations.en,
  '@campaign': CampaignTranslations.en,
  '@payment': PaymentTranslations.en,
  '@user': UserTranslations.en,
  '@cammon': CammonTranslations.en,
  'socket_context': {
    refund_request_event: {
      alert_title: 'Refund Request',
      alert_message: 'Do you approve the refund of {{amount}}?',
      alert_action_submit: 'Accept',
      alert_action_reject: 'Reject',
      alert_action_submit_toast: 'Refund successfully processed',
      alert_action_reject_toast: 'Refund process canceled',
    },
    payment_event: {
      alert_title: 'Payment Successful',
      alert_message: 'You can check wallet transactions to view payment details',
      alert_action_submit: 'OK',
      toast_error_message: 'Payment Failed',
      toast_no_balance_message: 'Insufficient balance for payment',
      toast_expire_message: 'Payment has expired',
      toast_device_offline_message: 'Connection could not be established',
      toast_callback_no: 'Device is not responding',
    },
  },
  'content_config': {
    CampaignItem: {
      details_button_text: 'Details',
      disabled_button_text: 'Used',
      remaining_time: 'Remaining Time',
      day: 'Day',
      point_suffix: 'points gift',
    },
    RewardChart: {
      table_type_title: 'LOAD AND EARN',
      progress_type_title: 'SPEND AND EARN',
      progress_type_description: 'spending remaining',
      table_type_description: 'Total points earned for using Macellan SuperApp',
    },
    WalletCard: {
      placeholders_amount_label: 'BALANCE',
      placeholders_point: 'Points',
    },
    HistoryItem: {
      refund: 'Cancellation / Refund',
      topUp: 'Balance Load',
      capture: 'Balance Spending',
      refund_approval: 'Refund Approval Pending',
      refund_reject: 'Refund Rejected',
    },
    PhoneField: {
      placeholders_country_dropdown_field_label: 'Country or Region',
      placeholders_country_dropdown_field_modal_header_title: 'Select Country or Region',
      placeholders_phone_number_text_field_label: 'Phone Number',
    },
  },
  'validation': {
    error_message: {
      /* Mixed Validation Messages */
      mixed_required: 'This field is required.',
      mixed_default: 'The entered value is not of the correct type',
      mixed_one_of: 'You entered an invalid value',
      /* String Validation Messages */
      string_length: 'This field must be {{length}} characters long',
      string_min: 'This field must be at least {{min}} characters long',
      string_max: 'This field must be at most {{max}} characters long',
      string_email: 'The entered value must be in email format',
      string_matches: 'Enter a value in the correct format',
      string_url: 'The entered value must be in URL format',
      /* Number Validation Messages */
      number_min: 'The entered number cannot be less than {{min}}',
      number_max: 'The entered number cannot be greater than {{max}}',
      number_less_than: 'The entered number must be greater than {{less}}',
      number_more_than: 'The entered number must be less than {{more}}',
      number_positive: 'The entered value must be greater than zero',
      number_negative: 'The entered value must be less than zero',
      number_integer: 'The entered value must be an integer',
      /* Date Validation Messages */
      date_min: 'The entered date cannot be earlier than {{min}}',
      date_max: 'The entered date cannot be later than {{max}}',
      /* Array Validation Messages */
      array_length: 'The specified number of elements must be {{length}}',
      array_min: 'The specified number of elements must be at least {{min}}',
      array_max: 'The specified number of elements must be at most {{max}}',
    },
  },
}
