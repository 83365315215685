import type { GetToastChipTypeFunction, GetToastIconColorFunction, GetToastProgressColorsFunction } from './Toast.types'
import styles from './Toast.module.css'
import ThemeConfig from '~/tailwind.config'

const { colors } = ThemeConfig.theme.extend

export const ToastVariants = {
  default: styles.Default,
  suggestion: styles.Suggestion,
  success: styles.Success,
  error: styles.Error,
}

export const getToastChipType: GetToastChipTypeFunction = type => {
  switch (type) {
    case 'suggestion':
      return 'brand'
    case 'success':
      return 'ink-inverse'
    case 'error':
      return 'ink-inverse'
    default:
      return 'yellow'
  }
}

export const getToastIconColor: GetToastIconColorFunction = type => {
  switch (type) {
    case 'suggestion':
      return colors.semantic.content.ink
    case 'success':
      return colors.semantic.content.inkInverse
    case 'error':
      return colors.semantic.content.inkInverse
    default:
      return colors.semantic.background.primary
  }
}

export const getToastProgressColors: GetToastProgressColorsFunction = type => {
  if (type === 'suggestion') {
    return {
      background: colors.semantic.content.ink,
      indicator: colors.semantic.content.brand,
      text: colors.semantic.content.ink,
    }
  }

  if (type === 'success' || type === 'error') {
    return {
      background: colors.tint.white[20],
      indicator: colors.semantic.content.inkInverse,
      text: colors.semantic.content.inkInverse,
    }
  }

  return {
    background: colors.tint.white[20],
    indicator: colors.semantic.content.brand,
    text: colors.semantic.content.inkInverse,
  }
}
