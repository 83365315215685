import React from 'react'
import { motion } from 'framer-motion'
import type { HistoryItemProps } from './HistoryItem.types'
import styles from './HistoryItem.module.css'
import { useContentConfig } from '@/hooks'
import ThemeConfig from '~/tailwind.config'
import { Icon } from '../Icons'
import HistoryItemContent from './HistoryItemContent'

const HistoryItem: React.FC<HistoryItemProps> = props => {
  const { type, amount, point, currency, subtitle, contentStyle, avatarImageSource, datetime, onClick } = props
  const content = useContentConfig()
  const { colors } = ThemeConfig.theme.extend

  const item = React.useMemo(() => {
    return content.historyItem.types.find(element => element.key === type)
  }, [type, content])

  if (!item) return null

  return (
    <motion.div
      initial="hidden"
      animate="visible"
      variants={{ hidden: { opacity: 0 }, visible: { opacity: 1 } }}
      transition={{
        ease: 'easeInOut',
        duration: 0.5,
      }}
      className={styles.HistoryItemContainer}
      onClick={onClick}>
      <div className={styles.HistoryItemContainerLeft}>
        <div className={styles.HistoryItemContainerAvatarIcon}>
          <Icon name={item.icon} size={24} color={colors.semantic.content.inkMedium} />
          {avatarImageSource && (
            <div className={styles.HistoryItemContainerAvatarImage}>
              <img src={avatarImageSource} alt={subtitle} />
            </div>
          )}
        </div>
      </div>
      <div className={styles.HistoryItemContent} style={contentStyle}>
        <HistoryItemContent
          item={item}
          amount={amount}
          point={point}
          currency={currency}
          subtitle={subtitle}
          datetime={datetime}
        />
      </div>
    </motion.div>
  )
}

export default HistoryItem
