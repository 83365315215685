import * as React from 'react'
import type { UseTextInputMaskFunction } from './TextInput.types'
import { getCreditCardMask, getCVCMask, getDateTimeMask, getOnlyNumbersMask } from '../TextInput/TextInput.utils'

export const useMaskTextInput: UseTextInputMaskFunction = (type, value, options) => {
  return React.useMemo(() => {
    if (type === 'only-numbers') return getOnlyNumbersMask(value.length + 1)

    if (type === 'date-time') return getDateTimeMask(options?.dateTimePattern ?? 'DD/MM/YYYY')

    if (type === 'credit-card') return getCreditCardMask(value)

    if (type === 'cvc') return getCVCMask(options?.cvcReferenceValue ?? '')

    return undefined
  }, [options?.cvcReferenceValue, options?.dateTimePattern, type, value])
}
