const CampaignRewardListSkeleton: React.FC = () => {
  return (
    <div className="animate-pulse">
      <div className="flex flex-col gap-2 px-6 py-6">
        <div className="h-[32px] w-[160px] rounded-xl bg-semantic-background-secondary" />
        <div className="h-4 w-full rounded-xl bg-semantic-background-secondary" />
      </div>
      <div className="px-6">
        <div className="h-[172px] w-full rounded-[20px] bg-semantic-background-secondary" />
      </div>
    </div>
  )
}

export default CampaignRewardListSkeleton
