import React from 'react'
import { useNavigate } from 'react-router-dom'
import { map, slice } from 'lodash'
import dayjs from 'dayjs'
import type { AccountActivityListProps, AccountActivityListStaticProperties } from './AccountActivityList.types'
import { usePagination } from '@/hooks'
import { HistoryItem } from '@/components/HistoryItem'
import walletApi from '../../api/WalletApi'
import AccountActivityListSkeleton from './AccountActivityListSkeleton'
import AccountActivitySectionList from './AccountActivitySectionList'

const AccountActivityList: React.FC<AccountActivityListProps> & AccountActivityListStaticProperties = props => {
  const { config, limit = 0, ListHeaderComponent } = props
  const navigate = useNavigate()

  const paginate = usePagination(walletApi.useBalanceHistoryListQuery, {
    ...config,
  })

  if (paginate.query.isLoading) return <AccountActivityListSkeleton />

  if (!paginate.data || !paginate.meta || !paginate.data.length) return null

  const data = limit > 0 ? slice(paginate.data, 0, limit) : paginate.data

  return (
    <React.Fragment>
      {ListHeaderComponent && ListHeaderComponent}
      {map(data, (item, index) => (
        <HistoryItem
          key={index}
          type={item.type}
          subtitle={item.company.name}
          amount={item.amount}
          point={item.total_earned_points}
          currency={item.company.currency}
          datetime={dayjs(item.created_at).toDate()}
          avatarImageSource={item.company.logo}
          contentStyle={data.length - 1 === index ? { borderBottom: 'none' } : {}}
          onClick={() => navigate(`/account-activities/${item.id}`)}
        />
      ))}
    </React.Fragment>
  )
}

AccountActivityList.SectionList = AccountActivitySectionList

export default AccountActivityList
