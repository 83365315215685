import React from 'react'
import { motion } from 'framer-motion'
import type { DropdownModalProps } from './DropdownField.types'
import styles from './DropdownField.module.css'
import { Icon } from '../Icons'
import { SearchField } from '../SearchField'
import DropdownList from './DropdownList'

const DropdownModal: React.FC<DropdownModalProps> = props => {
  const { headerTitle, items, value, onDismiss, onChangeValue } = props

  const [searchText, setSearchText] = React.useState<string>('')
  const [debouncedSearchText, setDebouncedSearchText] = React.useState<string>('')

  return (
    <motion.div
      initial={{ transform: 'translateY(100%)' }}
      animate={{ transform: 'translateY(0%)' }}
      exit={{ transform: 'translateY(100%)' }}
      transition={{ duration: 0.2 }}
      className={styles.Modal}>
      <div className={styles.ModalHeader}>
        <h4>{headerTitle}</h4>
        <button onClick={onDismiss}>
          <Icon name="close" size={24} />
        </button>
      </div>
      <SearchField
        className="h-[60px]"
        value={searchText}
        onChangeText={setSearchText}
        onChangeDebouncedText={setDebouncedSearchText}
      />
      <DropdownList data={items} searchText={debouncedSearchText} value={value} onChangeValue={onChangeValue} />
    </motion.div>
  )
}
export default DropdownModal
