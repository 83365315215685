import * as React from 'react'
import type { MasterPassSectionProps } from './MasterPassSection.types'
import { DepositCard } from '@/components/DepositCard'
import { Section } from '@/components/Section'
import DepositSection from '../DepositSection/DepositSection'
import MasterPassDepositCard from '../MasterPassDepositCard/MasterPassDepositCard'

const MasterPassSection: React.FC<MasterPassSectionProps> = props => {
  const { visible = true, selected, depositCardProps, onClick, walletId } = props
  if (!visible) return null

  return (
    <React.Fragment>
      <DepositSection selected={selected} type="masterpass" />
      <Section className="!py-0">
        {!selected ? <DepositCard.Loader /> : null}
        {selected ? (
          <MasterPassDepositCard selected={selected} {...depositCardProps} onClick={onClick} walletId={walletId} />
        ) : null}
      </Section>
    </React.Fragment>
  )
}

export default MasterPassSection
