import * as React from 'react'
import { useNavigate } from 'react-router-dom'
import type { PaymentMinimal } from '@/pages/payment/api'
import paymentApi from '../../api/PaymentApi'

import { SocketService } from '@/services'

const useGenerateQREvents = (referenceCode: string | undefined) => {
  const [qrStatusQuery] = paymentApi.useLazyQrStatusQuery()
  const navigate = useNavigate()

  React.useEffect(() => {
    if (!referenceCode) return

    const connected = SocketService.getState() === 'connected'
    let attempt = 0
    let interval: ReturnType<typeof setInterval>

    const polling = async () => {
      interval = setInterval(async () => {
        if (attempt >= 30) clearInterval(interval)

        attempt++

        const result = await qrStatusQuery({
          qr_content: referenceCode,
        }).unwrap()

        if (result.data.status !== 'appointed') return

        if (result.data.type === 'payment') {
          navigate('/capture', {
            state: {
              payment: result.data.resource as PaymentMinimal,
              wallet: result.data.wallet,
            },
          })
          clearInterval(interval)
        }
      }, 3000)
    }

    const timer = setTimeout(polling, connected ? 5000 : 0)

    return () => {
      if (interval) clearInterval(interval)
      if (timer) clearTimeout(timer)
    }
  }, [navigate, qrStatusQuery, referenceCode])
}

export default useGenerateQREvents
