import { useNavigate } from 'react-router-dom'
import { map } from 'lodash'
import { useTranslation } from 'react-i18next'
import type { OtherCampaignsProps } from './CampaignDetail.types'
import { CampaignItem } from '@/components/CampaignItem'
import { ContentHeader } from '@/components/ContentHeader'
import { Section } from '@/components/Section'

const OtherCampaigns: React.FC<OtherCampaignsProps> = props => {
  const { otherCampaigns, currency } = props
  const { t } = useTranslation()
  const navigate = useNavigate()

  if (!otherCampaigns.length) return null

  return (
    <Section className="flex flex-col gap-6 bg-semantic-background-primary pb-10 pt-6">
      <ContentHeader
        title={t('@campaign.CampaignDetail.other_campaigns_title')}
        description={t('@campaign.CampaignDetail.other_campaigns_description')}
      />
      <div className="flex flex-col gap-3">
        {map(otherCampaigns, campaign => (
          <CampaignItem.Tiny
            key={campaign.id}
            description={campaign.title}
            currency={currency}
            amount={campaign.campaign.point_amount}
            ButtonProps={{
              onClick: () => navigate(`/campaign/${campaign.id}`),
            }}
          />
        ))}
      </div>
    </Section>
  )
}

export default OtherCampaigns
