import React from 'react'
import { useTranslation } from 'react-i18next'
import type { ResendVerifyMethods, ResendVerifyProps } from './OTPVerify.types'
import ThemeConfig from '~/tailwind.config'
import { Button } from '@/components/Button'
import type { TimerMethods } from '@/components/Timer'
import { Timer } from '@/components/Timer'
import { ReferenceUtils } from '@/utils'

const ResendVerify = React.forwardRef<ResendVerifyMethods, ResendVerifyProps>((props, ref) => {
  const { duration, actionButtonProps, onResetTimer } = props
  const { t } = useTranslation()
  const theme = ThemeConfig.theme.extend
  const timer = React.useRef<TimerMethods>(null)

  const [isFinished, setFinished] = React.useState<boolean>(false)

  const handleFinished = React.useCallback(() => {
    setFinished(true)
  }, [])

  const handleResetTimer = React.useCallback(() => {
    if (!isFinished) return

    setFinished(false)
    onResetTimer && onResetTimer()
    timer.current?.resetTimer(true)
  }, [isFinished, onResetTimer])

  if (!duration) return null

  return (
    <div className="flex items-center justify-between pt-4">
      <Button
        type="button"
        variant="primary"
        size="tiny"
        radiusType="pill"
        leftIcon="notification"
        disabled={!isFinished}
        onClick={() => {
          handleResetTimer(), actionButtonProps?.onClick
        }}>
        {t('@user.OTPVerify.resend_verify_button')}
      </Button>
      <Timer.Circle
        ref={ReferenceUtils.mergeRefs([timer, ref])}
        className="flex flex-row-reverse items-center gap-2"
        durationSuffix={` ${t('@user.OTPVerify.resend_verify_second')}`}
        duration={duration}
        size={22}
        indicatorColor={theme.colors.solid.brand[5]}
        backgroundColor={theme.colors.semantic.background.secondary}
        valueTextStyle={{
          fontSize: theme.fontSize['label-sm'][0],
          color: theme.colors.semantic.content.inkLight,
        }}
        onFinished={handleFinished}
      />
    </div>
  )
})

export default ResendVerify
