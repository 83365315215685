import { omit } from 'lodash'
import type { WalletDetailsResponse } from './WalletApi.types'

export default class WalletApiUtils {
  public static transformWalletDetailsResponse = (response: WalletDetailsResponse) => {
    return {
      data: {
        ...response.data,
        company: {
          ...response.data.company,
          deposit_methods: {
            masterpass: response.data.company.deposit_methods.masterpass,
            paycell: response.data.company.deposit_methods.paycell,
            bkm: response.data.company.deposit_methods.bkm,
            ...omit(response.data.company.deposit_methods, ['masterpass', 'paycell', 'bkm']),
          },
        },
      },
      meta: {
        ...response.meta,
      },
    }
  }
}
