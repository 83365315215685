import React from 'react'
import { useNavigate } from 'react-router-dom'
import { RedirectPage, type StateProps } from './Initialize.types'
import type { InitializeResponse } from '@/api'

// eslint-disable-next-line
export const useNavigateToPage = (): ((initializeData: InitializeResponse['data']) => void) => {
  const navigate = useNavigate()

  return React.useCallback(
    (initializeData: InitializeResponse['data']) => {
      const { redirect_page, auth_data, wallet_id, company_id, trigger_data } = initializeData

      let path: string = '/'
      let state: StateProps = {
        authData: auth_data,
        redirectPage: redirect_page,
        walletId: wallet_id,
        companyId: company_id,
      }

      if (redirect_page === RedirectPage.Home) (path = '/'), (state = { walletId: wallet_id })

      if (redirect_page === RedirectPage.Deposit)
        (path = '/topup'), (state = { walletId: wallet_id, triggerData: trigger_data })

      return navigate(path, { replace: true, state })
    },
    [navigate],
  )
}
