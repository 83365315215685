import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useFormik } from 'formik'
import { CurrencyFormatter } from '@macellan/formatter'
import type { CouponApplyForm } from './CouponApply.types'
import { couponApplyValidation } from './CouponApply.validations'
import toast from '@/context/ToastContext/Toast.utils'
import { Button } from '@/components/Button'
import { ContentHeader } from '@/components/ContentHeader'
import { Header } from '@/components/Header'
import { Section } from '@/components/Section'
import { TextField } from '@/components/TextField'
import { ToolBar } from '@/components/ToolBar'
import i18n from '@/translations'
import walletApi from '../../api/WalletApi'

const CouponApply: React.FC = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const { t } = useTranslation()
  const [walletDetailsQuery] = walletApi.useLazyWalletDetailsQuery()
  const [pointCouponApply, pointCouponApplyResult] = walletApi.usePointCouponApplyMutation()

  const handleSubmit = React.useCallback(
    async (values: CouponApplyForm) => {
      const result = await pointCouponApply({
        wallet_id: location.state.walletId,
        code: values.code,
      }).unwrap()

      const message = result.data.message

      const walletDetailsResult = await walletDetailsQuery({}).unwrap()

      const formattedAmount = CurrencyFormatter.format(
        result.data.amount,
        i18n.getLocale(),
        walletDetailsResult.data.company.currency,
      )
      toast.success({
        message: message ? message : t('@wallet.CouponApply.coupon_toast_message', { amount: formattedAmount }),
        icon: 'check-circle',
      })

      navigate(-1)
    },
    [pointCouponApply, walletDetailsQuery, t, navigate, location.state.walletId],
  )

  const couponApplyFormik = useFormik({
    initialValues: {
      code: '',
    },
    onSubmit: values => handleSubmit(values),
    validationSchema: couponApplyValidation,
  })

  return (
    <React.Fragment>
      <Header
        title={t('@wallet.CouponApply.route_header_title')}
        variant="secondary"
        color="black"
        goBack={() => navigate(-1)}
      />
      <Section className="!py-9">
        <ContentHeader description={t('@wallet.CouponApply.content_header_description')} />
        <form onSubmit={couponApplyFormik.handleSubmit} className="pt-10">
          <TextField
            type="text"
            placeholder={t('@wallet.CouponApply.coupon_input_placeholder')}
            autoCapitalize="characters"
            autoFocus={true}
            value={couponApplyFormik.values.code.toUpperCase()}
            onChange={couponApplyFormik.handleChange('code')}
            onBlur={couponApplyFormik.handleBlur('code')}
            error={couponApplyFormik.errors.code ? 'error' : 'enable'}
            message={couponApplyFormik.errors.code}
          />
        </form>
      </Section>
      <ToolBar className="grid grid-cols-1 border-none">
        <Button
          type="submit"
          variant="primary"
          size="large"
          radiusType="rounded"
          loading={pointCouponApplyResult.isLoading}
          disabled={!couponApplyFormik.isValid}
          onClick={() => couponApplyFormik.handleSubmit()}>
          {t('@wallet.CouponApply.coupon_add_button')}
        </Button>
      </ToolBar>
    </React.Fragment>
  )
}

export default CouponApply
