import cn from 'classnames'
import type { ISectionProps } from './Section.types'
import { getStyleProps } from './Section.utils'

const Section: React.FC<ISectionProps> = props => {
  const { children, color = 'transparent', className } = props
  const combinedClassNames = cn('px-6 py-4', className)

  return (
    <section className={combinedClassNames} {...getStyleProps({ color })}>
      {children}
    </section>
  )
}
export default Section
