import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { CurrencyFormatter } from '@macellan/formatter'
import type { CampaignDetailToastProps } from './CampaignDetail.types'
import { Toast, type ToastType } from '@/components/Toast'
import i18next from '@/translations'

const CampaignDetailToast: React.FC<CampaignDetailToastProps> = props => {
  const {
    campaignType,
    usageCount,
    multipleUsage,
    userUsed,
    remainderCount,
    campaignWon,
    processCount,
    pointAmount,
    currency,
  } = props
  const { t } = useTranslation()

  const formattedEarnablePoint = React.useMemo(() => {
    return CurrencyFormatter.format(pointAmount, i18next.getLocale(), currency)
  }, [currency, pointAmount])

  const formattedWonPoint = React.useMemo(() => {
    return CurrencyFormatter.format(campaignWon, i18next.getLocale(), currency)
  }, [campaignWon, currency])

  const message = React.useMemo(() => {
    return campaignType === 'payment_count_gift'
      ? t('@campaign.CampaignDetail.payment_count_gift_toast_message', {
          formattedEarnablePoint: formattedEarnablePoint,
          remainderCount: remainderCount,
        })
      : !multipleUsage && userUsed
        ? t('@campaign.CampaignDetail.deposit_gift_disposable_toast_message')
        : t('@campaign.CampaignDetail.deposit_gift_toast_message', {
            usageCount: usageCount,
          })
  }, [campaignType, formattedEarnablePoint, multipleUsage, remainderCount, t, usageCount, userUsed])

  const ToastRightComponent = React.useCallback(
    (type: ToastType) => {
      if (campaignType === 'payment_count_gift' && processCount)
        return (
          <Toast.Progress
            className="flex flex-row-reverse items-center gap-2"
            valueTextStyle={{ fontSize: '0.75rem' }}
            type={type}
            value={processCount - remainderCount}
            totalValue={processCount}>
            {`${processCount - remainderCount}/${processCount}`}
          </Toast.Progress>
        )

      return (
        <Toast.Chip variant={type}>
          {t('@campaign.CampaignDetail.toast_message_chip_title', { formattedWonPoint: formattedWonPoint })}
        </Toast.Chip>
      )
    },
    [campaignType, formattedWonPoint, processCount, remainderCount, t],
  )

  return (
    <div className="bg-semantic-background-primary px-6 pb-3">
      <Toast variant={'default'} message={message} LayoutRightComponent={ToastRightComponent} />
    </div>
  )
}

export default CampaignDetailToast
