import React from 'react'
import cn from 'classnames'
import type { IconButtonProps } from './IconButton.types'
import styles from './IconButton.module.css'
import { Icon } from '../Icons'

const IconButton: React.FC<IconButtonProps> = props => {
  const { name, size, color, className, ...rest } = props

  const buttonClass = cn(styles.IconButton, className)

  return (
    <button className={buttonClass} style={{ width: size, height: size }} {...rest}>
      <Icon name={name} size={size} color={color} />
    </button>
  )
}

export default IconButton
