import type { UtilsItemSizes, UtilsTooltipPosition } from './RewardChart.types'
import styles from './RewardChart.module.css'

export function tooltipPosition(
  balance: number,
  totalBalance: number,
  itemSizes: UtilsItemSizes,
): UtilsTooltipPosition {
  const heightRatio = (balance / totalBalance) * itemSizes.height
  const isBottom = heightRatio >= 90
  return {
    position: heightRatio < 90 ? heightRatio + 15 : heightRatio - 55,
    tooltipArrow: isBottom ? <div className={styles.TooltipArrowBottom} /> : <div className={styles.TooltipArrowTop} />,
  }
}
