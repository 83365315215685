import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import type { MasterPassDepositCardProps } from './MasterPassDepositCard.types'
import { useAppSelector } from '@/store'
import { Button } from '@/components/Button'
import { DepositCard } from '@/components/DepositCard'
import { useSelectedCard } from '@/pages/deposit/hook'
import { MasterPassService } from '@/pages/deposit/services'

import MasterPassLogo from '@/assets/images/MasterPassLogo.jpeg'

const MasterPassDepositCard: React.FC<MasterPassDepositCardProps> = props => {
  const { selectedLoading = false, walletId, ...rest } = props
  const { t } = useTranslation()
  const navigate = useNavigate()
  const selected = useSelectedCard('masterpass')
  const { mpAccountType, mpAccountLoading } = useAppSelector(state => state.depositSlice)

  React.useEffect(() => {
    if (mpAccountType !== 'unknown') return
    MasterPassService.checkMasterPass()
  }, [mpAccountType])

  React.useEffect(() => {
    if (mpAccountType !== 'registered' || !selected.card) return
    MasterPassService.syncSelectedCard(selected.card)
  }, [mpAccountType, selected.card])

  if ((mpAccountLoading || selected.isLoading) && !selected.card) return <DepositCard.Loader />

  if (selected.isFetching && selected.card && selectedLoading) return <DepositCard.Loader />

  if (mpAccountType === 'not-user')
    return (
      <DepositCard
        avatarImageSource={MasterPassLogo}
        title="MasterPass"
        description={t('@deposit.MasterPassDepositCard.register_description')}
        {...rest}
        onClick={() => navigate('/masterpass-card-register', { state: { walletId } })}
      />
    )

  if (mpAccountType === 'unlinked' || !selected.card)
    return (
      <DepositCard
        avatarImageSource={MasterPassLogo}
        title="MasterPass"
        description={t('@deposit.MasterPassDepositCard.link_description')}
        onClick={() => {
          navigate(mpAccountType === 'unlinked' ? '/masterpass-link' : '/masterpass-cards')
        }}
      />
    )

  return (
    <DepositCard
      avatarImageSource={MasterPassLogo}
      title={selected.card.name}
      description={selected.card.mask_pan}
      LayoutRightComponent={
        <Button size="tiny" variant="primary" radiusType="pill" onClick={() => navigate('/masterpass-cards')}>
          {t('@deposit.MasterPassDepositCard.edit_button')}
        </Button>
      }
      {...rest}
    />
  )
}

export default MasterPassDepositCard
