export default {
  SessionTimeout: {
    content_title: 'Oturum zaman aşımına uğradı',
    content_description: 'Oturumunuz sona erdi. Lütfen uygulamayı kapatıp açınız.',
  },
  InvalidToken: {
    content_title: 'Geçersiz oturum',
    content_description: 'Oturumunuz geçersiz. Lütfen uygulamayı kapatıp açınız.',
  },
}
