import { createApi } from '@reduxjs/toolkit/query/react'
import type { AppInfoData, AppInfoResponse, InitializeData, InitializeResponse } from './BaseApi.types'
import BaseQuery from './BaseQuery'

const baseApi = createApi({
  reducerPath: 'baseApi',
  baseQuery: BaseQuery,
  tagTypes: ['CardList', 'Wallets', 'WalletDetail', 'BalanceHistoryDetail', 'DepositCampaignDetail', 'Me'],
  endpoints: builder => ({
    appInfo: builder.query<AppInfoResponse, AppInfoData>({
      query: data => ({
        url: 'v2/app-info',
        method: 'POST',
        body: data,
      }),
    }),
    initialize: builder.query<InitializeResponse, InitializeData>({
      query: data => {
        localStorage.clear()
        return {
          url: `waas/token/validate/${data.token}`,
          method: 'GET',
        }
      },
    }),
  }),
})

export default baseApi
