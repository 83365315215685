export default {
  Auth: {
    route_type_login: 'Giriş Yap',
    route_type_register: 'Üye Ol',
    route_type_update: 'Telefonu Güncelle',
    content_header_title: 'Telefon Numarası Gir',
    content_header_login_description: 'Telefon numaranı girerek Macellan SuperApp hesabına giriş yapabilirsin',
    content_header_register_description: 'Telefon numaranı girerek Macellan SuperApp hesabını oluşturabilirsin',
    content_header_update_description:
      'Yeni telefon numaranı girerek Macellan SuperApp hesabına kayıtlı numaranı değiştirebilirsin',
    auth_action_user_not_fount_title: 'Kayıt Bulunamadı',
    auth_action_user_not_fount_message: "Girdiğin bilgilerle Macellan SuperApp'e üye olmak ister misin?",
    auth_action_user_not_fount_action_register: 'Üye Ol',
    submit_button: 'Gönder',
  },
  OTPVerify: {
    route_header_title: 'Onay Kodu',
    content_header_title: 'Onay Kodunu Gir',
    content_header_description: '{{phone_number}} numaralı telefonuna gönderilen kodu gir',
    resend_toast_message: 'Doğrulama kodun tekrar gönderildi',
    resend_verify_button: 'Tekrar Gönder',
    resend_verify_second: 'sn',
    update_phone_success_message: 'Telefon numarası başarıyla güncellendi',
  },
}
