import React from 'react'
import { useTranslation } from 'react-i18next'
import ThemeConfig from '~/tailwind.config'
import { InfoSheet } from '@/components/InfoSheet'

const InvalidToken: React.FC = () => {
  const { t } = useTranslation()
  const theme = ThemeConfig.theme.extend.colors

  return (
    <div className="flex h-screen items-center justify-center">
      <InfoSheet
        containerClassName="h-full"
        className="justify-center"
        avatarIcon="connection_error"
        avatarProps={{ iconProps: { color: theme.semantic.content.inkLight } }}
        title={t('@cammon.InvalidToken.content_title')}
        description={t('@cammon.InvalidToken.content_description')}
      />
    </div>
  )
}
export default InvalidToken
