import * as React from 'react'
import type { ToastChipProps } from './Toast.types'
import { getToastChipType } from './Toast.utils'
import { Badge } from '../Badge'

const ToastChip: React.FC<ToastChipProps> = props => {
  const { variant } = props

  return (
    <Badge {...props} variant={getToastChipType(variant)} size="small">
      {props.children}
    </Badge>
  )
}

ToastChip.displayName = 'Toast.Chip'

export default ToastChip
