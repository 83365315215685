import { AsYouType, formatNumber } from 'libphonenumber-js'
import type { FormatPhoneNumberFunction } from './OTPVerify.types'

export const formatPhoneNumber: FormatPhoneNumberFunction = phoneNumber => {
  const asYouType = new AsYouType()
  asYouType.input('+' + phoneNumber)

  const countryCode = asYouType.getCountry()
  if (!countryCode) return phoneNumber
  return formatNumber(phoneNumber, countryCode, 'INTERNATIONAL')
}
