import * as React from 'react'
import { useTranslation } from 'react-i18next'
import type { IconsNameType } from '@/components/Icons/Icon.types'
import type { WalletModuleName } from '@/pages/wallet/api/WalletApi.types'

export interface WalletModuleItem {
  title: string
  icon: IconsNameType
  nagivation: string
}

export interface WalletModuleParams {
  walletId: number
  companyId?: number
}

const useWalletModule = (name: WalletModuleName, _params: WalletModuleParams) => {
  const { t } = useTranslation()

  return React.useMemo<WalletModuleItem | undefined>(() => {
    if (name === 'payment')
      return {
        title: t('@wallet.WalletModules.pay'),
        icon: 'wallet',
        nagivation: '/payment-qr',
      }

    if (name === 'coupon') {
      return {
        title: t('@wallet.WalletModules.coupon'),
        icon: 'coupon',
        nagivation: '/coupon',
      }
    }

    if (name === 'deposit') {
      return {
        title: t('@wallet.WalletModules.deposit'),
        icon: 'plus-bold',
        nagivation: '/topup',
        params: {
          walletId: _params.walletId,
          companyId: _params.companyId,
        },
      }
    }
  }, [name, t, _params])
}

export default useWalletModule
