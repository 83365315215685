import cn from 'classnames'
import type { RowTitleProps } from './Row.types'
import styles from './Row.module.css'

const RowTitle: React.FC<RowTitleProps> = props => {
  const { title, className } = props

  const classNames = cn(styles.Title, className)

  return <h3 className={classNames}>{title}</h3>
}

export default RowTitle
