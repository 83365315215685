import React from 'react'
// import WalletDetailMenu from './WalletDetailMenu'
import { useLocation } from 'react-router-dom'
import { filter } from 'lodash'
import { Header } from '@/components/Header'
import { Section } from '@/components/Section'
import walletApi from '@/pages/wallet/api/WalletApi'
import WalletDetailSkeleton from '../../components/WalletDetailSkeleton/WalletDetailSkeleton'
import CampaignSection from './CampaignSection'
import ModulesSection from './ModulesSection'
import WalletDetailHeader from './WalletDetailHeader'
import WalletMovements from './WalletMovements'

const WalletDetailPage: React.FC = () => {
  const location = useLocation()
  const walletId = location.state?.walletId ?? localStorage.getItem('user.wallet_id')

  const walletDetailsQuery = walletApi.useWalletDetailsQuery(
    { wallet_id: walletId },
    { refetchOnMountOrArgChange: true },
  )

  const wallet = React.useMemo(() => {
    return walletDetailsQuery.data?.data
  }, [walletDetailsQuery.data?.data])

  const modules = React.useMemo(() => {
    return filter(walletDetailsQuery.data?.meta.waas_modules_sort_list, item => item.status)
  }, [walletDetailsQuery.data?.meta])

  if (walletDetailsQuery.isLoading) return <WalletDetailSkeleton />

  if (!wallet) return null

  return (
    <React.Fragment>
      <Header
        title={wallet.company.name}
        logo={wallet.company.logo}
        variant="primary"
        color="white"
        bgColor={wallet.company.color}
      />
      <Section className="!py-6" color={wallet.company.color}>
        <WalletDetailHeader currency={wallet.company.currency} balance={wallet.balance} point={wallet.point} />
        <ModulesSection modules={modules} walletId={walletId} companyId={wallet.company.id} />
      </Section>
      {/* <WalletDetailMenu color={wallet.company.color} /> */}
      <CampaignSection
        walletId={wallet.id}
        companyId={wallet.company.id}
        color={wallet.company.color}
        currency={wallet.company.currency}
      />
      <WalletMovements walletId={walletId} />
    </React.Fragment>
  )
}

export default WalletDetailPage
