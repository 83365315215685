import * as React from 'react'
import depositApi, { type CardType } from '../api'
import { PaymentMethodsService } from '../services'

const useSelectedCard = (type: CardType) => {
  const cardListQuery = depositApi.useUserCardListQuery(undefined, {
    refetchOnMountOrArgChange: true,
  })

  const selected = React.useMemo(() => {
    if (!cardListQuery.data) return
    return PaymentMethodsService.getSelectedCard(cardListQuery.data.data, type)
  }, [cardListQuery.data, type])

  return {
    card: selected,
    ...cardListQuery,
  }
}

export default useSelectedCard
