import React from 'react'
import { Link } from 'react-router-dom'
import type { SectionBarProps } from './SectionBar.types'
import styles from './SectionBar.module.css'
import ThemeConfig from '~/tailwind.config'
import { Container } from '../Container'
import { Icon } from '../Icons'

const TitleSectionBar: React.FC<SectionBarProps> = props => {
  const { colors } = ThemeConfig.theme.extend
  const { title, to, state, LayoutRightComponent } = props

  return (
    <div className={styles.SectionBarTopWrapper}>
      <h2 className={styles.SectionBarTitle}>{title}</h2>
      {to && (
        <div>
          <Link to={to} state={state}>
            <Icon name="arrow-right" size={24} color={colors.semantic.content.ink} />
          </Link>
        </div>
      )}
      {LayoutRightComponent && <LayoutRightComponent />}
    </div>
  )
}

const SubtitleSectionBar: React.FC<SectionBarProps> = props => {
  const { description } = props
  return <p className={styles.SectionBarDescription}>{description}</p>
}

const SectionBar: React.FC<SectionBarProps> = props => {
  const { type } = props
  return (
    <Container>
      <div className={styles.SectionBarContainer}>
        {type === 'title' || !type ? <TitleSectionBar {...props} /> : null}
        {type === 'subtitle' || !type ? <SubtitleSectionBar {...props} /> : null}
      </div>
    </Container>
  )
}

export default SectionBar
