import React from 'react'
import { Link } from 'react-router-dom'
import type { ModuleItemButtonProps } from './ModuleItem.types'
import styles from './ModuleItem.module.css'
import { ModuleItemButtonVariants } from './ModuleItem.utils'
import { Container } from '@/components/Container'
import { Icon } from '@/components/Icons'
import { useWalletModule } from '@/pages/wallet/hook'

const ModuleItemButton: React.FC<ModuleItemButtonProps> = props => {
  const { name, variant = 'secondary', params } = props

  const module = useWalletModule(name, params)

  if (!module) return null

  return (
    <Container className="flex flex-1">
      <Link
        to={module.nagivation}
        className={`${styles.ModuleItemContainer} ${ModuleItemButtonVariants[variant]}`}
        state={{ ...params }}>
        <Icon name={module.icon} size={24} />
        <p>{module.title}</p>
      </Link>
    </Container>
  )
}

export default ModuleItemButton
