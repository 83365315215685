import React from 'react'
import { Container } from '@/components/Container'

const TopUpSkeleton: React.FC = () => {
  return (
    <Container>
      <div className="h-screen animate-pulse overflow-y-hidden">
        <div className="flex h-16 w-full items-center justify-between border-b border-semantic-background-tertiary bg-semantic-background-primary px-4">
          <div className="h-6 w-6 rounded-md bg-semantic-background-tertiary" />
          <div className="h-6 w-24 rounded-md bg-semantic-background-tertiary" />
          <div className="h-6 w-6 rounded-md bg-semantic-background-tertiary" />
        </div>
        <div className="flex h-28 w-full items-center justify-between bg-semantic-background-primary px-6">
          <div className="flex items-center gap-3">
            <div className="h-12 w-12 rounded-full bg-semantic-background-tertiary" />
            <div className="flex flex-col gap-1">
              <div className="h-6 w-[145px] rounded-lg bg-semantic-background-tertiary" />
              <div className="h-4 w-[76px] rounded-md bg-semantic-background-tertiary" />
            </div>
          </div>
          <div className="h-8 w-[48px] rounded-lg bg-semantic-background-tertiary" />
        </div>
        <div>
          <div className="flex flex-col gap-4 px-6 pt-10">
            <div className="h-3 w-[122px] rounded-lg bg-semantic-background-secondary" />
            <div className="flex gap-3">
              {[...Array(4)].map((_, index) => (
                <div key={index} className="h-10 w-[82px] rounded-full bg-semantic-background-secondary" />
              ))}
            </div>
          </div>
          <div className="flex items-center gap-2 px-6 pt-4">
            <div className="h-3 w-[134px] rounded-md bg-semantic-background-secondary" />
            <div className="h-3 w-[66px] rounded-full bg-semantic-background-secondary" />
          </div>
          <div className="flex flex-col gap-4 px-6 py-7">
            <div className="h-3 w-[122px] rounded-lg bg-semantic-background-secondary" />
            <div className="h-[78px] w-full rounded-[20px] bg-semantic-background-secondary" />
          </div>
        </div>
        <div className="fixed bottom-0 left-0 flex h-[85px] w-full items-center justify-between border-t border-semantic-background-tertiary bg-semantic-background-primary px-6">
          <div className="flex flex-col gap-[2px]">
            <div className="h-4 w-[112px] rounded-md bg-semantic-background-tertiary" />
            <div className="h-6 w-[66px] rounded-lg bg-semantic-background-tertiary" />
          </div>
          <div className="h-12 w-[171px] rounded-[13px] bg-semantic-background-tertiary" />
        </div>
      </div>
    </Container>
  )
}

export default TopUpSkeleton
