import * as React from 'react'
import type { FilterButtonProps } from './FilterButton.types'
import styles from './FilterButton.module.css'
import { Icon } from '../Icons'

const FilterButton: React.FC<FilterButtonProps> = props => {
  return (
    <button className={styles.FilterButton} {...props}>
      <Icon name="filter" size={24} />
    </button>
  )
}

FilterButton.displayName = 'FilterButton'

export default FilterButton
