import type { SegmentedControlStaticProperties } from './SegmentedControl.types'
import SegmentedTab from './SegmentedTab'

const SegmentedControl: SegmentedControlStaticProperties = () => {
  return null
}

SegmentedControl.Tab = SegmentedTab

export default SegmentedControl
