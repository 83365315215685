import * as React from 'react'
import type { WalletDescriptionSectionProps } from './CompanyWalletSubscribe.types'
import { Section } from '@/components/Section'

const WalletDescriptionSection: React.FC<WalletDescriptionSectionProps> = ({ description, shortDescription }) => {
  if (!description || !shortDescription) return null

  return (
    <Section className="flex flex-col gap-6 !py-6">
      <p className="text-overline-lg text-semantic-content-inkLight">{shortDescription}</p>
      <p className="text-body-lg-r text-semantic-content-inkMedium">{description}</p>
    </Section>
  )
}

export default WalletDescriptionSection
