import * as React from 'react'
import cn from 'classnames'
import type { InfoSheetProps } from './InfoSheet.types'
import styles from './InfoSheet.module.css'
import ThemeConfig from '~/tailwind.config'
import { Button } from '../Button'
import { Container } from '../Container'
import { Icon } from '../Icons'

const InfoSheet: React.FC<InfoSheetProps> = props => {
  const {
    className,
    avatarIcon,
    avatarProps,
    title,
    titleStyle,
    description,
    descriptionStyle,
    question,
    buttons,
    ContentComponent,
    containerClassName,
  } = props
  const { colors } = ThemeConfig.theme.extend

  return (
    <Container className={containerClassName}>
      <div className={cn(styles.Container, className)}>
        <div className={styles.Header}>
          <div className={styles.Avatar} style={{ backgroundColor: avatarProps?.backgroundColor }}>
            <Icon name={avatarIcon} size={64} color={colors.semantic.content.ink} {...avatarProps?.iconProps} />
          </div>
          <h6 style={{ ...titleStyle }}>{title}</h6>
          <p>{description}</p>
        </div>
        <div className={styles.Footer}>
          <div className={styles.Content}>
            <p style={{ ...descriptionStyle }}>{question}</p>
            {ContentComponent ? (typeof ContentComponent === 'function' ? ContentComponent() : ContentComponent) : null}
          </div>
          <div className={styles.Buttons}>
            {buttons?.map((buttonProps, index) => <Button key={index} {...buttonProps} />)}
          </div>
        </div>
      </div>
    </Container>
  )
}

export default InfoSheet
