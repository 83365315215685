import type { CampaignItemBoxProps } from './CampaignItem.types'
import styles from './CampaignItem.module.css'
import { useFormatCurrency } from './CampaignItem.hooks'
import { useContentConfig } from '@/hooks'
import { Button } from '../Button'
import { Container } from '../Container'

const CampaignItemBox: React.FC<CampaignItemBoxProps> = props => {
  const { isUsed, point, currency, description, actionButtonClick } = props
  const { currencySymbol, formattedText } = useFormatCurrency(point, currency)
  const content = useContentConfig()

  return (
    <Container>
      <div className={styles.Box}>
        <div className={styles.BoxHeader}>
          <div>
            <span>{`${currencySymbol} ${formattedText}`}</span>
            <p>{content.campaignItem.pointSuffix}</p>
          </div>
          <p>{description}</p>
        </div>
        <Button variant="primary" radiusType="pill" size="tiny" onClick={actionButtonClick}>
          {isUsed ? content.campaignItem.disabledButtonText : content.campaignItem.detailsButtonText}
        </Button>
      </div>
    </Container>
  )
}

export default CampaignItemBox
