import React from 'react'
import { useNavigate } from 'react-router-dom'
import { find } from 'lodash'
import { CurrencyFormatter } from '@macellan/formatter'
import type { CustomAmountFieldProps } from './TopUp.types'
import styles from './TopUp.module.css'
import i18n from '@/translations'

const CustomAmountField: React.FC<CustomAmountFieldProps> = props => {
  const locale = i18n.getLocale()
  const navigate = useNavigate()
  const { value, defaultAmounts, currency, walletId } = props

  const visible = React.useMemo(() => {
    return !find(defaultAmounts, defaultAmount => defaultAmount.amount === value)
  }, [value, defaultAmounts])

  const formattedAmount = React.useMemo(() => {
    return CurrencyFormatter.formatToDetails(value, locale, currency)
  }, [value, currency, locale])

  const handleNavigateToCustomAmount = React.useCallback(() => {
    navigate('/topup/custom-amount', { state: { defaultAmount: value, walletId: walletId } })
  }, [value, navigate, walletId])

  if (!visible || !value) return null

  return (
    <button
      className={styles.CustomAmountField}
      onClick={handleNavigateToCustomAmount}>{`${formattedAmount.symbol}${formattedAmount.digid}`}</button>
  )
}

export default CustomAmountField
