import type { DetailsData, DetailsResponse, WalletSubscribeData, WalletSubscribeResponse } from './CompanyApi.types'
import baseApi from '@/api/BaseApi'

const companyApi = baseApi.injectEndpoints({
  endpoints: builder => ({
    walletSubscribe: builder.mutation<WalletSubscribeResponse, WalletSubscribeData>({
      query: data => ({
        url: '/wallet/subscribe',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: (_, error) => (error ? [] : ['Wallets']),
    }),
    details: builder.query<DetailsResponse, DetailsData>({
      query: data => ({
        url: `v2/company/${data.company_id}/details`,
        method: 'GET',
      }),
    }),
  }),
  overrideExisting: true,
})

export default companyApi
