import type { CampaignDetailContentProps } from './CampaignDetail.types'
import styles from './CampaignDetail.module.css'
import { Container } from '@/components/Container'
import { Section } from '@/components/Section'

const CampaignDetailContent: React.FC<CampaignDetailContentProps> = props => {
  const { title, description } = props
  return (
    <Container>
      <Section className="flex flex-col gap-4 !pt-6 pb-0">
        <h1 className={styles.CampaignDetailTitle}>{title}</h1>
        <div className={styles.CampaignDetailDescription} dangerouslySetInnerHTML={{ __html: description }} />
      </Section>
    </Container>
  )
}

export default CampaignDetailContent
