import type { RouteObject } from 'react-router-dom'
import { Capture, Payment, PaymentRefundEftPos, PaymentRefundPage } from '@/pages/payment/views'

export const PaymentRouter: RouteObject[] = [
  {
    path: '/capture',
    element: <Capture />,
  },
  {
    path: '/payment-refund',
    element: <PaymentRefundPage />,
  },
  {
    path: '/payment-refund-eftpos',
    element: <PaymentRefundEftPos />,
  },
  {
    path: '/payment-qr',
    element: <Payment.PaymentQR />,
  },
]
