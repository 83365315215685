import React from 'react'
import type { CampaignItemComponent, CampaignItemStaticProperties } from './CampaignItem.types'
import styles from './CampaignItem.module.css'
import { Container } from '../Container'
import CampaignItemBox from './CampaignItemBox'
import CampaignItemCard from './CampaignItemCard'
import CampaignItemTiny from './CampaignItemTiny'

const CampaignItem: React.FC<CampaignItemStaticProperties> & CampaignItemComponent = props => {
  const { children } = props
  return (
    <Container>
      <div className={styles.Layout}>{children}</div>
    </Container>
  )
}

CampaignItem.Card = CampaignItemCard
CampaignItem.Box = CampaignItemBox
CampaignItem.Tiny = CampaignItemTiny

export default CampaignItem
