import React from 'react'
import cn from 'classnames'
import type { RowDescriptionProps } from './Row.types'
import styles from './Row.module.css'

const RowDescription: React.FC<RowDescriptionProps> = props => {
  const { title, message, className } = props

  const classes = cn(styles.RowDescriptionContainer, className)

  return (
    <div className={classes}>
      <h3 className={styles.RowDescriptionTitle}>{title}</h3>
      <p className={styles.RowDescriptionMessage}>{message}</p>
    </div>
  )
}

export default RowDescription
