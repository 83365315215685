import React from 'react'
import { useTranslation } from 'react-i18next'
import type { CampaignItemTinyProps } from './CampaignItem.types'
import styles from './CampaignItem.module.css'
import { useFormatCurrency } from './CampaignItem.hooks'
import { Button } from '../Button'

const CampaignItemTiny: React.FC<CampaignItemTinyProps> = props => {
  const { currency, amount, description, ButtonProps } = props
  const { t } = useTranslation()
  const { formattedAmount } = useFormatCurrency(amount, currency)

  return (
    <div className={styles.TinyContainer}>
      <div className="flex gap-2">
        <div className={styles.LeftWrapper}>{formattedAmount}</div>
        <div className={styles.TinyContent}>
          <p>{description}</p>
        </div>
      </div>
      <div className={styles.RightWrapper}>
        <Button variant="ink-tonal" size="tiny" radiusType="pill" {...ButtonProps}>
          {t('@campaign.CampaignItemTiny.button_text')}
        </Button>
      </div>
    </div>
  )
}

export default CampaignItemTiny
