import React from 'react'
import { AnimatePresence } from 'framer-motion'
import type { DropdownFieldProps, DropdownFieldStaticProperties } from './DropdownField.types'
import styles from './DropdownField.module.css'
import { Container } from '../Container'
import { Icon } from '../Icons'
import DropdownModal from './DropdownModal'

const DropdownField: React.FC<DropdownFieldProps> & DropdownFieldStaticProperties = props => {
  const {
    modalHeaderTitle,
    items,
    value,
    label,
    placeholder,
    rightIcon = 'caret-fill-down',
    onChangeValue,
    buttonProps,
  } = props
  const [isOpened, setOpened] = React.useState<boolean>(false)

  return (
    <Container>
      <div className={styles.Input}>
        <p className={buttonProps?.disabled ? styles.DisabledText : ''}>{label}</p>
        <button onClick={() => setOpened(!isOpened)} {...buttonProps}>
          <span>{value?.text ?? placeholder}</span>
          <Icon name={rightIcon} size={24} />
        </button>
      </div>
      <AnimatePresence>
        {isOpened && (
          <DropdownModal
            items={items}
            value={value}
            onDismiss={() => setOpened(false)}
            headerTitle={modalHeaderTitle}
            onChangeValue={item => {
              onChangeValue(item)
              setOpened(false)
            }}
          />
        )}
      </AnimatePresence>
    </Container>
  )
}

export default DropdownField
