import React from 'react'
import type { ReadMoreProps } from './ReadMore.types'
import styles from './ReadMore.module.css'
import { expandOrCollapseContent, getButtonText, getDisplayedContent, shouldShowFullContent } from './ReadMore.utils'
import { Button } from '../Button'

const ReadMore: React.FC<ReadMoreProps> = props => {
  const { content, limit, buttonText } = props
  const contentRef = React.useRef<HTMLDivElement>(null)
  const [showMore, setShowMore] = React.useState(false)
  const [limitContent] = React.useState(limit || 130)

  React.useEffect(() => {
    expandOrCollapseContent(contentRef, showMore)
  }, [showMore])

  if (shouldShowFullContent(content, limitContent)) {
    return <div className={`${styles.Text} whitespace-pre-wrap`} dangerouslySetInnerHTML={{ __html: content }} />
  }

  return (
    <div className="relative flex flex-col gap-2">
      <div
        ref={contentRef}
        className={`${styles.Text} whitespace-pre-wrap ${styles.expandAnimation}`}
        dangerouslySetInnerHTML={{ __html: getDisplayedContent(content, limitContent, showMore) }}></div>
      {!showMore && <div className={styles.GradientOverlay} />}
      <div className="-ml-1.5 flex">
        <Button
          variant="ghost-on-light"
          size="tiny"
          radiusType="pill"
          rightIcon="caret-down"
          onClick={() => setShowMore(!showMore)}>
          {getButtonText(showMore, buttonText)}
        </Button>
      </div>
    </div>
  )
}

export default ReadMore
