import React from 'react'
import { useLocation } from 'react-router-dom'
import styles from './Capture.module.css'
import { useCheckPaymentStatus } from './Capture.hooks'
import { Header } from '@/components/Header'
import type { PaymentStatus } from '@/pages/payment/api'
import type { Wallet } from '@/pages/wallet/api'
import CallbackWaiting from './CallbackWaiting'
import CaptureFooter from './CaptureFooter'
import CapturePrice from './CapturePrice'
import CaptureWallet from './CaptureWallet'

const Capture: React.FC = () => {
  const { state } = useLocation()

  const [wallet, setWallet] = React.useState<Wallet>(state.wallet)
  const [pointUsage, setPointUsage] = React.useState<boolean>(false)
  const [status, setStatus] = React.useState<PaymentStatus>(state.payment.status)

  useCheckPaymentStatus(state.payment.payment_id, status, setStatus)

  const payment = React.useMemo(() => state.payment, [state.payment])

  if (status === 'callback_waiting') return <CallbackWaiting />

  return (
    <React.Fragment>
      <div className={styles.CaptureHeader}>
        <Header
          variant="primary"
          title={wallet.company.name}
          logo={wallet.company.logo}
          bgColor="transparent"
          color="black"
        />
        <CapturePrice amount={payment.price} currency={wallet.company.currency} />
      </div>
      <CaptureWallet
        wallet={wallet}
        payment={payment}
        paymentId={payment.payment_id}
        status={status}
        price={payment.paid_price}
        onChangePointUsage={setPointUsage}
        onRefectWallet={setWallet}
      />
      <CaptureFooter
        paymentId={payment.payment_id}
        walletId={wallet.id}
        pointUsage={pointUsage}
        onChangeStatus={setStatus}
      />
    </React.Fragment>
  )
}

export default Capture
