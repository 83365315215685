import React from 'react'
import { AnimatePresence } from 'framer-motion'
import { ToastContext } from '@/context'
import { Modal } from '../Modal'
import Toast from './Toast'

const ToastContainer: React.FC = () => {
  const { toasts, modal } = React.useContext(ToastContext)

  return (
    <div className="fixed bottom-0 left-0 z-[999999] h-full w-full" style={{ pointerEvents: 'none' }}>
      <div
        className="absolute bottom-[calc(var(--toolbar-height)+12px)] flex w-full flex-col gap-2 px-3"
        style={{ pointerEvents: 'all' }}>
        {toasts.map(toast => (
          <Toast
            key={toast.id}
            variant={toast.variant}
            message={`${toast.message}`}
            icon={toast.icon}
            LayoutRightComponent={toast.LayoutRightComponent}
            duration={toast.duration ?? 3000}
          />
        ))}
      </div>
      <div
        className={`screen-modal absolute bottom-3 flex w-full flex-col gap-2 px-3`}
        style={{ pointerEvents: 'all' }}>
        <AnimatePresence>{modal && <Modal {...modal} />}</AnimatePresence>
      </div>
    </div>
  )
}

export default ToastContainer
