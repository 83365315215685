/* eslint-disable */
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { CurrencyFormatter } from '@macellan/formatter'
import toast from '@/context'
import { Emitter } from '@/utils'
import i18n from '@/translations'
import type { QrInfoResponse, RefundMinimal } from '@/pages/payment/api'
import paymentApi, { type PaymentMinimal } from '@/pages/payment/api'
import { usePaymentEvents } from '@/pages/payment/hooks'
import userApi from '@/pages/user/api'
import { SocketService } from '@/services'

export type QrInfoEventResponse = Pick<QrInfoResponse, 'data'>['data']

export type QrErrorType = 'qr_expired' | 'no_wallet'

export interface QrErrorResponse {
  type: QrErrorType
  message: string
}

const useSocketListener = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const handlePaymentEvents = usePaymentEvents()
  const accessToken = localStorage.getItem('user.access_token')

  const [connected, setConnected] = React.useState<boolean>(false)

  const userMeQuery = userApi.useMeQuery(undefined, {
    skip: !accessToken,
  })

  const userId = React.useMemo(() => {
    return userMeQuery.data?.data.id
  }, [userMeQuery.data?.data.id])

  const [refundQrReply] = paymentApi.useRefundQrReplyMutation()

  const handleRefundQrReply = React.useCallback(
    async (reply: boolean, id: number) => {
      await refundQrReply({
        request_id: id,
        reply,
      }).unwrap()

      reply
        ? toast.success({
            message: t('socket_context.refund_request_event.alert_action_submit_toast'),
            icon: 'check-circle',
          })
        : toast.error({ message: t('socket_context.refund_request_event.alert_action_reject_toast'), icon: 'warning' })

      navigate('/', { replace: true })
    },
    [navigate, refundQrReply, t],
  )

  const listen = React.useCallback(() => {
    const echo = SocketService.getInstance()
    const channel = echo?.private(`users.${userId}`)

    if (!channel) return

    channel.error((error: any) => {
      if (error.status === 403) {
        SocketService.connect()
      }
    })

    channel.listen('.user.logout', () => Emitter.emit('user-logout'))

    channel.listen('.refund_request.event', async (event: RefundMinimal) => {
      const showAlert = () =>
        toast.modal({
          variant: 'suggestion',
          title: t('socket_context.refund_request_event.alert_title'),
          description: t('socket_context.refund_request_event.alert_message', {
            amount: CurrencyFormatter.format(event.amount, i18n.getLocale(), event.company.currency),
          }),
          icon: 'info',
          actions: [
            {
              variant: 'primary',
              size: 'large',
              radiusType: 'rounded',
              children: t('socket_context.refund_request_event.alert_action_submit'),
              onClick: async () => {
                await handleRefundQrReply(true, event.id)
              },
            },
            {
              variant: 'secondary',
              size: 'large',
              radiusType: 'rounded',
              children: t('socket_context.refund_request_event.alert_action_reject'),
              onClick: async () => {
                await handleRefundQrReply(false, event.id)
              },
            },
          ],
        })

      return setTimeout(showAlert, 750)
    })

    channel.listen('.qr_info.event', async (event: QrInfoEventResponse) => {
      navigate('/capture', {
        state: {
          payment: event.payment,
          wallet: event.wallet,
        },
      })
    })

    channel.listen('.qr.error.event', async (event: QrErrorResponse) => {
      toast.error({ icon: 'warning', message: event.message })
    })

    channel.listen('.payment.event', async (event: PaymentMinimal) => {
      handlePaymentEvents(event)
    })
  }, [handlePaymentEvents, handleRefundQrReply, navigate, userId, t])

  React.useEffect(() => {
    if (!connected) {
      SocketService.connect()
      setConnected(true)
    }

    return () => {
      SocketService.disconnect()
      setConnected(false)
    }
  }, [])

  React.useEffect(() => {
    if (!connected || !userId) return
    listen()
  }, [connected, listen, userId])
}

export default useSocketListener
