/* eslint-disable  */
import * as React from 'react'
import type { TimerCircleProps, TimerMethods } from './Timer.types'
import { Progress } from '../Progress'

const TimerCircle = React.forwardRef<TimerMethods, TimerCircleProps>((props, ref) => {
  const {
    size = 100,
    duration = 3000,
    auto = true,
    isReverse = true,
    valueTextColor,
    durationPrefix = '',
    durationSuffix = '',
    onFinished,
    ...rest
  } = props
  const [timerValue, setTimerValue] = React.useState<number>(duration)
  const intervalRef = React.useRef<any>()

  const calculateOffset = (timerValue: number) => {
    return isReverse ? (timerValue / duration) * 100 : 100 - (timerValue / duration) * 100
  }

  const [offset, setOffset] = React.useState<number>(calculateOffset(timerValue))

  const handleStartTimer = React.useCallback(() => {
    clearInterval(intervalRef.current)
    const end = Date.now() + duration

    intervalRef.current = setInterval(() => {
      setTimerValue(_prev => {
        const newTimerValue = Math.max(end - Date.now(), 0)
        if (newTimerValue === 0) {
          clearInterval(intervalRef.current)
          onFinished && onFinished()
        }
        return newTimerValue
      })
    }, 1)
  }, [duration, isReverse, onFinished])

  React.useEffect(() => {
    setOffset(calculateOffset(timerValue))
  }, [timerValue, duration, isReverse])

  const handleResetTimer = React.useCallback(
    (autoStart = false) => {
      clearInterval(intervalRef.current)
      setTimerValue(duration)
      setOffset(isReverse ? 100 : 0)
      if (autoStart) {
        handleStartTimer()
      }
    },
    [duration, isReverse, handleStartTimer],
  )

  const handleSetTimerValue = React.useCallback(
    (value: number) => {
      clearInterval(intervalRef.current)
      setTimerValue(value)
      setOffset(calculateOffset(value))
    },
    [duration, isReverse],
  )

  React.useEffect(() => {
    if (auto) {
      handleStartTimer()
    }
    return () => clearInterval(intervalRef.current)
  }, [auto, handleStartTimer])

  React.useImperativeHandle(ref, () => ({
    startTimer: handleStartTimer,
    resetTimer: handleResetTimer,
    setTimerValue: handleSetTimerValue,
  }))

  return (
    <Progress size={size} value={offset} totalValue={isReverse ? 100 : 0} {...rest}>
      {`${durationPrefix}${(timerValue / 1000).toFixed(0)}${durationSuffix}`}
    </Progress>
  )
})

export default TimerCircle
