import * as React from 'react'
import { CurrencyFormatter } from '@macellan/formatter'
import type { RewardChartTableListItemProps } from './RewardChart.types'
import styles from './RewardChart.module.css'
import { tooltipPosition } from './RewardChart.utils'
import { RewardChartTableListItemSizes } from './RewardChart.constants'
import i18n from '@/translations'

const RewardChartTableListItem: React.FC<RewardChartTableListItemProps> = props => {
  const { balance, color, totalBalance, currency } = props
  const [balanceVisible, setBalanceVisible] = React.useState(false)
  const locale = i18n.getLocale()

  const showBalance = React.useCallback(() => {
    setBalanceVisible(!balanceVisible)
  }, [balanceVisible])

  const formattedPoint = React.useMemo(() => {
    return CurrencyFormatter.format(balance, locale, currency)
  }, [balance, locale, currency])

  return (
    <React.Fragment>
      <button
        className={styles.RewardChartTableListItem}
        style={{ width: RewardChartTableListItemSizes.width, height: RewardChartTableListItemSizes.height }}
        onClick={showBalance}>
        <div
          className={styles.RewardChartTableListItemBar}
          style={{
            width: RewardChartTableListItemSizes.width,
            height: (balance / totalBalance) * RewardChartTableListItemSizes.height,
            backgroundColor: color,
          }}
        />
        {balanceVisible ? (
          <div
            className={`${styles.RewardChartTableListItemPointTop}`}
            style={{ bottom: tooltipPosition(balance, totalBalance, RewardChartTableListItemSizes).position }}>
            {formattedPoint}
            {tooltipPosition(balance, totalBalance, RewardChartTableListItemSizes).tooltipArrow}
          </div>
        ) : null}
      </button>
    </React.Fragment>
  )
}

export default RewardChartTableListItem
