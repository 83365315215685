import styles from './Header.module.css'
import ThemeConfig from '~/tailwind.config'

const { colors } = ThemeConfig.theme.extend

export const VariantDividers = {
  white: styles.dividerWhite,
  black: styles.dividerBlack,
}

export const GetColors = {
  white: colors.semantic.content.inkInverse,
  black: colors.semantic.content.ink,
}

export const handleScroll = (setIsScrolled: React.Dispatch<React.SetStateAction<boolean>>, bgColor?: string) => {
  if (bgColor && bgColor !== 'transparent') return
  const offsetY = window.scrollY
  const limit = 35
  if (offsetY > limit) {
    if (setIsScrolled) setIsScrolled(true)
    return
  }
  if (setIsScrolled) setIsScrolled(false)
}

export const getBackgroundColor = (isScrolled: boolean, bgColor?: string, color?: 'white' | 'black') => {
  if (bgColor && bgColor !== 'transparent') return bgColor

  if (isScrolled) {
    switch (color) {
      case 'white':
        return colors.semantic.content.ink
      case 'black':
        return colors.semantic.content.inkInverse
      default:
        return bgColor
    }
  }

  return 'transparent'
}
