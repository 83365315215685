import type { RouteObject } from 'react-router-dom'
import { Auth, OTPVerify } from '@/pages/user/views'

export const UserRouter: RouteObject[] = [
  {
    path: '/auth',
    element: <Auth />,
  },
  {
    path: '/otp-verify',
    element: <OTPVerify />,
  },
]
