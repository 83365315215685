import React from 'react'
import type { IconsNameType } from '../Icons/Icon.types'
import type { TextFieldProps } from './TextField.types'
import styles from './TextField.module.css'
import { Icon } from '../Icons'

export const renderIcon = (iconName: IconsNameType | undefined, size: number) => {
  if (!iconName) return null
  return <Icon name={iconName} size={size} />
}

export const getClassNames = (props: { error?: string; disabled?: boolean }) => {
  let classNames = styles.TextFieldContainer

  if (props.error === 'error') {
    classNames += ` ${styles.Error}`
  }

  if (props.disabled) {
    classNames += ` ${styles.Disabled}`
  }

  return classNames
}

export const Label = (props: TextFieldProps) => {
  return (
    <React.Fragment>
      {props.label && (
        <div className={styles.LabelContainer}>
          {renderIcon(props.labelLeftIcon, 16)}
          <label htmlFor={props.id} className={styles.Label}>
            {props.label}
          </label>
          {renderIcon(props.labelRightIcon, 16)}
        </div>
      )}
    </React.Fragment>
  )
}

export const Input = React.forwardRef<HTMLInputElement | HTMLTextAreaElement, TextFieldProps>((props, ref) => {
  const { disabled, leftIcon, rightIcon, multiline, ...rest } = props
  return (
    <div className={`${styles.InputContainer}`}>
      {renderIcon(leftIcon, 24)}
      {multiline && (
        <textarea
          ref={ref as React.Ref<HTMLTextAreaElement>}
          className={`${styles.Input}`}
          disabled={disabled}
          {...rest}
        />
      )}
      {!multiline && (
        <input ref={ref as React.Ref<HTMLInputElement>} className={`${styles.Input}`} disabled={disabled} {...rest} />
      )}
      {renderIcon(rightIcon, 24)}
    </div>
  )
})

export const Helper = (props: TextFieldProps) => {
  return <React.Fragment>{props.message && <span className={styles.Helper}>{props.message}</span>}</React.Fragment>
}
