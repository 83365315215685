import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { MasterPassSDK } from '@macellan/masterpass-sdk/src'
import { useCompletePurchase } from '../TopUp/TopUp.hooks'
import toast from '@/context/ToastContext/Toast.utils'
import { useAppDispatch, useAppSelector } from '@/store'
import { ActionLabel } from '@/components/ActionLabel'
import { Button } from '@/components/Button'
import { ContentHeader } from '@/components/ContentHeader'
import { Header } from '@/components/Header'
import { PinInput } from '@/components/PinInput'
import { Section } from '@/components/Section'
import { Spinner } from '@/components/Spinner'
import { MasterPassService } from '../../services'
import { setMpAccountType } from '../../slice'

const MasterPassOTP: React.FC = () => {
  const { t } = useTranslation()
  const route = useLocation()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const [pinValue, setPinValue] = React.useState<string>('')
  const [visibleHelpButton, setVisibleHelpButton] = React.useState<boolean>(false)
  const handleCompletePurchase = useCompletePurchase()

  const { mpResendOTPLoading, mpValidateTransactionLoading } = useAppSelector(state => state.depositSlice)

  const mpResendOTP = React.useCallback(() => {
    MasterPassService.resendOtp()
      .then(() => {
        toast.default({
          message: t('@deposit.MasterPassOTP.resend_otp_toast_message'),
          icon: 'asterisk',
        })
      })
      .catch(error => {
        toast.default({
          message: error.data.message,
          icon: 'warning',
        })
      })
      .finally(() => {
        setVisibleHelpButton(true)
      })
  }, [t])

  const handleSubmitValue = React.useCallback(
    (value: string) => {
      MasterPassService.validateTransaction(value)
        .then(async result => {
          if (route.state?.otpType === 'purchase' && route.state?.depositId) {
            if (
              result.responseCode !== MasterPassSDK.Constants.ResponseCodes.SUCCESS &&
              result.responseCode !== MasterPassSDK.Constants.ResponseCodes.SUCCESS_EMPTY
            ) {
              toast.error({ message: t('@deposit.MasterPassOTP.error_message'), icon: 'warning', duration: 10000 })
              MasterPassService.fail(route.state?.depositId, result)

              handleCompletePurchase(false, route.state?.canGoBack)
              return
            }

            await MasterPassService.makeDeposit({
              depositId: route.state?.depositId,
              token: result.token,
            })

            handleCompletePurchase(true, route.state?.canGoBack)
            return
          }

          const message =
            route.state?.otpType === 'link'
              ? t('@deposit.MasterPassOTP.success_type_link')
              : t('@deposit.MasterPassOTP.success_type_register')
          toast.success({ message: message, icon: 'check-circle', duration: 10000 })

          dispatch(setMpAccountType('registered'))

          const mpCards = await MasterPassService.listCards()

          if (mpCards.length) {
            await MasterPassService.selectCard(mpCards[0])
          }

          return navigate('/topup', {
            replace: true,
            state: {
              selectedLoading: true,
              walletId: route.state?.walletId,
            },
          })
        })
        .catch(error => {
          toast.error({ message: error.data.message, icon: 'warning', duration: 10000 })
        })
        .finally(() => {
          setPinValue('')
        })
    },
    [dispatch, navigate, route.state, t, handleCompletePurchase],
  )

  return (
    <React.Fragment>
      <Header
        variant="secondary"
        title={t('@deposit.MasterPassOTP.route_header_title')}
        goBack={() => navigate(-1)}
        rightAction={mpValidateTransactionLoading ? <Spinner size={24} /> : undefined}
      />
      <Section>
        <ContentHeader
          title={t('@deposit.MasterPassOTP.content_header_title')}
          description={t('@deposit.MasterPassOTP.content_header_description')}
        />
        <div className="mt-10 space-y-[18px]">
          <PinInput value={pinValue} onChangeValue={setPinValue} onSubmitValue={handleSubmitValue} autoFocus={true} />
          <div className="flex items-center justify-between">
            <Button
              type="button"
              variant="primary"
              size="tiny"
              radiusType="pill"
              leftIcon="notification"
              disabled={mpValidateTransactionLoading}
              loading={mpResendOTPLoading}
              onClick={mpResendOTP}
              containerClassName="flex">
              {t('@deposit.MasterPassOTP.resend_otp_button')}
            </Button>
            {visibleHelpButton && (
              <ActionLabel
                type="blue"
                style={{
                  color: 'red!important',
                }}
                size="medium"
                label={t('@deposit.MasterPassOTP.action_help_button')}
                onClick={() => navigate('/masterpass-help')}
              />
            )}
          </div>
        </div>
      </Section>
    </React.Fragment>
  )
}

export default MasterPassOTP
