import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Trans, useTranslation } from 'react-i18next'
import styles from './CompanyWalletSubscribe.module.css'
import toast from '@/context'
import { Button } from '@/components/Button'
import { Header } from '@/components/Header'
import { ToolBar } from '@/components/ToolBar'
import companyApi from '@/pages/company/api/CompanyApi'
import WalletDescriptionSection from './WalletDescriptionSection'
import WalletHeaderSection from './WalletHeaderSection'

const CompanyWalletSubscribe: React.FC = () => {
  const navigate = useNavigate()
  const { state } = useLocation()
  const { t } = useTranslation()
  const companyId = state.companyId

  const [walletSubscribe, walletSubscribeResult] = companyApi.useWalletSubscribeMutation()

  const detailsQuery = companyApi.useDetailsQuery({
    company_id: companyId,
  })

  const handleWalletSubscribe = React.useCallback(async () => {
    const result = await walletSubscribe({
      company_id: companyId,
      agreement_approved: true,
    }).unwrap()

    localStorage.setItem('user.wallet_id', String(result.data.wallet.id))

    if (state?.redirect_page === 'deposit')
      return navigate('/topup', {
        state: { walletId: result.data.wallet.id, triggerData: state?.triggerData },
      })

    navigate('/', {
      state: {
        walletId: result.data.wallet.id,
        wallet: result.data.wallet,
      },
    })

    toast.success({
      message: t('@company.CompanyWalletSubscribe.success_message'),
    })
  }, [companyId, navigate, t, walletSubscribe, state])

  if (!detailsQuery.data) return null

  return (
    <React.Fragment>
      <Header
        variant="secondary"
        title={t('@company.CompanyWalletSubscribe.route_header_title')}
        bgColor={detailsQuery.data.data.color}
        color="white"
        divider={false}
      />
      <WalletHeaderSection
        logo={detailsQuery.data.data.logo}
        name={detailsQuery.data.data.name}
        color={detailsQuery.data.data.color}
      />
      <WalletDescriptionSection
        description={detailsQuery.data.data.description}
        shortDescription={detailsQuery.data.data.short_description}
      />
      <ToolBar className={styles.Toolbar}>
        <Button
          variant="primary"
          size="large"
          radiusType="rounded"
          loading={walletSubscribeResult.isLoading}
          onClick={handleWalletSubscribe}>
          {t('@company.CompanyWalletSubscribe.add_button_title')}
        </Button>
        <div className={styles.CompanyWalletSubscribeAgreement}>
          <Trans
            i18nKey={'@company.CompanyWalletSubscribe.agreement_description'}
            components={[
              <span
                className={styles.CompanyWalletSubscribeAgreementLint}
                onClick={() =>
                  navigate('/wallet-agreement', {
                    state: {
                      companyId: companyId,
                      isWallet: false,
                    },
                  })
                }
              />,
            ]}
          />
        </div>
      </ToolBar>
    </React.Fragment>
  )
}

export default CompanyWalletSubscribe
