import * as React from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import baseApi from '@/api'
import toast from '@/context'
import { useAppDispatch } from '@/store'
import type { PaymentMinimal } from '@/pages/payment/api'
import walletApi from '@/pages/wallet/api/WalletApi'

const usePaymentEvents = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  return React.useCallback(
    (event: PaymentMinimal) => {
      if (event.status === 'no_balance') {
        toast.error({ message: t('socket_context.payment_event.toast_no_balance_message'), icon: 'warning' })
      }

      if (event.status === 'expire') {
        toast.error({ message: t('socket_context.payment_event.toast_expire_message'), icon: 'warning' })
      }

      if (event.status === 'device_offline') {
        toast.error({ message: t('socket_context.payment_event.toast_device_offline_message'), icon: 'warning' })
      }

      if (event.status === 'callback_no') {
        toast.error({ message: t('socket_context.payment_event.toast_callback_no'), icon: 'warning' })
      }

      if (event.status === 'service_error') {
        toast.error({ message: '', icon: 'warning' })
      }

      if (event.status === 'approval') {
        return
      }

      if (event.status === 'complete') {
        dispatch(baseApi.util.invalidateTags(['Wallets']))
        dispatch(walletApi.util.invalidateTags(['WalletDetail']))

        const showAlert = () => {
          toast.modal({
            title: t('socket_context.payment_event.alert_title'),
            description: t('socket_context.payment_event.alert_message'),
            variant: 'success',
            icon: 'check-circle',
            actions: [
              {
                variant: 'primary',
                size: 'large',
                radiusType: 'rounded',
                children: t('socket_context.payment_event.alert_action_submit'),
              },
            ],
          })
        }

        setTimeout(showAlert, 750)

        return navigate('/', { replace: true })
      }
    },
    [navigate, t, dispatch],
  )
}

export default usePaymentEvents
