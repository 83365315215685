import React from 'react'
import { split } from 'lodash'
import type { PhoneFieldProps } from './PhoneField.types'
import { useContentConfig } from '@/hooks'
import { getCountryByCode, getPhoneInformation } from './PhoneField.utils'
import { CountryConstants } from '@/constants'
import type { DropdownFieldItem } from '../DropdownField'
import DropdownField from '../DropdownField/DropdownField'
import { TextField } from '../TextField'

const PhoneField: React.FC<PhoneFieldProps> = props => {
  const {
    defaultValue,
    defaultCountryCode,
    textFieldStyle,
    textFieldProps,
    onChangeValue,
    onValidation,
    dropdownFieldProps,
  } = props
  const content = useContentConfig()

  const textField = React.useRef<HTMLInputElement>(null)

  const [isReady, setReady] = React.useState<boolean>(!defaultValue)
  const [country, setCountry] = React.useState<DropdownFieldItem | undefined>(getCountryByCode(defaultCountryCode))
  const [phoneNumber, setPhoneNumber] = React.useState<string>(defaultValue ?? '')

  const handleChangeText = React.useCallback(
    (text: string) => {
      setPhoneNumber(text)

      if (onChangeValue && country?.key) {
        onChangeValue(`${country?.key}${split(text, ' ').join('')}`)
      }
    },
    [country?.key, onChangeValue],
  )

  const handleChangeCountry = React.useCallback(
    (item: DropdownFieldItem) => {
      setCountry(item)
      handleChangeText('')
      textField.current?.focus()
    },
    [handleChangeText],
  )

  const isValid = React.useMemo(() => {
    return getPhoneInformation(`+${country?.key}${phoneNumber}`)?.isValid ?? false
  }, [country?.key, phoneNumber])

  React.useEffect(() => {
    if (!defaultValue) return

    const defaultPhoneInformation = getPhoneInformation(defaultValue)

    if (defaultPhoneInformation) {
      setCountry(defaultPhoneInformation.country)
      setPhoneNumber(defaultPhoneInformation.nationalNumber)
      onChangeValue?.(defaultPhoneInformation.country.key + defaultPhoneInformation.nationalNumber)
    }

    setReady(true)
  }, [defaultValue, onChangeValue])

  React.useEffect(() => {
    onValidation?.(isValid)
  }, [isValid, onValidation])

  React.useEffect(
    () => {
      if (!defaultValue) return
      handleChangeText(defaultValue)
    },
    // eslint-disable-next-line
    [],
  )

  if (!isReady) return null

  return (
    <div className="grid grid-cols-3 gap-2">
      <div className="col-span-1">
        <DropdownField
          label={content.phoneField.placeholders.countryDropdownFieldLabel}
          modalHeaderTitle={content.phoneField.placeholders.countryDropdownFieldModalHeaderTitle}
          value={country}
          placeholder="-"
          items={CountryConstants.phones}
          onChangeValue={handleChangeCountry}
          buttonProps={dropdownFieldProps}
        />
      </div>
      <div className="col-span-2 grid">
        <TextField
          ref={textField}
          style={textFieldStyle}
          autoFocus={true}
          inputMode="numeric"
          label={content.phoneField.placeholders.phoneNumberTextFieldLabel}
          value={phoneNumber}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChangeText(e.target.value)}
          {...textFieldProps}
        />
      </div>
    </div>
  )
}

export default PhoneField
