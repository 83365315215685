/* eslint-disable */
import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Trans, useTranslation } from 'react-i18next'
import { Formik, type FormikProps } from 'formik'
import { MasterPassSDK } from '@macellan/masterpass-sdk/src'
import type { TopUpAnotherCardForm, TopUpAnotherCardProps } from './TopUpAnotherCard.types'
import { maskCard } from './TopUpAnotherCard.utils'
import { cardValidationSchema } from './TopUpAnotherCard.validations'
import baseApi from '@/api'
import toast from '@/context'
import { useAppDispatch } from '@/store'
import { Button } from '@/components/Button'
import { CheckBox } from '@/components/CheckBox'
import { ContentHeader } from '@/components/ContentHeader'
import { Header } from '@/components/Header'
import { Section } from '@/components/Section'
import { TextField } from '@/components/TextField'
import { MaskUtils } from '@/utils'
import walletApi from '@/pages/wallet/api'
import { MasterPassService } from '@/pages/deposit/services'
import { setMpAccountType } from '@/pages/deposit/slice'
import { useCompletePurchase } from '@/pages/deposit/views/TopUp/TopUp.hooks'
import InstallmentSection from './InstallmentSection'

const TopUpAnotherCard: React.FC = () => {
  const navigate = useNavigate()
  const location = useLocation().state as TopUpAnotherCardProps
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const handlePurchaseComplete = useCompletePurchase()

  const [bin, setBin] = React.useState<string>('')
  const [installmentCount, setInstallmentCount] = React.useState<number | null>(null)

  const walletDetailsQuery = walletApi.useWalletDetailsQuery(
    { wallet_id: location.walletId },
    { refetchOnMountOrArgChange: true },
  )

  const formikRef = React.useRef<FormikProps<TopUpAnotherCardForm>>(null)

  const [loading, setLoading] = React.useState<boolean>(false)

  const wallet = React.useMemo(() => {
    return walletDetailsQuery.data?.data
  }, [walletDetailsQuery.data?.data])

  const handleCompletePurchase = React.useCallback(
    async (success: boolean) => {
      if (!success) return

      if (formikRef?.current?.values.saveCard) {
        dispatch(setMpAccountType('registered'))

        const mpCards = await MasterPassService.listCards()

        if (mpCards.length) {
          await MasterPassService.selectCard(mpCards[0])
        }
      }

      dispatch(baseApi.util.invalidateTags(['Wallets']))

      handlePurchaseComplete(success)
    },
    [dispatch, handlePurchaseComplete],
  )

  const handleSubmit = React.useCallback(
    async (values: TopUpAnotherCardForm) => {
      setLoading(true)

      try {
        const rtaPanRawValue = MaskUtils.getRawValue('credit-card', values.creditCardNo)

        const maskedRtaPan = maskCard(rtaPanRawValue)

        const expireDateRawValue = MaskUtils.getRawValue('date-time', values.expireDate, 'MM/YY')

        const expiryYear = expireDateRawValue.getFullYear().toString().slice(2, 4)

        const expiryMonth = ('0' + (expireDateRawValue.getMonth() + 1).toString()).slice(-2)

        const expiryDate = expiryYear + expiryMonth

        let purchaseResult = null

        if (values.saveCard) {
          purchaseResult = await MasterPassService.purchaseAndRegister({
            walletId: location.walletId,
            amount: location.amount,
            rtaPan: rtaPanRawValue,
            maskedRtaPan: maskedRtaPan,
            cvc: values.cvcNo,
            expiryDate: expiryDate,
            accountAliasName: values.name,
            installmentCount: installmentCount,
          })
        } else {
          purchaseResult = await MasterPassService.directPurchase({
            walletId: location.walletId,
            amount: location.amount,
            rtaPan: rtaPanRawValue,
            maskedRtaPan: maskedRtaPan,
            cvc: values.cvcNo,
            expiryDate: expiryDate,
            installmentCount: installmentCount,
          })

          if (
            purchaseResult.sdkResult.responseCode === MasterPassSDK.Constants.ResponseCodes.SUCCESS ||
            purchaseResult.sdkResult.responseCode === MasterPassSDK.Constants.ResponseCodes.SUCCESS_EMPTY
          ) {
            const mpMakeDepositResult = await MasterPassService.makeDeposit({
              depositId: purchaseResult.initResult.data.deposit_id,
              token: purchaseResult.sdkResult.token,
            })

            await handleCompletePurchase(true)
            return mpMakeDepositResult
          }
        }

        if (purchaseResult.sdkResult.responseCode === MasterPassSDK.Constants.ResponseCodes.VALIDATE_OTP) {
          await MasterPassService.markOTP(purchaseResult.initResult.data.deposit_id)

          navigate('/masterpass-otp', {
            state: {
              otpType: 'purchase',
              depositId: purchaseResult.initResult.data.deposit_id,
            },
          })
        }

        if (purchaseResult.sdkResult.responseCode === MasterPassSDK.Constants.ResponseCodes.VALIDATE_3D_SECURE) {
          navigate('/masterpass-3d', {
            state: {
              depositId: purchaseResult.initResult.data.deposit_id,
              threeDUrl: purchaseResult.sdkResult.url3D + '&returnUrl=' + purchaseResult.initResult.data.callback_url,
            },
          })
        }
      } catch (error: any) {
        toast.error({ message: error.message })
      } finally {
        setLoading(false)
      }
    },
    [handleCompletePurchase, installmentCount, navigate, location.amount, location.walletId],
  )

  React.useEffect(() => {
    formikRef.current?.setFieldValue('amount', location.amount, false)
  }, [location.amount])

  if (!wallet) return null

  return (
    <React.Fragment>
      <Header
        variant="secondary"
        divider={false}
        goBack={() =>
          navigate('/topup', {
            state: {
              walletId: location.walletId,
              customAmount: location.amount,
            },
          })
        }
      />
      <Formik
        innerRef={formikRef}
        initialValues={{
          amount: location.amount,
          creditCardNo: '',
          expireDate: '',
          cvcNo: '',
          saveCard: false,
          name: '',
          agreement: false,
        }}
        validationSchema={cardValidationSchema}
        onSubmit={handleSubmit}>
        {formik => (
          <Section className="!p-6">
            <ContentHeader
              title={t('@deposit.TopUpAnotherCard.content_header_title')}
              description={t('@deposit.TopUpAnotherCard.content_header_description')}
            />
            <div className="flex flex-col gap-4 pt-9">
              <TextField
                label={t('@deposit.TopUpAnotherCard.form_label_credit_card_no')}
                error={formik.touched.creditCardNo && formik.errors.creditCardNo ? 'error' : undefined}
                message={formik.touched.creditCardNo ? formik.errors.creditCardNo : undefined}
                maskType="credit-card"
                placeholder="•••• •••• •••• ••••"
                inputMode="numeric"
                pattern="[0-9]*"
                value={formik.values.creditCardNo}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setBin(e.target.value.replace(/\s/g, '').slice(0, 6))

                  formik.handleChange('creditCardNo')(e)
                }}
              />
              <TextField
                label={t('@deposit.TopUpAnotherCard.form_label_expire_date')}
                error={formik.touched.expireDate && formik.errors.expireDate ? 'error' : undefined}
                message={formik.touched.expireDate ? formik.errors.expireDate : undefined}
                placeholder={t('@deposit.MasterPassCardRegister.form_placeholder_expiry_date')}
                maskType="date-time"
                maskOptions={{
                  dateTimePattern: 'MM/YY',
                }}
                inputMode="numeric"
                pattern="[0-9]*"
                value={formik.values.expireDate}
                onBlur={formik.handleBlur('expireDate')}
                onChange={formik.handleChange('expireDate')}
              />
              <TextField
                label={t('@deposit.TopUpAnotherCard.form_label_cvc_no')}
                error={formik.touched.cvcNo && formik.errors.cvcNo ? 'error' : undefined}
                message={formik.touched.cvcNo ? formik.errors.cvcNo : undefined}
                type="text"
                autoCapitalize="words"
                placeholder={t('@deposit.TopUpAnotherCard.form_label_cvc_no')}
                value={formik.values.cvcNo}
                onBlur={formik.handleBlur('cvcNo')}
                onChange={formik.handleChange('cvcNo')}
              />
              <div className="mt-4 flex flex-col gap-6">
                <CheckBox checked={formik.values.saveCard} onChange={formik.handleChange('saveCard')}>
                  {t('@deposit.TopUpAnotherCard.form_save_card_title')}
                </CheckBox>
                {formik.values.saveCard ? (
                  <React.Fragment>
                    <TextField
                      label={t('@deposit.TopUpAnotherCard.form_label_name')}
                      error={formik.touched.name && formik.errors.name ? 'error' : undefined}
                      message={formik.touched.name ? formik.errors.name : undefined}
                      type="text"
                      autoCapitalize="words"
                      placeholder={t('@deposit.MasterPassCardRegister.form_placeholder_account_alias_name')}
                      value={formik.values.name}
                      onBlur={formik.handleBlur('name')}
                      onChange={formik.handleChange('name')}
                    />
                    <CheckBox checked={formik.values.agreement} onChange={formik.handleChange('agreement')}>
                      <Trans
                        i18nKey={'@deposit.TopUpAnotherCard.form_agreement_checkbox_title'}
                        components={[
                          <button
                            type="button"
                            className="text-link-sm text-semantic-content-link underline"
                            onClick={() =>
                              toast.modal({
                                variant: 'weblink',
                                children: (
                                  <iframe
                                    src={t('@deposit.MasterPassCardRegister.mp_user_agreement_url')}
                                    className="h-[calc(100vh-202px)] w-full"
                                  />
                                ),
                                actions: [
                                  {
                                    children: t('@deposit.MasterPassCardRegister.mp_user_agreement_header_description'),
                                    variant: 'primary',
                                    size: 'large',
                                    radiusType: 'rounded',
                                    onClick: () => formik.setFieldValue('agreement', true),
                                  },
                                ],
                              })
                            }
                          />,
                        ]}
                      />
                    </CheckBox>
                  </React.Fragment>
                ) : null}

                <InstallmentSection
                  walletId={wallet.id}
                  amount={formik.values.amount}
                  currency={wallet.company.currency}
                  bin={bin.length === 6 ? parseFloat(bin) : undefined}
                  onChangeCount={setInstallmentCount}
                />

                <Button
                  type="submit"
                  variant="primary"
                  size="large"
                  radiusType="rounded"
                  disabled={!formik.isValid}
                  loading={loading}
                  onClick={() => formik.handleSubmit()}>
                  {t('@deposit.TopUpAnotherCard.form_submit_button')}
                </Button>
              </div>
            </div>
          </Section>
        )}
      </Formik>
    </React.Fragment>
  )
}

export default TopUpAnotherCard
