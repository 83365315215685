import type { RouteObject } from 'react-router-dom'
import {
  DepositCards,
  MasterPass3D,
  MasterPassCardRegister,
  MasterPassCards,
  MasterPassHelp,
  MasterPassLink,
  MasterPassOTP,
  Payment3D,
  TopUp,
  TopUpAnotherCard,
  TopUpCustomAmount,
} from '@/pages/deposit/views'

export const DepositRouter: RouteObject[] = [
  {
    path: '/topup',
    element: <TopUp />,
  },
  {
    path: '/topup/another-card',
    element: <TopUpAnotherCard />,
  },
  {
    path: '/topup/custom-amount',
    element: <TopUpCustomAmount />,
  },
  {
    path: '/deposit-cards',
    element: <DepositCards />,
  },
  {
    path: '/masterpass-card-register',
    element: <MasterPassCardRegister />,
  },
  {
    path: '/masterpass-otp',
    element: <MasterPassOTP />,
  },
  {
    path: '/masterpass-3d',
    element: <MasterPass3D />,
  },
  {
    path: '/masterpass-cards',
    element: <MasterPassCards />,
  },
  {
    path: '/masterpass-link',
    element: <MasterPassLink />,
  },
  {
    path: '/masterpass-help',
    element: <MasterPassHelp />,
  },
  {
    path: '/payment-3d',
    element: <Payment3D />,
  },
]
