import React from 'react'
import { filter, map } from 'lodash'
import { useTranslation } from 'react-i18next'
import type { CampaignRewardListProps } from './CampaignRewardList.types'
import { EmblaCarousel } from '@/components/EmblaCarousel'
import { Section } from '@/components/Section'
import { SectionBar } from '@/components/SectionBar'
import campaignApi from '@/pages/campaign/api'
import walletApi from '@/pages/wallet/api'
import { RewardChart } from '../RewardChart'
import CampaignRewardListSkeleton from './CampaignRewardListSkeleton'
import RewardTopUpCharts from './RewardTopUpCharts'

const CampaignRewardList: React.FC<CampaignRewardListProps> = props => {
  const { walletId, companyId, color, currency } = props
  const { t } = useTranslation()

  const lastEarnedDepositGiftPointsQuery = walletApi.useWalletLastEarnedDepositGiftPointsQuery(
    { wallet_id: walletId },
    { refetchOnMountOrArgChange: true },
  )

  const lastEarnedDepositGiftPointsData = React.useMemo(() => {
    return lastEarnedDepositGiftPointsQuery.data?.data
  }, [lastEarnedDepositGiftPointsQuery.data])

  const pointWonCampaignQuery = walletApi.useWalletPointWonCampaignQuery(
    { wallet_id: walletId },
    { refetchOnMountOrArgChange: true },
  )

  const pointWonCampaignData = React.useMemo(() => {
    return pointWonCampaignQuery.data?.data ?? []
  }, [pointWonCampaignQuery.data?.data])

  const campaignNewsListQuery = campaignApi.useCampaignNewsListQuery(
    { page: 1, company_id: companyId, campaign_types: undefined },
    { refetchOnMountOrArgChange: true },
  )

  const campaignNewsListData = React.useMemo(() => {
    return campaignNewsListQuery.data?.data ?? []
  }, [campaignNewsListQuery.data?.data])

  if (lastEarnedDepositGiftPointsQuery.isLoading || pointWonCampaignQuery.isLoading || campaignNewsListQuery.isLoading)
    return <CampaignRewardListSkeleton />

  if (!lastEarnedDepositGiftPointsData && !pointWonCampaignData.length && !campaignNewsListData.length) return null

  return (
    <React.Fragment>
      <Section className="!py-2">
        <SectionBar
          title={t('@campaign.CampaignRewardList.campaign_section_title')}
          description={t('@campaign.CampaignRewardList.campaign_section_description')}
        />
      </Section>
      <EmblaCarousel slideSize={100}>
        {filter(
          [
            ...map(pointWonCampaignData, campaign => (
              <RewardChart.Progress
                key={campaign.id.toString()}
                name={campaign.name}
                processCount={campaign.process_count}
                remainderCount={campaign.user_process_remainder}
                color={color}
              />
            )),
            lastEarnedDepositGiftPointsData && (
              <RewardTopUpCharts
                key="rewardTopUpCharts"
                data={lastEarnedDepositGiftPointsData}
                color={color}
                currency={currency}
              />
            ),
          ],
          Boolean,
        )}
      </EmblaCarousel>
    </React.Fragment>
  )
}

export default CampaignRewardList
