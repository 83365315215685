import React from 'react'
import { useTranslation } from 'react-i18next'
import ThemeConfig from '~/tailwind.config'
import { InfoSheet } from '@/components/InfoSheet'
import { Section } from '@/components/Section'

const SessionTimeout: React.FC = () => {
  const { t } = useTranslation()
  const { colors } = ThemeConfig.theme.extend

  return (
    <React.Fragment>
      <Section className="!px-4 !py-0">
        <InfoSheet
          className="flex h-screen flex-col items-center justify-center"
          avatarIcon="warning"
          avatarProps={{
            backgroundColor: colors.semantic.content.error,
            iconProps: { color: colors.semantic.content.inkInverse },
          }}
          title={t('@cammon.SessionTimeout.content_title')}
          description={t('@cammon.SessionTimeout.content_description')}
        />
      </Section>
    </React.Fragment>
  )
}

export default SessionTimeout
