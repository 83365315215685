import type { RouteObject } from 'react-router-dom'
import {
  AccountActivitiesFilterPage,
  AccountActivitiesPage,
  AccountActivityDetailPage,
  CouponApplyPage,
  WalletAgreement,
  WalletDetailPage,
} from '@/pages/wallet/views'

export const WalletRouter: RouteObject[] = [
  {
    path: '/',
    element: <WalletDetailPage />,
  },
  {
    path: '/coupon',
    element: <CouponApplyPage />,
  },
  {
    path: '/account-activities',
    element: <AccountActivitiesPage />,
  },
  {
    path: '/account-activities/:id',
    element: <AccountActivityDetailPage />,
  },
  {
    path: '/account-activities-filter',
    element: <AccountActivitiesFilterPage />,
  },
  {
    path: '/wallet-agreement',
    element: <WalletAgreement />,
  },
]
