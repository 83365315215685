import React from 'react'
import { motion } from 'framer-motion'
import type { ModalProps } from './Modal.types'
import styles from './Modal.module.css'
import { useToast } from '@/hooks'
import { ModalRender, useContainerClassNames } from './Modal.utils'
import { Button } from '../Button'

const Modal: React.FC<ModalProps> = props => {
  const { variant, actions, children } = props
  const { modal, closeModal } = useToast()

  React.useEffect(() => {
    if (!modal) return undefined
    const root = document.querySelector('#root')
    const overlay = document.createElement('div')
    overlay.classList.add('overlay')
    root?.appendChild(overlay)
    root?.classList.add('overflow-hidden', 'h-[calc(100vh-var(--toolbar-height))]')
    const findScreenModal = document.querySelector('.screen-modal')
    if (variant === 'weblink') {
      findScreenModal?.classList.add('!bottom-0', '!px-0')
    }

    return () => {
      root?.removeChild(overlay)
      root?.classList.remove('overflow-hidden', 'h-[calc(100vh-var(--toolbar-height))]')
      findScreenModal?.classList.remove('!bottom-0', '!px-0')
    }
  }, [modal, variant])

  return (
    <motion.div
      initial={{ scale: 0.9, opacity: 0 }}
      animate={{ scale: 1, opacity: 1, transition: { duration: 0.2, ease: 'easeInOut' } }}
      exit={{ scale: 0.9, opacity: 0, transition: { duration: 0.15, ease: 'easeIn', forwards: true } }}
      className={useContainerClassNames(props)}>
      {variant === 'weblink' && (
        <React.Fragment>
          <div className={styles.WebLinkHeader} />
          <div className={styles.WebLinkContent}>{children}</div>
        </React.Fragment>
      )}
      {variant !== 'weblink' && <ModalRender {...props} />}
      <div className={styles.ModalFooter}>
        {actions?.map((buttonProps, index) => (
          <Button
            key={index}
            {...buttonProps}
            onClick={e => {
              buttonProps.onClick && buttonProps.onClick?.(e)
              closeModal()
            }}
          />
        ))}
      </div>
    </motion.div>
  )
}

export default Modal
