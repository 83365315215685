import * as React from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Formik, type FormikProps } from 'formik'
import { CurrencyFormatter } from '@macellan/formatter'
import type { GoldFramerPaymentFormProps, PaymentFormProps } from './PaymentFrom.types'
import { useCompletePurchase } from '../TopUp/TopUp.hooks'
import { paymentFormValidations } from './PaymentForm.validations'
import { Button } from '@/components/Button'
import { Section } from '@/components/Section'
import { TextField } from '@/components/TextField'
import { Toast } from '@/components/Toast'
import { ToolBar } from '@/components/ToolBar'
import { MaskUtils } from '@/utils'
import i18n from '@/translations/Translator.ts'
import { goldFramerApi } from '@/pages/deposit/api'

const GoldFramerPaymentForm: React.FC<GoldFramerPaymentFormProps> = ({ walletId, amount, currency, companyId }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const handleCompletePurchase = useCompletePurchase()

  const formikRef = React.useRef<FormikProps<PaymentFormProps>>(null)

  const [bin, setBin] = React.useState<string | null>(null)

  const [preCheckApply, preCheckResult] = goldFramerApi.usePreCheckMutation()
  const [payApply, payResult] = goldFramerApi.usePayMutation()

  const formattedAmount = React.useMemo(() => {
    if (!currency) return null
    return CurrencyFormatter.format(amount, i18n.getLocale(), currency)
  }, [amount, currency])

  const handleSubmit = React.useCallback(
    async (values: PaymentFormProps) => {
      const CardNo = MaskUtils.getRawValue('credit-card', values.creditCardNo)

      const expireMonth = values.expireDate.slice(0, 2)
      const expireYear = '20' + values.expireDate.slice(3, 5)

      const result = await payApply({
        amount: amount,
        wallet_id: walletId,
        card: {
          no: CardNo,
          expiry_month: expireMonth,
          expiry_year: expireYear,
          cvc: values.cvcNo,
        },
      }).unwrap()

      if (result.is_3d)
        return navigate('/payment-3d', {
          state: {
            threeDUrl: result.iframe_url,
          },
        })

      handleCompletePurchase(true)
    },
    [amount, handleCompletePurchase, navigate, payApply, walletId],
  )

  React.useEffect(() => {
    if (!bin) return

    preCheckApply({
      amount: amount,
      card: { no: bin },
    })
  }, [bin, amount, preCheckApply])

  if (companyId != 222) return null

  return (
    <React.Fragment>
      <Formik
        innerRef={formikRef}
        initialValues={{
          amount: amount,
          creditCardNo: '',
          expireDate: '',
          cvcNo: '',
        }}
        onSubmit={handleSubmit}
        validationSchema={paymentFormValidations}>
        {formik => (
          <Section className="!px-0 py-6">
            <div className="flex flex-col gap-4">
              <TextField
                label={t('@deposit.TopUpAnotherCard.form_label_credit_card_no')}
                error={formik.touched.creditCardNo && formik.errors.creditCardNo ? 'error' : undefined}
                message={formik.touched.creditCardNo ? formik.errors.creditCardNo : undefined}
                maskType="credit-card"
                placeholder="•••• •••• •••• ••••"
                inputMode="numeric"
                pattern="[0-9]*"
                value={formik.values.creditCardNo}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  const value = e.target.value.replace(/\D/g, '')

                  formik.handleChange('creditCardNo')(e)

                  if (value.length > 6) return setBin(value.slice(0, 6))
                }}
                autoComplete="cc-number"
              />
              <TextField
                label={t('@deposit.TopUpAnotherCard.form_label_expire_date')}
                error={formik.touched.expireDate && formik.errors.expireDate ? 'error' : undefined}
                message={formik.touched.expireDate ? formik.errors.expireDate : undefined}
                placeholder={t('@deposit.MasterPassCardRegister.form_placeholder_expiry_date')}
                maskType="date-time"
                maskOptions={{
                  dateTimePattern: 'MM/YY',
                }}
                inputMode="numeric"
                pattern="[0-9]*"
                value={formik.values.expireDate}
                onBlur={formik.handleBlur('expireDate')}
                onChange={formik.handleChange('expireDate')}
                autoComplete="cc-exp"
              />
              <TextField
                label={t('@deposit.TopUpAnotherCard.form_label_cvc_no')}
                error={formik.touched.cvcNo && formik.errors.cvcNo ? 'error' : undefined}
                message={formik.touched.cvcNo ? formik.errors.cvcNo : undefined}
                type="text"
                autoCapitalize="words"
                placeholder={t('@deposit.TopUpAnotherCard.form_label_cvc_no')}
                value={formik.values.cvcNo}
                onBlur={formik.handleBlur('cvcNo')}
                onChange={formik.handleChange('cvcNo')}
                autoComplete="cc-csc"
              />
            </div>

            <ToolBar className="left-0">
              <ToolBar.Left>
                <p>{t('@deposit.TopUp.footer_balance_title')}</p>
                <span>{formattedAmount}</span>
              </ToolBar.Left>
              <ToolBar.Right>
                <Button
                  variant="primary"
                  size="large"
                  radiusType="rounded"
                  disabled={!formik.isValid}
                  loading={payResult.isLoading}
                  onClick={() => formik.handleSubmit()}>
                  {t('@deposit.TopUp.footer_submit_button')}
                </Button>
              </ToolBar.Right>
            </ToolBar>
          </Section>
        )}
      </Formik>
      {preCheckResult.data?.will_convert && (
        <Toast
          variant="suggestion"
          icon="info"
          message={t('@deposit.GoldFramer.warning_message', {
            amount: preCheckResult.data.amount,
          })}
        />
      )}
    </React.Fragment>
  )
}

export default GoldFramerPaymentForm
