import type { MasterPassHelpQuestion } from './MasterPassHelp.types'
import i18n from '@/translations'

export const MasterPassHelpQuestions: MasterPassHelpQuestion[] = [
  {
    title: i18n.t('@deposit.MasterPassHelp.question_card_title'),
    message: i18n.t('@deposit.MasterPassHelp.question_card_description'),
  },
  {
    title: i18n.t('@deposit.MasterPassHelp.question_time_title'),
    message: i18n.t('@deposit.MasterPassHelp.question_time_description'),
  },
  {
    title: i18n.t('@deposit.MasterPassHelp.question_number_title'),
    message: i18n.t('@deposit.MasterPassHelp.question_number_description'),
  },
  {
    title: i18n.t('@deposit.MasterPassHelp.question_do_what_title'),
    message: i18n.t('@deposit.MasterPassHelp.question_do_what_description'),
  },
]

export const MasterPassHelpUrl = 'https://www.masterpassturkiye.com/login'
