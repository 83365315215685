import type { RewardChartStaticProperties } from './RewardChart.types'
import RewardChartProgress from './RewardChartProgress'
import RewardChartTable from './RewardChartTable'

const RewardChart: RewardChartStaticProperties = () => {
  return null
}

RewardChart.Progress = RewardChartProgress
RewardChart.Table = RewardChartTable

export default RewardChart
