import type { TimerStaticProperties } from './Timer.types'
import TimerCircle from './TimerCircle'

const Timer: TimerStaticProperties = () => {
  return null
}

Timer.Circle = TimerCircle

export default Timer
