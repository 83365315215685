import React from 'react'

// eslint-disable-next-line no-unused-vars
export const useToolBarHeight = (): [(node: HTMLDivElement | null) => void] => {
  const [height, setHeight] = React.useState<number | null>(null)
  const ref = React.useCallback((node: HTMLDivElement | null) => {
    if (node === null) return
    setHeight(node.getBoundingClientRect().height)
  }, [])

  React.useEffect(() => {
    if (height === null) return
    document.documentElement.style.setProperty('--toolbar-height', `${height}px`)
    return () => {
      document.documentElement.style.removeProperty('--toolbar-height')
    }
  }, [height])

  return [ref]
}
