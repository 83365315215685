import React from 'react'
import { useLocation } from 'react-router-dom'
import { filter, map } from 'lodash'
import type { FilterDateItem, FilterItem } from '../AccountActivitiesFilter/AccountActivitiesFilter.types'
import { convertArrayToObject } from './AccountActivities.utils'
import { AccountActivityList } from '../../components/AccountActivityList'
import AccountActivitiesHeader from './AccountActivitiesHeader'

const AccountActivitiesPage: React.FC = () => {
  const location = useLocation()
  const route = location.state as { walletId: number; filters: (FilterItem | FilterDateItem)[] }
  const [activeFilters, setActiveFilters] = React.useState<(FilterItem | FilterDateItem)[]>([])

  const config = React.useMemo(() => {
    const convertedFilter = convertArrayToObject(activeFilters)

    const balanceFilters = filter(
      activeFilters,
      filterItem => filterItem.type !== 'started_date' && filterItem.type !== 'end_date',
    ) as FilterItem[]

    const balanceFiltersTypes = map(balanceFilters, item => item.type)

    return {
      wallet_id: route.walletId,
      start_date: convertedFilter.started_date?.date ?? undefined,
      end_date: convertedFilter.end_date?.date ?? undefined,
      filters: balanceFiltersTypes ? { ...balanceFiltersTypes } : undefined,
    }
  }, [activeFilters, route.walletId])

  React.useEffect(() => {
    setActiveFilters(route?.filters ?? [])
  }, [route?.filters])

  return (
    <React.Fragment>
      <AccountActivitiesHeader
        walletId={route.walletId}
        activeFilters={activeFilters}
        startedDate={location.state?.startedDate}
        endDate={location.state?.endDate}
      />
      <AccountActivityList.SectionList config={config} />
    </React.Fragment>
  )
}

export default AccountActivitiesPage
