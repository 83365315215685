import React from 'react'
import { Container } from '@/components/Container'
import CampaignRewardListSkeleton from '@/pages/campaign/components/CampaignRewardList/CampaignRewardListSkeleton'
import AccountActivityListSkeleton from '../AccountActivityList/AccountActivityListSkeleton'

const WalletDetailSkeleton: React.FC = () => {
  return (
    <Container>
      <div className="animate-pulse bg-semantic-background-tertiary">
        <header className="flex items-center justify-end gap-4 border-b border-semantic-background-secondary p-4">
          <div className="h-6 w-[175px] rounded-lg bg-semantic-background-secondary" />
          <div className="h-8 w-8 rounded-full bg-semantic-background-secondary" />
        </header>
        <div className="p-6">
          <div className="flex flex-col gap-2">
            <div className="flex flex-col gap-3">
              <div className="h-3 w-10 rounded-lg bg-semantic-background-secondary" />
              <div className="h-[56px] w-28 rounded-lg bg-semantic-background-secondary" />
            </div>
            <div className="h-6 w-[70px] rounded-full bg-semantic-background-secondary" />
          </div>
          <div className="flex gap-1 pt-8">
            {[...Array(3)].map((_, index) => (
              <div key={index} className="h-[106px] w-[110px] rounded-xl bg-semantic-background-secondary" />
            ))}
          </div>
        </div>
      </div>
      <CampaignRewardListSkeleton />
      <AccountActivityListSkeleton className="px-6" />
    </Container>
  )
}

export default WalletDetailSkeleton
