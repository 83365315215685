import type { RowItemProps } from './Row.types'
import styles from './Row.module.css'
import { Icon } from '../Icons'

const RowItem: React.FC<RowItemProps> = props => {
  const { title, icon, ...rest } = props
  return (
    <button className={styles.RowItemContainer} {...rest}>
      {icon && (
        <div className={styles.RowItemIcon}>
          <Icon name={icon} size={24} />
        </div>
      )}
      <div className={styles.RowItemContent}>
        <h5 className={styles.RowItemTitle}>{title}</h5>
      </div>
    </button>
  )
}

export default RowItem
