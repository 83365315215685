/* eslint-disable */
import EventEmitter from 'eventemitter3'
import type { ModalProps } from '@/components/Modal/Modal.types'
import { ToastVariant, ToastOptionsProps, ModalOptionsProps } from './ToastContext.types'

class ToastEmitter extends EventEmitter {
  private addToast(variant: ToastVariant, options: ToastOptionsProps) {
    this.emit('add', { variant, ...options })
  }

  private addModal(options: ModalProps) {
    this.emit('modal', { ...options })
  }

  modal(options: ModalOptionsProps) {
    this.addModal({ ...options })
  }

  default(options: ToastOptionsProps) {
    this.addToast(ToastVariant.Default, { ...options })
  }

  suggestion(options: ToastOptionsProps) {
    this.addToast(ToastVariant.Suggestion, { ...options })
  }

  success(options: ToastOptionsProps) {
    this.addToast(ToastVariant.Success, { ...options })
  }

  error(options: ToastOptionsProps) {
    this.addToast(ToastVariant.Error, { ...options })
  }
}

const toast = new ToastEmitter()
export default toast
