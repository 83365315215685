import { useTranslation } from 'react-i18next'
import type { ConditionParticipationProps } from './CampaignDetail.types'
import { Container } from '@/components/Container'
import { ReadMore } from '@/components/ReadMore'
import { Section } from '@/components/Section'

const ConditionParticipation: React.FC<ConditionParticipationProps> = props => {
  const { htmlDescription } = props
  const { t } = useTranslation()

  return (
    <Container>
      <Section className="flex flex-col gap-3 pt-12">
        <h2 className="text-display-sm text-semantic-content-ink">
          {t('@campaign.CampaignDetail.condition_participation_title')}
        </h2>
        <ReadMore
          content={htmlDescription}
          buttonText={{
            more: t('@campaign.CampaignDetail.condition_participation_button_more_title'),
            hide: t('@campaign.CampaignDetail.condition_participation_button_hide_title'),
          }}
        />
      </Section>
    </Container>
  )
}

export default ConditionParticipation
