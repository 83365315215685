import React from 'react'

const useLocalStorageListener = (walletId: number, handleLastPaymentMethod: () => void): void => {
  React.useEffect(() => {
    const handleStorageChange = (e: StorageEvent) => {
      if (e.key === 'deposit_methods.' + walletId) {
        handleLastPaymentMethod()
      }
    }
    window.addEventListener('storage', handleStorageChange)

    return () => {
      window.removeEventListener('storage', handleStorageChange)
    }
  }, [walletId, handleLastPaymentMethod])
}

export default useLocalStorageListener
