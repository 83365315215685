import React from 'react'
import cn from 'classnames'
import type { BadgeProps } from './Badge.types'
import styles from './Badge.module.css'
import { BadgeSize, BadgeType, getBadgetIconComponents, IconColor, IconSize } from './Badge.utils'
import { Container } from '../Container'
import { Spinner } from '../Spinner'

const Badge: React.FC<BadgeProps> = props => {
  const { variant = 'yellow', children, size, loading, icon, className, ...rest } = props

  const { leftIconComponent } = getBadgetIconComponents({
    icon,
    iconSize: IconSize[size],
    iconColor: IconColor[variant],
  })

  const classNames = cn(styles.Badge, BadgeType[variant], BadgeSize[size], className)

  return (
    <Container>
      <button className={classNames} disabled={loading} {...rest}>
        {!loading && leftIconComponent}
        {!loading && children}
        {loading && <Spinner size={IconSize[size]} color={IconColor[variant]} />}
      </button>
    </Container>
  )
}

export default Badge
