import React from 'react'
import { map } from 'lodash'
import type { DetailItemProps } from './AccountActivityDetail.types'
import styles from './AccountActivityDetail.module.css'

const DetailItem: React.FC<DetailItemProps> = props => {
  const { title, sections } = props
  if (!title) return null

  return (
    <div className={styles.DetailItemContainer}>
      <div className={styles.DetailItemHeader}>
        <h3 className={styles.DetailItemTitle}>{title.toLocaleUpperCase()}</h3>
      </div>
      <div className={styles.DetailItemSectionContainer}>
        {map(sections, (item, index) => {
          if (!item) return
          return (
            <div className={styles.DetailItemSection} key={item.title + index}>
              <h5>{item.title}</h5>
              <p>{item.value}</p>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default DetailItem
