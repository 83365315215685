import { find, includes, keys, pickBy } from 'lodash'
import type { CardType, PaymentMethods, UserCard } from '../../api/DepositApi.types'
import { PaymentMethodConstants } from '../../constants'
import store from '@/store'
import depositApi from '../../api/DepositApi'

export default class PaymentMethodsService {
  private static get dispatch() {
    return store.dispatch
  }

  public static getTitle = (type: CardType) => {
    return PaymentMethodConstants.Titles[type]
  }

  public static getLogo = (type: CardType) => {
    return PaymentMethodConstants.Logos[type]
  }

  public static getSelectedCard = (cardList: UserCard[], type: CardType) => {
    return find(cardList, item => item.type === type)
  }

  public static setLastPaymentMethod = (walletId: number, type: CardType) => {
    localStorage.setItem('deposit_methods.' + walletId, type)
  }

  public static getAvailablePaymentMethods = (paymentMethods: PaymentMethods) => {
    return keys(pickBy(paymentMethods, method => method))
  }

  public static getLastPaymentMethod = async (walletId: number, paymentMethods: PaymentMethods) => {
    const storageMethod = localStorage.getItem('deposit_methods.' + walletId) as keyof PaymentMethods

    const activePaymentMethods = PaymentMethodsService.getAvailablePaymentMethods(paymentMethods)

    if (!activePaymentMethods.length) return null

    const cardListResult = await this.dispatch(depositApi.endpoints.userCardList.initiate(undefined)).unwrap()

    const selectedCard = PaymentMethodsService.getSelectedCard(cardListResult.data, storageMethod)

    const isSupportted = includes(activePaymentMethods, storageMethod)

    if (isSupportted && selectedCard) return storageMethod

    for (const method of activePaymentMethods) {
      const selected = await PaymentMethodsService.getSelectedCard(cardListResult.data, method as CardType)

      if (selected) return method
    }

    return activePaymentMethods[0]
  }
}
