import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Trans, useTranslation } from 'react-i18next'
import { useFormik } from 'formik'
import { MasterPassSDK } from '@macellan/masterpass-sdk/src'
import { mpRegisterValidationSchema } from './MasterPassCardRegister.validations'
import toast from '@/context'
import { useAppSelector } from '@/store'
import { Button } from '@/components/Button'
import { CheckBox } from '@/components/CheckBox'
import { ContentHeader } from '@/components/ContentHeader'
import { Header } from '@/components/Header'
import { Section } from '@/components/Section'
import { TextField } from '@/components/TextField'
import { MaskUtils } from '@/utils'
import { MasterPassService, type MPRegisterData } from '../../services'

import masterpassLogo from '@/assets/images/MasterPassLogo.jpeg'

const MasterPassCardRegister: React.FC = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { mpRegisterLoading } = useAppSelector(state => state.depositSlice)

  const handleSubmit = React.useCallback(
    async (values: MPRegisterData) => {
      const rtaPanRawValue = MaskUtils.getRawValue('credit-card', values.rtaPan)

      const expiryDateRawValue = MaskUtils.getRawValue('date-time', values.expiryDate, 'MM/YY')

      const expiryYear = expiryDateRawValue.getFullYear().toString().slice(2, 4)

      const expiryMonth = ('0' + (expiryDateRawValue.getMonth() + 1).toString()).slice(-2)

      const expiryDate = expiryYear + expiryMonth

      await MasterPassService.register({
        rtaPan: rtaPanRawValue,
        expiryDate: expiryDate,
        accountAliasName: values.accountAliasName,
      })
        .then(result => {
          if (result.responseCode === MasterPassSDK.Constants.ResponseCodes.VALIDATE_OTP) {
            navigate('/masterpass-otp', {
              state: {
                otpType: 'register',
                selectedLoading: true,
              },
            })
          }
        })
        .catch(error => {
          toast.error({ message: error.data.message, icon: 'warning' })
        })
    },
    [navigate],
  )

  const formik = useFormik({
    initialValues: {
      rtaPan: '',
      expiryDate: '',
      accountAliasName: '',
      agreement: false,
    },
    validationSchema: mpRegisterValidationSchema,
    onSubmit: values => handleSubmit(values),
  })

  return (
    <React.Fragment>
      <Header
        title={t('@deposit.MasterPassCardRegister.route_header_title')}
        variant="secondary"
        goBack={() => navigate(-1)}
      />
      <Section className="!p-6">
        <ContentHeader
          title={t('@deposit.MasterPassCardRegister.content_header_title')}
          description={t('@deposit.MasterPassCardRegister.content_header_description')}
        />
        <form onSubmit={formik.handleSubmit} className="flex flex-col gap-4 pt-9">
          <TextField
            label={t('@deposit.MasterPassCardRegister.form_label_card_number')}
            error={formik.touched.rtaPan && formik.errors.rtaPan ? 'error' : undefined}
            message={formik.touched.rtaPan ? formik.errors.rtaPan : undefined}
            maskType="credit-card"
            placeholder="•••• •••• •••• ••••"
            inputMode="numeric"
            pattern="[0-9]*"
            value={formik.values.rtaPan}
            onChange={formik.handleChange('rtaPan')}
          />
          <TextField
            label={t('@deposit.MasterPassCardRegister.form_label_expiry_date')}
            error={formik.touched.expiryDate && formik.errors.expiryDate ? 'error' : undefined}
            message={formik.touched.expiryDate ? formik.errors.expiryDate : undefined}
            placeholder={t('@deposit.MasterPassCardRegister.form_placeholder_expiry_date')}
            maskType="date-time"
            maskOptions={{
              dateTimePattern: 'MM/YY',
            }}
            inputMode="numeric"
            pattern="[0-9]*"
            value={formik.values.expiryDate}
            onBlur={formik.handleBlur('expiryDate')}
            onChange={formik.handleChange('expiryDate')}
          />
          <TextField
            label={t('@deposit.MasterPassCardRegister.form_label_account_alias_name')}
            error={formik.touched.accountAliasName && formik.errors.accountAliasName ? 'error' : undefined}
            message={formik.touched.accountAliasName ? formik.errors.accountAliasName : undefined}
            type="text"
            autoCapitalize="words"
            placeholder={t('@deposit.MasterPassCardRegister.form_placeholder_account_alias_name')}
            value={formik.values.accountAliasName}
            onBlur={formik.handleBlur('accountAliasName')}
            onChange={formik.handleChange('accountAliasName')}
          />
          <div className="mt-4 flex flex-col gap-6">
            <CheckBox onChange={formik.handleChange('agreement')} checked={formik.values.agreement}>
              <Trans
                i18nKey={'@deposit.MasterPassCardRegister.form_agreement_checkbox_title'}
                components={[
                  <button
                    type="button"
                    className="text-link-sm text-semantic-content-link underline"
                    onClick={() =>
                      toast.modal({
                        variant: 'weblink',
                        children: (
                          <iframe
                            src={t('@deposit.MasterPassCardRegister.mp_user_agreement_url')}
                            className="h-[calc(100vh-202px)] w-full"
                          />
                        ),
                        actions: [
                          {
                            children: t('@deposit.MasterPassCardRegister.mp_user_agreement_header_description'),
                            variant: 'primary',
                            size: 'large',
                            radiusType: 'rounded',
                            onClick: () => formik.setFieldValue('agreement', true),
                          },
                        ],
                      })
                    }
                  />,
                ]}
              />
            </CheckBox>
            <img src={masterpassLogo} className=" w-[104px] self-center object-cover" alt="MasterPass" />
            <Button
              type="submit"
              variant="primary"
              size="large"
              radiusType="rounded"
              disabled={!formik.isValid}
              loading={mpRegisterLoading}
              onClick={() => formik.handleSubmit()}>
              {t('@deposit.MasterPassCardRegister.form_submit_button')}
            </Button>
          </div>
        </form>
      </Section>
    </React.Fragment>
  )
}

export default MasterPassCardRegister
