import type { ModalProps } from '@/components/Modal/Modal.types'
import type { ToastProps } from '@/components/Toast'

export interface ToastsProps extends ToastProps {
  id: string
}

export interface ToastContextTypeProps {
  toasts: ToastsProps[]
  modal: ModalProps | null
  addToast: (type: string, message: string) => void
  addModal: (type: string, message: string) => void
  closeModal: () => void
}
export interface ToastProviderPropsProps {
  children: React.ReactNode
}

export enum ToastVariant {
  Default = 'default',
  Suggestion = 'suggestion',
  Success = 'success',
  Error = 'error',
}

export interface ToastOptionsProps extends Omit<ToastProps, 'variant'> {}

export interface ModalOptionsProps extends ModalProps {}
