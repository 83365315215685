import React from 'react'
import { useNavigate } from 'react-router-dom'
import { find } from 'lodash'
import { useTranslation } from 'react-i18next'
import type { DepositChipsProps } from './DepositChips.types'
import { renderDepositBadges } from './DepositChips.utils'
import { EmblaCarousel } from '@/components/EmblaCarousel'
import i18n from '@/translations'
import walletApi from '@/pages/wallet/api/WalletApi'

const DepositChips: React.FC<DepositChipsProps> = props => {
  const locale = i18n.getLocale()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { amount, onChangeValue, isVisibleFooterComponent = true, walletId } = props

  const walletDetailsQuery = walletApi.useWalletDetailsQuery({}, { refetchOnMountOrArgChange: true })

  const wallet = React.useMemo(() => {
    return walletDetailsQuery.data?.data
  }, [walletDetailsQuery.data?.data])

  const useOtherLabelActive = React.useMemo(() => {
    return !find(wallet?.company.settings.default_deposit_amounts, defaultAmount => defaultAmount.amount === amount)
  }, [amount, wallet?.company.settings.default_deposit_amounts])

  const handleNavigateToCustomAmount = React.useCallback(() => {
    navigate('/topup/custom-amount', { state: { defaultAmount: amount, walletId } })
  }, [amount, navigate, walletId])

  if (!wallet) return null

  return (
    <React.Fragment>
      <EmblaCarousel slideSize="auto">
        {renderDepositBadges({
          depositAmounts: wallet.company.settings.default_deposit_amounts,
          amount,
          locale,
          currency: wallet.company.currency,
          onChangeValue,
          otherLabel: t('@deposit.TopUp.deposit_chips_other_label'),
          isVisibleFooterComponent,
          handleNavigateToCustomAmount,
          useOtherLabelActive,
        })}
      </EmblaCarousel>
    </React.Fragment>
  )
}

export default DepositChips
