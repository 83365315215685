import * as React from 'react'
import type { RewardTopUpChartProps } from './CampaignRewardList.types'
import { RewardChart } from '../RewardChart'

const RewardTopUpCharts: React.FC<RewardTopUpChartProps> = ({ data, color, currency }) => {
  if (!data) return null

  return (
    <RewardChart.Table
      amount={data.total_of_last_earned_deposit_gift_point_amounts}
      amountList={data.last_earned_deposit_gift_point_amounts}
      color={color}
      currency={currency}
    />
  )
}

export default RewardTopUpCharts
