import type {
  LoginPhoneVerifyData,
  LoginPhoneVerifyResponse,
  LoginWithPhoneData,
  LoginWithPhoneResponse,
  UserMeResponse,
} from './UserApi.types'
import baseApi from '@/api'

const userApi = baseApi.injectEndpoints({
  endpoints: builder => ({
    loginSendSms: builder.mutation<LoginWithPhoneResponse, LoginWithPhoneData>({
      query: data => ({
        url: 'waas/login/send-sms',
        method: 'POST',
        body: data,
      }),
    }),
    loginPhoneVerify: builder.mutation<LoginPhoneVerifyResponse, LoginPhoneVerifyData>({
      query: data => ({
        url: 'waas/login/phone-verify',
        method: 'POST',
        body: data,
      }),
    }),
    me: builder.query<UserMeResponse, void>({
      query: () => ({
        url: 'v2/user/me',
        method: 'GET',
      }),
      providesTags: ['Me'],
    }),
  }),
  overrideExisting: true,
})

export default userApi
