import React from 'react'
import type { GetModalColorFunction, ModalProps } from './Modal.types'
import styles from './Modal.module.css'
import { useToast } from '@/hooks'
import ThemeConfig from '~/tailwind.config'
import { Container } from '../Container'
import { Icon } from '../Icons'

const { colors } = ThemeConfig.theme.extend

export const getToastIconColor: GetModalColorFunction = type => {
  switch (type) {
    case 'suggestion':
      return colors.semantic.content.info
    case 'success':
      return colors.semantic.content.success
    case 'error':
      return colors.semantic.content.error
    default:
      return colors.semantic.background.primary
  }
}

export const useContainerClassNames = (modal: ModalProps) => {
  const { variant } = modal

  const classNames = React.useMemo(() => {
    const result = [styles.ModalContainer]

    if (variant === 'weblink') result.push(styles.ModalContainerWeblink)

    return result.join(' ')
  }, [variant])

  return classNames
}

export const ModalRender = (props: ModalProps) => {
  const { variant, title, description, icon = 'check-circle' } = props
  const { closeModal } = useToast()

  if (variant === 'weblink') return null

  return (
    <Container>
      <div className={styles.ModalHeader}>
        <button onClick={closeModal}>
          <Icon name="close" size={16} />
        </button>
      </div>
      <div className={styles.ModalContent}>
        <Icon name={icon} size={128} color={getToastIconColor(variant)} />
        <h6>{title}</h6>
        <p>{description}</p>
      </div>
    </Container>
  )
}
