export default {
  CampaignForYou: {
    section_bar_title: 'Sana Özel',
    section_bar_description: 'Sana özel tüm kampanyalara göz at',
  },
  CampaignRewardList: {
    campaign_section_title: 'Kampanyalar',
    campaign_section_description: 'Fırsatları takip et, bütçene katkı sağla',
  },
  CampaignSegmentedList: {
    segmented_section_topup_title: 'Yükle Kazan',
    segmented_section_capture_title: 'Harca Kazan',
  },
  Campaigns: {
    route_header_title: 'Kampanyalar',
    route_header_description: 'Üye iş yeri kampanyalarını takip et, fırsatları kaçırma!',
    header_right_button_title: 'Cüzdan Seç',
    campaigns_chips_label_all: 'Tümü',
    campaigns_chips_label_deposit: 'Yükle Kazan',
    campaigns_chips_label_payment: 'Harca Kazan',
    campaigns_ending_soon_title: 'Süresi Bitecek Olanlar',
    campaigns_ending_soon_description: 'Süresi bitmeden kampanyalardan yararlan',
    empty_sheet_title: 'Kampanya Bulunamadı',
    empty_sheet_description: 'Aradığın kriterlere göre kampanya bulunamadı',
  },
  CampaignDetail: {
    route_header_title: 'Kampanya Detayları',
    content_header_left_title: 'SON TARİH',
    content_header_right_title: 'HEDİYE PUAN',
    footer_deposit_button: 'Bakiye Yükle',
    footer_deposit_description: 'Fırsattan faydalanmak için bakiye yükle!',
    condition_participation_title: 'Katılım Koşulları',
    condition_participation_button_more_title: 'Devamını Oku',
    condition_participation_button_hide_title: 'Gizle',
    other_campaigns_title: 'İlgini Çekebilir',
    other_campaigns_description: 'Markaya ait diğer kampanyalar',
    payment_count_gift_toast_message:
      '{{formattedEarnablePoint}} hediye puan kazanmana {{remainderCount}} harcama kaldı',
    deposit_gift_toast_message: 'Bu kampanyadan {{usageCount}} kez faydalandın',
    deposit_gift_disposable_toast_message: 'Bu kampanyadan zaten faydalandın',
    toast_message_chip_title: 'Kazanılan: {{formattedWonPoint}}',
  },
  CampaignItemTiny: {
    button_text: 'Detay',
  },
}
