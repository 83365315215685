import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import styles from './PaymentRefundEftPos.module.css'
import { splitOrderId } from './PaymentRefundEftPos.utils'
import ThemeConfig from '~/tailwind.config'
import { Header } from '@/components/Header'
import { InfoSheet } from '@/components/InfoSheet'

const PaymentRefundEftPos: React.FC = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { colors } = ThemeConfig.theme.extend

  return (
    <React.Fragment>
      <Header
        variant="secondary"
        title={t('@payment.PaymentRefundEftPos.route_header_title')}
        goBack={() => navigate(-1)}
      />
      <div className={styles.PaymentRefundEftPosOrderContainer}>
        <h6>{t('@payment.PaymentRefundEftPos.order_title')}</h6>
        <p>{splitOrderId(location.state?.orderId)}</p>
      </div>
      <InfoSheet
        className="h-[calc(100vh-161px)] justify-center px-6 pb-4"
        avatarIcon="warning"
        avatarProps={{
          backgroundColor: 'transparent',
          iconProps: {
            color: colors.semantic.content.inkLight,
            size: 128,
          },
        }}
        title={t('@payment.PaymentRefundEftPos.title')}
        titleStyle={{ color: colors.semantic.content.inkLight }}
        description={t('@payment.PaymentRefundEftPos.description')}
        descriptionStyle={{ color: colors.semantic.content.inkLight }}
      />
    </React.Fragment>
  )
}

export default PaymentRefundEftPos
