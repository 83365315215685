import React from 'react'
import type { IIconProps } from './Icon.types'

import iconsConfig from '@/assets/icons/macellan-icons-config.json'

const Icon: React.FC<IIconProps> = props => {
  const { name, size = '1em', color } = props
  const icon = iconsConfig.glyphs.find(glyph => glyph.css === name)

  if (!icon) return null

  return (
    <svg width={size} height={size} fill={color} viewBox="0 0 1000 1000">
      <path d={icon.svg.path} />
    </svg>
  )
}

export default Icon
