import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useLocalStorageListener } from '@/hooks'
import { ContentHeader } from '@/components/ContentHeader'
import { Header } from '@/components/Header'
import { Section } from '@/components/Section'
import walletApi from '@/pages/wallet/api'
import type { CardType } from '../../api'
import MasterPassSection from '../../components/MasterPassSection/MasterPassSection'
import { PaymentMethodsService } from '../../services'

const DepositCards: React.FC = () => {
  const { t } = useTranslation()
  const location = useLocation()
  const navigate = useNavigate()

  const walletId = React.useMemo(() => {
    return location.state?.walletId
  }, [location.state?.walletId])

  const [selectedPaymentMethod, setSelectedPaymentMethod] = React.useState<CardType>()

  const walletDetailsQuery = walletApi.useWalletDetailsQuery({ wallet_id: walletId }, { skip: !walletId })

  const isUnlinkButtonHidden = React.useMemo(() => {
    return location.state?.actionType === 'top-up'
  }, [location.state?.actionType])

  const checkVisibleState = React.useCallback(
    (type: CardType) => {
      return walletDetailsQuery.data?.data.company.deposit_methods[type]
    },
    [walletDetailsQuery],
  )

  const checkSelectedState = React.useCallback(
    (type: CardType) => {
      return walletId ? selectedPaymentMethod === type : undefined
    },
    [selectedPaymentMethod, walletId],
  )

  const setPaymentMethod = React.useCallback(
    async (type: CardType) => {
      if (!walletId) return

      await PaymentMethodsService.setLastPaymentMethod(walletId, type)
      navigate(-1)
    },
    [navigate, walletId],
  )

  const handleLastPaymentMethod = React.useCallback(async () => {
    if (!walletId) return undefined

    if (!walletDetailsQuery.data?.data.company.deposit_methods) return

    const lastPaymentMethod = (await PaymentMethodsService.getLastPaymentMethod(
      walletId,
      walletDetailsQuery.data?.data.company.deposit_methods,
    )) as CardType

    if (!lastPaymentMethod) return

    setSelectedPaymentMethod(lastPaymentMethod)
  }, [walletDetailsQuery.data?.data.company.deposit_methods, walletId])

  useLocalStorageListener(walletId, handleLastPaymentMethod)

  React.useEffect(() => {
    handleLastPaymentMethod()
  }, [handleLastPaymentMethod])

  return (
    <div>
      <Header
        variant="secondary"
        title={t('@deposit.DepositCards.route_header_title')}
        color="black"
        goBack={() => navigate('/topup')}
      />
      <Section className="!py-6">
        <ContentHeader description={t('@deposit.DepositCards.content_header_description')} />
      </Section>
      <MasterPassSection
        visible={checkVisibleState('masterpass')}
        selected={checkSelectedState('masterpass')}
        isUnlinkButtonHidden={isUnlinkButtonHidden}
        onClick={() => setPaymentMethod('masterpass')}
        walletId={walletId}
      />
    </div>
  )
}

export default DepositCards
