import React from 'react'
import { CurrencyFormatter } from '@macellan/formatter'
import type { RewardChartTableProps } from './RewardChart.types'
import styles from './RewardChart.module.css'
import { useContentConfig } from '@/hooks'
import i18n from '@/translations'
import RewardChartTableList from './RewardChartTableList'

const RewardChartTable: React.FC<RewardChartTableProps> = props => {
  const { amount, color, currency, amountList } = props
  const content = useContentConfig()
  const locale = i18n.getLocale()

  const formattedDetails = React.useMemo(() => {
    return CurrencyFormatter.formatToDetails(amount, locale, currency)
  }, [amount, locale, currency])

  return (
    <div className={styles.Container}>
      <div className={styles.LeftWrapper}>
        <h6 className={styles.Title}>{content.rewardChart.tableTypeTitle}</h6>
        <div className={styles.ContentWrapper}>
          <div className={styles.ContentTable}>
            <span className={styles.Symbol}>{formattedDetails.symbol}</span>
            <p className={styles.Digid}>{formattedDetails.digid}</p>
          </div>
          <p className={styles.Description}>{content.rewardChart.tableTypeDescription}</p>
        </div>
      </div>
      <div className={styles.RightWrapper}>
        <RewardChartTableList amountList={amountList} color={color} currency={currency} />
      </div>
    </div>
  )
}

export default RewardChartTable
