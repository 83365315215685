import React from 'react'
import cn from 'classnames'
import type { ToolBarItemsProps, ToolBarStaticProperties } from './ToolBar.types'
import styles from './ToolBar.module.css'
import { useToolBarHeight } from './ToolBar.utils'
import { Container } from '../Container'
import ToolBarLeft from './ToolBarLeft'
import ToolBarRight from './ToolBarRight'

const ToolBar: React.FC<ToolBarItemsProps> & ToolBarStaticProperties = props => {
  const { children, className } = props
  const [ref] = useToolBarHeight()

  return (
    <Container>
      <div ref={ref} className={cn(styles.ToolBar, className)}>
        {children}
      </div>
    </Container>
  )
}

ToolBar.Left = ToolBarLeft
ToolBar.Right = ToolBarRight

export default ToolBar
