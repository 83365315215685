import type { GetActionLabelColorFunction, GetActionLabelSizeFunction } from './ActionLabel.types'
import ThemeConfig from '~/tailwind.config'

const theme = ThemeConfig.theme.extend

export const getActionLabelColor: GetActionLabelColorFunction = type => {
  if (type === 'enabled') return theme.colors.semantic.content.inkMedium
  if (type === 'disabled') return theme.colors.semantic.content.disabled
  if (type === 'brand') return theme.colors.solid.brand[5]
  if (type === 'error') return theme.colors.semantic.content.error
  if (type === 'blue') return theme.colors.solid.blue[6]
  return theme.colors.semantic.content.ink
}

export const getActionLabelSize: GetActionLabelSizeFunction = (size, fontType) => {
  if (size === 'medium') return fontType === 'label' ? 'text-label-md' : 'text-body-md-b'

  if (size === 'large') return fontType === 'label' ? 'text-label-lg' : 'text-body-lg-b'

  return 'text-body-sm-b'
}
