import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import type { TopUpDepositCardProps } from './TopUpDepositCard.types'
import { useLocalStorageListener } from '@/hooks'
import { Button } from '@/components/Button'
import { DepositCard } from '@/components/DepositCard'
import type { CardType } from '@/pages/deposit/api/DepositApi.types'
import walletApi from '@/pages/wallet/api'
import { PaymentMethodsService } from '../../services'
import { MasterPassDepositCard } from '../MasterPassDepositCard'

const TopUpDepositCard: React.FC<TopUpDepositCardProps> = props => {
  const { walletId, selectedLoading } = props
  const navigate = useNavigate()
  const { t } = useTranslation()
  const [paymentMethodLoading, setPaymentMethodLoading] = React.useState<boolean>(true)
  const [paymentMethod, setPaymentMethod] = React.useState<CardType>()

  const walletDetailsQuery = walletApi.useWalletDetailsQuery({}, { refetchOnMountOrArgChange: true })

  const navigateToDepositCard = React.useCallback(() => {
    navigate('/masterpass-cards', {
      state: {
        actionType: 'top-up',
        walletId: walletId,
      },
    })
  }, [navigate, walletId])

  const handleLastPaymentMethod = React.useCallback(async () => {
    setPaymentMethodLoading(true)

    const companyPaymentMethods = walletDetailsQuery.data?.data.company.deposit_methods

    if (!companyPaymentMethods) return

    const result = await PaymentMethodsService.getLastPaymentMethod(walletId, companyPaymentMethods)

    if (result) setPaymentMethod(result as CardType)

    setPaymentMethodLoading(false)
  }, [walletDetailsQuery.data?.data.company.deposit_methods, walletId])

  useLocalStorageListener(walletId, handleLastPaymentMethod)

  React.useEffect(() => {
    handleLastPaymentMethod()
  }, [handleLastPaymentMethod])

  const DepositCardRightComponent = React.useMemo(() => {
    return (
      <Button variant="primary" size="tiny" radiusType="pill" onClick={navigateToDepositCard}>
        {t('@deposit.TopUpDepositCard.change_button')}
      </Button>
    )
  }, [navigateToDepositCard, t])

  if (paymentMethodLoading) {
    return <DepositCard.Loader />
  }

  if (!paymentMethod) return null

  if (paymentMethod === 'masterpass') {
    return (
      <MasterPassDepositCard
        LayoutRightComponent={DepositCardRightComponent}
        onClick={navigateToDepositCard}
        selectedLoading={selectedLoading}
        {...props}
      />
    )
  }
}

export default TopUpDepositCard
