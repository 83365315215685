/* eslint-disable  */
const mergeRefs = <T extends unknown>(
  refs: Array<React.MutableRefObject<T> | React.LegacyRef<T>>,
): React.RefCallback<T> => {
  return value => {
    refs.forEach(ref => {
      if (!ref) return
      if (typeof ref === 'function') return ref(value)
      ;(ref as React.MutableRefObject<T | null>).current = value
    })
  }
}

export default {
  mergeRefs: mergeRefs,
}
