import { useTranslation } from 'react-i18next'
import type { WalletCardLoyaltyProps } from './WalletCard.types'
import styles from './WalletCard.module.css'
import { useFormatCurrency } from './WalletCard.hooks'
import { Badge } from '../Badge'
import { Container } from '../Container'

const WalletCardLoyalty: React.FC<WalletCardLoyaltyProps> = props => {
  const { amount, color, currency, logo, name, point } = props
  const { t } = useTranslation()

  const { currencySymbol, formattedAmount, formattedPoint } = useFormatCurrency(amount, point, currency)

  return (
    <Container>
      <div className={styles.WalletCardLoyaltyContainer} style={{ backgroundColor: color }}>
        <div className={styles.WalletCardLoyaltyLeft}>
          <img src={logo} alt={name} />
          <div className={styles.WalletCardLoyaltyContent}>
            <h6>{name}</h6>
            <Badge variant="yellow" size="tiny">
              {`${t('content_config.WalletCard.placeholders_point')}: ${currencySymbol}${formattedPoint}`}
            </Badge>
          </div>
        </div>
        <div className={styles.WalletCardLoyaltyRight}>{`${currencySymbol}${formattedAmount}`}</div>
      </div>
    </Container>
  )
}

export default WalletCardLoyalty
