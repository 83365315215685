import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import type { AuthProviderProps } from './AuthContext.types'
import { useSocketListener } from '@/hooks'
import AuthContext from './AuthContext'

import { routes } from '@/routes'
import { SocketService } from '@/services'

const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const navigate = useNavigate()
  const location = useLocation()
  useSocketListener()
  const accessToken = localStorage.getItem('user.access_token')

  const AuthRoutesPath = React.useMemo(() => {
    if (!routes[1].children || !routes[1].children[1].children) return []
    return routes[1].children[1].children.map(route => route.path) as string[]
  }, [])

  React.useEffect(() => {
    const isAuthRoute = AuthRoutesPath.includes(location.pathname)

    if (accessToken && isAuthRoute) return navigate('/', { replace: true })
  }, [accessToken, navigate, location.pathname, AuthRoutesPath])

  React.useEffect(() => {
    if (!accessToken) return
    SocketService.connect()
  }, [accessToken])

  return (
    <AuthContext.Provider
      value={{
        isAuthenticating: !!accessToken,
      }}>
      {children}
    </AuthContext.Provider>
  )
}

export default AuthProvider
