import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import type { WalletCardPayingProps } from './WalletCard.types'
import styles from './WalletCard.module.css'
import { useFormatCurrency } from './WalletCard.hooks'
import { Badge } from '../Badge'
import { Container } from '../Container'
import { Header } from '../Header'
import { Icon } from '../Icons'

const WalletCardPaying: React.FC<WalletCardPayingProps> = props => {
  const { walletId, amount, color, currency, name, logo, point, redirected } = props
  const navigate = useNavigate()
  const { t } = useTranslation()

  const { currencySymbol, formattedAmount, formattedPoint } = useFormatCurrency(amount, point, currency)

  return (
    <React.Fragment>
      {!redirected && (
        <Header
          title={t('@deposit.TopUp.route_header_title')}
          variant="secondary"
          rightAction={() => (
            <Link to="/coupon" state={{ walletId: walletId }}>
              <Icon name="coupon" size={24} color="white" />
            </Link>
          )}
          color="white"
          bgColor={color}
          goBack={() => navigate('/', { replace: true })}
        />
      )}
      <Container duration={0.3}>
        <div
          className={styles.WalletCardPayingContainer}
          style={{
            backgroundColor: color,
          }}>
          <img src={logo} alt={name} />
          <div className={styles.WalletCardPayingContent}>
            <div className={styles.WalletCardPayingLeft}>
              <span>{name}</span>
              <Badge variant="yellow" size="tiny">
                {t('@deposit.EarnablePoint.point_label')}: {`${currencySymbol}${formattedPoint}`}
              </Badge>
            </div>
            <div className={styles.WalletCardPayingBalance}>{`${currencySymbol}${formattedAmount}`}</div>
          </div>
        </div>
      </Container>
    </React.Fragment>
  )
}

export default WalletCardPaying
