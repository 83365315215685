import * as React from 'react'
import type { RefundSectionInfoProps } from './AccountActivityDetail.types'
import styles from './AccountActivityDetail.module.css'

const RefundSectionInfo: React.FC<RefundSectionInfoProps> = props => {
  const { title, comment } = props
  return (
    <div className={styles.RefundContainer}>
      <h5 className={styles.RefundTitle}>{title}</h5>
      <p className={styles.RefundDescription}>{comment}</p>
    </div>
  )
}

export default RefundSectionInfo
