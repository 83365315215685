import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { debounce } from 'lodash'
import { useTranslation } from 'react-i18next'
import styles from './TopUpCustomAmount.module.css'
import { DefaultMaximumDepositAmount } from './TopUpCustomAmount.constants'
import { Button } from '@/components/Button'
import { ContentHeader } from '@/components/ContentHeader'
import { Header } from '@/components/Header'
import { Section } from '@/components/Section'
import { DepositChips } from '@/pages/deposit/components/DepositChips'
import { EarnablePoint } from '@/pages/deposit/components/EarnablePoint'
import walletApi from '@/pages/wallet/api/WalletApi'
import TopUpCustomAmountCurrencyInput from './TopUpCustomAmountCurrencyInput'

const TopUpCustomAmount: React.FC = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const { t } = useTranslation()
  const [amount, setAmount] = React.useState<number | string>('')
  const [debouncedAmount, setDebouncedAmount] = React.useState<number>(0)

  const walletDetailsQuery = walletApi.useWalletDetailsQuery({}, { refetchOnMountOrArgChange: true })

  const wallet = React.useMemo(() => {
    return walletDetailsQuery.data?.data
  }, [walletDetailsQuery.data?.data])

  const maximumDepositAmount = React.useMemo(() => {
    return wallet?.company.settings.maximum_deposit.amount ?? DefaultMaximumDepositAmount
  }, [wallet?.company.settings.maximum_deposit.amount])

  const minimumDepositAmount = React.useMemo(() => {
    return wallet?.company.settings.minimum_deposit.amount ?? 1
  }, [wallet?.company.settings.minimum_deposit.amount])

  const isValid = React.useMemo(() => {
    const numericAmount = typeof amount === 'string' ? parseFloat(amount) : amount
    return numericAmount >= minimumDepositAmount && numericAmount <= maximumDepositAmount
  }, [amount, maximumDepositAmount, minimumDepositAmount])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleChangeDebouncedValue = React.useCallback(
    debounce(value => {
      setDebouncedAmount(value)
    }, 500),
    [],
  )

  const handleChangeValue = React.useCallback(
    (value: number) => {
      const resultValue = value || ''
      setAmount(resultValue)
      handleChangeDebouncedValue(resultValue)
    },
    [handleChangeDebouncedValue],
  )

  if (!wallet) return null

  return (
    <React.Fragment>
      <Header color="black" goBack={() => navigate(-1)} />
      <Section className="!py-2">
        <ContentHeader
          title={t('@deposit.TopUpCustomAmount.content_header_title')}
          description={t('@deposit.TopUpCustomAmount.content_header_description')}
        />
        <TopUpCustomAmountCurrencyInput
          amount={amount}
          currency={wallet.company.currency}
          minimumDepositAmount={minimumDepositAmount}
          maximumDepositAmount={maximumDepositAmount}
          onChangeValue={handleChangeValue}
        />
        <EarnablePoint
          amount={debouncedAmount}
          currency={wallet.company.currency}
          minimumDepositAmount={minimumDepositAmount}
          maximumDepositAmount={maximumDepositAmount}
          onChangeAmount={handleChangeValue}
          walletId={location.state.walletId}
        />
      </Section>
      <div className={styles.Keyboard}>
        <DepositChips
          isVisibleFooterComponent={false}
          amount={amount}
          onChangeValue={handleChangeValue}
          walletId={location.state.walletId}
        />
        <Section className="grid !py-6">
          <Button
            variant="primary"
            size="large"
            disabled={!isValid}
            radiusType="rounded"
            onClick={() => navigate('/topup', { state: { customAmount: amount } })}>
            {t('@deposit.TopUpCustomAmount.submit_button')}
          </Button>
        </Section>
      </div>
    </React.Fragment>
  )
}

export default TopUpCustomAmount
