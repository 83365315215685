import React from 'react'
import CurrencyInput from 'react-currency-input-field'
import { CurrencyFormatter } from '@macellan/formatter'
import type { TopUpCustomAmountCurrencyInputProps } from './TopUpCustomAmount.types'
import styles from './TopUpCustomAmount.module.css'
import i18n from '@/translations'

const TopUpCustomAmountCurrencyInput: React.FC<TopUpCustomAmountCurrencyInputProps> = props => {
  const locale = i18n.getLocale()
  const { currency, amount, maximumDepositAmount, onChangeValue } = props

  const details = React.useMemo(() => {
    if (!currency) return { symbol: '', seperator: '.', decimal: ',' }
    const symbol = CurrencyFormatter.getSymbol(currency)
    const seperators = CurrencyFormatter.getLocaleSeparators(locale)

    return {
      symbol: symbol,
      decimal: seperators.decimal,
      seperator: seperators.separator,
    }
  }, [locale, currency])

  return (
    <div className={styles.InputWrapper}>
      <CurrencyInput
        className={styles.CurrencyInput}
        value={amount}
        placeholder={`${details.symbol}0`}
        autoFocus={true}
        prefix={details.symbol}
        decimalSeparator={details.seperator}
        groupSeparator={details.decimal}
        decimalScale={0}
        maxLength={maximumDepositAmount.toString().length}
        allowNegativeValue={false}
        onValueChange={value => {
          onChangeValue(Number(value))
        }}
        inputMode="numeric"
        pattern="[0-9]*"
        step={1}
      />
    </div>
  )
}

export default TopUpCustomAmountCurrencyInput
