import * as React from 'react'
import type { SwitchProps } from './Switch.types'
import styles from './Switch.module.css'
import ThemeConfig from '~/tailwind.config'
import { Container } from '../Container'
import { Icon } from '../Icons'

const Switch: React.FC<SwitchProps> = props => {
  const { value, onChangeValue, ...rest } = props
  const { colors } = ThemeConfig.theme.extend

  return (
    <Container className="flex">
      <button
        role="switch"
        aria-checked={value}
        onClick={() => onChangeValue(!value)}
        className={`${value ? styles.SwitchContainerActive : styles.SwitchContainerDefault} ${styles.SwitchContainer}`}
        {...rest}>
        <span className={`${value ? styles.SwitchActive : styles.SwitchDefault} ${styles.Switch}`}>
          {value && <Icon name="check" size={24} color={colors.semantic.content.ink} />}
        </span>
      </button>
    </Container>
  )
}

export default Switch
