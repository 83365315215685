import { AsYouType, getCountryCallingCode } from 'libphonenumber-js'
import type { GetCountryByCodeFunction, GetPhoneInformationFunction } from './PhoneField.types'
import { CountryConstants } from '@/constants'

export const getPhoneInformation: GetPhoneInformationFunction = phoneNumber => {
  const asYouType = new AsYouType()

  asYouType.input(phoneNumber)

  if (!asYouType.getNumber()) return

  const nationalNumber = asYouType.getNumber()?.nationalNumber
  const countryCallingCode = asYouType.getNumber()?.countryCallingCode

  if (!nationalNumber || !countryCallingCode) return

  const country = CountryConstants.phones.find(countryItem => countryItem.key === countryCallingCode)

  if (!country) return

  const isValid = asYouType.isValid()

  return {
    country: country,
    nationalNumber: nationalNumber,
    isValid: isValid,
  }
}

export const getCountryByCode: GetCountryByCodeFunction = countryCode => {
  if (!countryCode) return

  const countryCallingCode = getCountryCallingCode(countryCode)
  if (!countryCallingCode) return

  return CountryConstants.phones.find(countryItem => countryItem.key === countryCallingCode)
}
