import { map } from 'lodash'
import type { PaymentRefundReasonListProps } from './PaymentRefund.types'
import { Badge } from '@/components/Badge'

const PaymentRefundReasonList: React.FC<PaymentRefundReasonListProps> = props => {
  const { reasons, activeReason, onPress } = props
  return (
    <div className="flex flex-wrap gap-2">
      {map(reasons, (item, index) => (
        <Badge
          key={index.toString() + item.value}
          style={{ borderWidth: 1 }}
          variant={activeReason?.value === item.value ? 'brand-tonal' : 'outlined'}
          size="medium"
          onClick={() => onPress(item)}>
          {item.label}
        </Badge>
      ))}
    </div>
  )
}

export default PaymentRefundReasonList
