import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { filter } from 'lodash'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'
import { useFormik } from 'formik'
import type { CategoryItem, FilterDateForm, FilterDateItem, FilterItem } from './AccountActivitiesFilter.types'
import { Categories } from './AccountActivitiesFilter.constants'
import { filterDateValidationSchema } from './AccountActivities.validations'
import { Button } from '@/components/Button'
import { DateTimeField } from '@/components/DateTimeField'
import { Header } from '@/components/Header'
import { Section } from '@/components/Section'
import { SectionBar } from '@/components/SectionBar'
import { ToolBar } from '@/components/ToolBar'
import CategorySection from './CategorySection'
import FilterSection from './FilterSection'

const AccountActivitiesFilterPage: React.FC = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const [category, setCategory] = React.useState<CategoryItem>()
  const [filters, setFilters] = React.useState<FilterItem[]>([])

  React.useEffect(() => {
    const activeFilters = filter(
      location.state?.filters,
      filterItem => filterItem.type !== 'started_date' && filterItem.type !== 'end_date',
    ) as FilterItem[]

    if (activeFilters.length > 0) {
      const activeCategory = filter(Categories, categoryItem => categoryItem.type === activeFilters[0].category)[0]
      setFilters(activeFilters)
      setCategory(activeCategory)
    }
  }, [location.state?.filters])

  const filteredList = React.useCallback(
    // eslint-disable-next-line
    (item: any) => filter(filters, filteredItem => filteredItem.type !== item.type) as [],
    [filters],
  )

  const selectedCategory = React.useMemo(() => (category ? category.filters : []), [category])

  const handleOnSubmit = React.useCallback(
    (values: FilterDateForm) => {
      const activeFilters = [...filters] as (FilterItem | FilterDateItem)[]

      if (values.startedDate) {
        activeFilters.push({
          type: 'started_date',
          date: dayjs(values.startedDate).format('DD.MM.YYYY'),
        })
      }

      if (values.endDate) {
        activeFilters.push({
          type: 'end_date',
          date: dayjs(values.endDate).format('DD.MM.YYYY'),
        })
      }

      return navigate('/account-activities', {
        state: {
          walletId: location.state.walletId,
          filters: activeFilters,
          balanceFilters: filters,
          startedDate: values.startedDate?.toDateString(),
          endDate: values.endDate?.toDateString(),
        },
      })
    },
    [filters, navigate, location.state.walletId],
  )

  const formik = useFormik<FilterDateForm>({
    initialValues: {
      startedDate: location.state?.startedDate ? new Date(location.state.startedDate) : undefined,
      endDate: location.state?.endDate ? new Date(location.state.endDate) : undefined,
    },
    onSubmit: handleOnSubmit,
    validationSchema: filterDateValidationSchema,
  })

  return (
    <div className="pb-24">
      <Header goBack={() => navigate(-1)} />
      <Section className="border-b border-semantic-background-tertiary !py-0">
        <SectionBar
          title={t('@wallet.AccountActivitiesFilter.content_header_title')}
          description={t('@wallet.AccountActivitiesFilter.content_header_description')}
        />
      </Section>
      <CategorySection
        category={category}
        handleOnPress={item => {
          setCategory(item)
          setFilters([])
        }}
      />
      <FilterSection
        filters={selectedCategory}
        selectedFilters={filters}
        handleOnPress={filterItem => {
          const filtered = filteredList(filterItem)
          if (filtered.length !== filters.length) {
            setFilters(filtered)
          } else {
            setFilters([...filtered, filterItem])
          }
        }}
      />
      <Section className="flex flex-col gap-11 !pt-11">
        <DateTimeField
          label={t('@wallet.AccountActivitiesFilter.started_date')}
          labelStyle="pb-4"
          id="start_date"
          placeholder={t('@wallet.AccountActivitiesFilter.date_time_place_holder')}
          value={formik.values.startedDate?.toDateString()}
          onChangeValue={date => formik.setFieldValue('startedDate', date)}
          onBlur={() => formik.setFieldTouched('startedDate', true)}
          message={formik.errors.startedDate ? formik.errors.startedDate : undefined}
          error={formik.touched.startedDate && formik.errors.startedDate ? 'error' : undefined}
        />
        <DateTimeField
          label={t('@wallet.AccountActivitiesFilter.end_date')}
          labelStyle="pb-4"
          id="end_date"
          placeholder={t('@wallet.AccountActivitiesFilter.date_time_place_holder')}
          value={formik.values.endDate?.toDateString()}
          onChangeValue={date => formik.setFieldValue('endDate', date)}
          onBlur={() => formik.setFieldTouched('endDate', true)}
          message={formik.errors.endDate ? formik.errors.endDate : undefined}
          error={formik.touched.endDate && formik.errors.endDate ? 'error' : undefined}
        />
      </Section>
      <ToolBar className="grid grid-cols-1 border-none">
        <Button
          type="submit"
          variant="primary"
          size="large"
          radiusType="rounded"
          disabled={!formik.isValid}
          onClick={() => formik.handleSubmit()}>
          {t('@wallet.AccountActivitiesFilter.submit')}
        </Button>
      </ToolBar>
    </div>
  )
}

export default AccountActivitiesFilterPage
