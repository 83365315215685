import React from 'react'
import type { RowProps, RowStaticProperties } from './Row.types'
import { Container } from '../Container'
import RowDescription from './RowDescription'
import RowItem from './RowItem'
import RowTitle from './RowTitle'

const Row: React.FC<RowProps> & RowStaticProperties = props => {
  const { children, className } = props
  return (
    <Container>
      <div className={className}>{children}</div>
    </Container>
  )
}

Row.Title = RowTitle
Row.Item = RowItem
Row.Description = RowDescription

export default Row
