import React from 'react'
import { map } from 'lodash'
import { useTranslation } from 'react-i18next'
import type { CategorySectionProps } from './AccountActivitiesFilter.types'
import { Categories } from './AccountActivitiesFilter.constants'
import { Badge } from '@/components/Badge'
import { EmblaCarousel } from '@/components/EmblaCarousel'
import { Section } from '@/components/Section'
import AccountActivitiesSectionHeader from '../AccountActivities/AccountActivitiesSectionHeader'

const CategorySection: React.FC<CategorySectionProps> = props => {
  const { category, handleOnPress } = props
  const { t } = useTranslation()
  return (
    <div className="flex flex-col gap-2">
      <Section className="!py-0">
        <AccountActivitiesSectionHeader title={t('@wallet.AccountActivitiesFilter.categories')} />
      </Section>
      <EmblaCarousel slideSize="auto">
        {map(Categories, (item, index) => (
          <Badge
            key={item.type + index.toString()}
            variant={item.type === category?.type ? 'brand-tonal' : 'outlined'}
            size="medium"
            onClick={() => handleOnPress(item)}>
            {t(`@wallet.AccountActivitiesFilter.${item.type}`)}
          </Badge>
        ))}
      </EmblaCarousel>
    </div>
  )
}

export default CategorySection
