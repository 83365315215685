// DateTimeField.tsx
import React from 'react'
import dayjs from 'dayjs'
import cn from 'classnames'
import type { DateTimeFieldProps } from './DateTimeField.types'
import styles from './DateTimeField.module.css'
import ThemeConfig from '~/tailwind.config'
import { Container } from '../Container'
import { Icon } from '../Icons'
import Calendar from './Calendar'

const DateTimeField: React.FC<DateTimeFieldProps> = props => {
  const { label, labelStyle, onChangeValue, message, error, ...rest } = props
  const { colors } = ThemeConfig.theme.extend
  const [showDatePicker, setShowDatePicker] = React.useState<boolean>(false)
  const [selectedDate, setSelectedDate] = React.useState<dayjs.Dayjs | null>(
    props.value ? dayjs(props.value.toString()) : null,
  )
  const [currentMonth, setCurrentMonth] = React.useState<dayjs.Dayjs>(dayjs())

  const handleDateSelect = (day: number) => {
    const date = currentMonth.date(day)
    setSelectedDate(date)
    setShowDatePicker(false)
    if (onChangeValue) return onChangeValue(date.toDate())
  }

  return (
    <Container>
      <div className={styles.Container}>
        <label htmlFor={props.id} className={cn(styles.DateTimeFieldLabel, labelStyle, error && styles.Error)}>
          {label}
        </label>
        <div className={styles.DateTimeFieldContainer}>
          <div className={styles.DateTimeFieldIcon}>
            <Icon name="calendar" size={24} color={colors.solid.ink[6]} />
          </div>
          <input
            type="text"
            className={cn(styles.DateTimeFieldInput, error && styles.Error)}
            style={{
              color: selectedDate ? colors.semantic.content.ink : colors.semantic.content.inkLight,
            }}
            readOnly
            onClick={() => setShowDatePicker(!showDatePicker)}
            {...rest}
            value={selectedDate ? selectedDate.format('DD/MM/YYYY') : ''}
          />
        </div>
        <Calendar
          selectedDate={selectedDate}
          showDatePicker={showDatePicker}
          handleDateSelect={handleDateSelect}
          setCurrentMonth={setCurrentMonth}
          currentMonth={currentMonth}
        />
        {showDatePicker && <div className={styles.DateTimePickerOverlay} onClick={() => setShowDatePicker(false)} />}
        <span className={styles.Helper}>{message}</span>
      </div>
    </Container>
  )
}

export default DateTimeField
