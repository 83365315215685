export default {
  CompanyWalletSubscribe: {
    route_header_title: 'Cüzdan Ekle',
    add_button_title: 'Cüzdanı Ekle',
    agreement_title: 'Cüzdan Sözleşmesi',
    agreement_description:
      'Cüzdanı eklediğinizde <0 href="{{agreement_url}}">Cüzdan Sözleşmesi</0>‘ni kabul etmiş sayılırsınız',
    success_message: 'Cüzdanın başarıyla oluşturuldu',
  },
}
