import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { MasterPassSDK } from '@macellan/masterpass-sdk/src'
import toast from '@/context/ToastContext/Toast.utils'
import { useAppSelector } from '@/store'
import { Header } from '@/components/Header'
import { InfoSheet } from '@/components/InfoSheet'
import { Section } from '@/components/Section'
import { MasterPassService } from '../../services'

const MasterPassLink: React.FC = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()

  const { mpLinkLoading } = useAppSelector(state => state.depositSlice)

  const linkMpAccount = React.useCallback(async () => {
    await MasterPassService.linkCardToClient()
      .then(result => {
        if (result.responseCode === MasterPassSDK.Constants.ResponseCodes.VALIDATE_OTP) {
          navigate('/masterpass-otp', {
            state: {
              otpType: 'link',
            },
            replace: true,
          })
        }
      })
      .catch(error => {
        toast.error({ message: error.data.message, icon: 'warning' })
      })
  }, [navigate])

  return (
    <React.Fragment>
      <Header
        variant="secondary"
        title={t('@deposit.MasterPassLink.route_header_title')}
        goBack={() => navigate(-1)}
        color="black"
      />
      <Section className="!h-screen-size !py-8">
        <InfoSheet
          containerClassName="h-full"
          avatarIcon="credit-card"
          title={t('@deposit.MasterPassLink.info_sheet_title')}
          description={t('@deposit.MasterPassLink.info_sheet_description')}
          question={t('@deposit.MasterPassLink.info_sheet_question')}
          buttons={[
            {
              variant: 'primary',
              size: 'large',
              radiusType: 'rounded',
              children: t('@deposit.MasterPassLink.info_sheet_approve_button'),
              loading: mpLinkLoading,
              onClick: linkMpAccount,
            },
            {
              variant: 'secondary',
              size: 'large',
              radiusType: 'rounded',
              children: t('@deposit.MasterPassLink.info_sheet_cancel_button'),
              onClick: () => navigate(-1),
            },
          ]}
        />
      </Section>
    </React.Fragment>
  )
}

export default MasterPassLink
