import React from 'react'
import { useInView } from 'react-intersection-observer'
import { useNavigate } from 'react-router-dom'
import { isEmpty, map } from 'lodash'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'
import type { AccountActivitySectionListProps } from './AccountActivityList.types'
import { usePagination } from '@/hooks'
import { EmptySheet } from '@/components/EmptySheet'
import { HistoryItem } from '@/components/HistoryItem'
import { Section } from '@/components/Section'
import walletApi from '@/pages/wallet/api'
import { groupHistoryByDate } from '@/pages/wallet/views/AccountActivities/AccountActivities.utils'
import AccountActivitiesSectionHeader from '@/pages/wallet/views/AccountActivities/AccountActivitiesSectionHeader'
import AccountActivitySectionListSkeleton from './AccountActivitySectionListSkeleton'

const AccountActivitySectionList: React.FC<AccountActivitySectionListProps> = props => {
  const { config } = props
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [ref, inView] = useInView({ rootMargin: '0px 0px 100px 0px' })

  const paginate = usePagination(walletApi.useBalanceHistoryListQuery, {
    ...config,
  })

  const segmentedHistories = React.useMemo(() => {
    if (!paginate.data) return []
    return groupHistoryByDate(paginate.data)
  }, [paginate.data])

  const hasMore = React.useMemo(() => {
    return paginate.meta?.paginate.has_more
  }, [paginate.meta?.paginate.has_more])

  React.useEffect(() => {
    if (!hasMore) return
    if (inView) paginate.more()
  }, [inView, hasMore, paginate])

  if (paginate.query.isLoading) return <AccountActivitySectionListSkeleton className="px-6" />

  if (!paginate.data || !paginate.meta) return null

  return (
    <React.Fragment>
      {!paginate.query.isFetching && isEmpty(segmentedHistories) && (
        <EmptySheet
          title={t('@wallet.AccountActivitySectionList.empty_sheet_title')}
          description={t('@wallet.AccountActivitySectionList.empty_sheet_description')}
        />
      )}
      <Section className="!py-0">
        {map(segmentedHistories, (section, key) => (
          <React.Fragment key={key}>
            <AccountActivitiesSectionHeader title={section.title} />
            {map(section.data, (item, index) => (
              <HistoryItem
                key={index}
                type={item.type}
                subtitle={item.company.name}
                amount={item.amount}
                point={item.total_earned_points}
                currency={item.company.currency}
                datetime={dayjs(item.created_at).toDate()}
                avatarImageSource={item.company.logo}
                onClick={() => navigate(`/account-activities/${item.id}`)}
              />
            ))}
          </React.Fragment>
        ))}
        {paginate.query.isFetching && <AccountActivitySectionListSkeleton />}
        <div ref={ref} />
      </Section>
    </React.Fragment>
  )
}

export default AccountActivitySectionList
