export default {
  CampaignForYou: {
    section_bar_title: 'Personalized for You',
    section_bar_description: 'Explore all campaigns tailored for you',
  },
  CampaignRewardList: {
    campaign_section_title: 'Campaigns',
    campaign_section_description: 'Follow opportunities and contribute to your budget',
  },
  CampaignSegmentedList: {
    segmented_section_topup_title: 'Load and Earn',
    segmented_section_capture_title: 'Spend and Earn',
  },
  Campaigns: {
    route_header_title: 'Campaigns',
    route_header_description: "Follow campaigns, don't miss out on opportunities!",
    header_right_button_title: 'Select Wallet',
    campaigns_chips_label_all: 'All',
    campaigns_chips_label_deposit: 'Load and Earn',
    campaigns_chips_label_payment: 'Spend and Earn',
    campaigns_ending_soon_title: 'Ending Soon',
    campaigns_ending_soon_description: 'Benefit from campaigns before they expire',
    empty_sheet_title: 'No Campaign Found',
    empty_sheet_description: 'No campaign found based on your criteria',
  },
  CampaignDetail: {
    route_header_title: 'Campaign Details',
    content_header_left_title: 'END DATE',
    content_header_right_title: 'GIFT POINTS',
    footer_deposit_button: 'Load Balance',
    footer_deposit_description: 'Load your balance to take advantage of the offer!',
    condition_participation_title: 'Participation Conditions',
    condition_participation_button_more_title: 'Read More',
    condition_participation_button_hide_title: 'Hide',
    other_campaigns_title: 'You Might Also Like',
    other_campaigns_description: 'Other campaigns related to the brand',
    payment_count_gift_toast_message: '{{formattedEarnablePoint}} gift points earned, {{remainderCount}} spending left',
    deposit_gift_toast_message: 'You have benefited from this campaign {{usageCount}} times',
    deposit_gift_disposable_toast_message: 'You have already benefited from this campaign',
    toast_message_chip_title: 'Earned: {{formattedWonPoint}}',
  },
  CampaignItemTiny: {
    button_text: 'Detail',
  },
}
