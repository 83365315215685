import React from 'react'
import type { ToastProviderPropsProps, ToastsProps } from './ToastContext.types'
import toastEmitter from './Toast.utils'
import type { ModalProps } from '@/components/Modal/Modal.types'
import type { ToastType } from '@/components/Toast'
import ToastContext from './ToastContext'

import { v4 as uuidv4 } from 'uuid'

const ToastProvider: React.FC<ToastProviderPropsProps> = ({ children }) => {
  const [toasts, setToasts] = React.useState<ToastsProps[]>([])
  const [modal, setModal] = React.useState<ModalProps | null>(null)
  const timers = React.useRef<Record<string, NodeJS.Timeout>>({})
  const currentTimers = timers.current

  React.useEffect(
    () => {
      const addToastListener = (toast: { variant: ToastType; message: string; duration?: number }) => {
        const duration = toast.duration ?? 3000
        const id = uuidv4()

        setToasts(currentToasts => [...currentToasts, { ...toast, id, duration }])

        currentTimers[id] = setTimeout(() => {
          removeToast(id)
        }, duration)
      }

      const addModalListener = (modal: ModalProps) => {
        setModal(modal)
      }

      toastEmitter.on('add', addToastListener)
      toastEmitter.on('modal', addModalListener)

      return () => {
        toastEmitter.off('add', addToastListener)
        toastEmitter.off('modal', addModalListener)
        Object.values(currentTimers).forEach(clearTimeout)
      }
    },
    // eslint-disable-next-line
    [],
  )

  const removeToast = (id: string) => {
    clearTimeout(currentTimers[id])
    delete currentTimers[id]
    setToasts(currentToasts => currentToasts.filter(toast => toast.id !== id))
  }

  const closeModal = () => {
    setModal(null)
  }

  return (
    <ToastContext.Provider
      value={{
        toasts,
        modal,
        addToast: toastEmitter.emit.bind(toastEmitter, 'add'),
        addModal: toastEmitter.emit.bind(toastEmitter, 'modal'),
        closeModal,
      }}>
      {children}
    </ToastContext.Provider>
  )
}

export default ToastProvider
