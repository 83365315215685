export default {
  Payment: {
    route_header_title: 'Ödeme Yap',
    tab_label_camera: 'QR Oku',
    tab_label_qr: 'QR Göster',
    manuel_qr_label: 'Kod Numarası Gir',
  },
  Capture: {
    empty_sheet_title: 'İşlemin\ngerçekleştiriliyor...',
    empty_sheet_description: 'Ödeme işlemi bitene kadar lütfen kapatma...',
    complate_alert_title: 'Ödeme başarıyla\ngerçekleşti',
    complate_alert_description: 'Ödeme detaylarını görüntülemek için\ncüzdan hareketlerini kontrol edebilirsin',
    complate_alert_accept_button: 'Tamam',
    status_reject_message: 'Ödeme işlemin iptal edildi',
    cancel_alert_title: 'Ödemeyi İptal Et',
    cancel_alert_message: 'Ödeme işlemini iptal etmek istediğine emin misin?',
    cancel_alert_approve_button: 'Evet',
    cancel_alert_cancel_button: 'Vazgeç',
    action_button_cancel_title: 'İptal Et',
    button_payment_title: 'Öde',
    price_header_title: 'Ödenecek Tutar',
    price_header_description: 'Ödeme bilgilerine lütfen dikkat et',
    wallet_row_title: 'CÜZDAN',
    wallet_switch_title: 'Hediye Puanlarını Kullan',
    toast_chip_label: 'Bakiye Yükle',
    toast_success_message: 'Bakiye yükleme başarıyla gerçekleşti',
    toast_error_message: 'Ödeme yapabilmek için bakiye yüklemen gerekmektedir',
  },
  PaymentRefund: {
    route_header_title: 'Harcama İadesi',
    submit_button: 'İade İste',
    refund_title: 'İADE SEBEBİ',
    refund_description: 'İADE AÇIKLAMASI',
    refund_request_success: 'İade talebi oluşturuldu',
    comment_placeholder: 'Açıklama girin',
  },
  PaymentRefundEftPos: {
    route_header_title: 'Harcama İadesi',
    order_title: 'İade Numarası',
    title: 'İade Uyarısı',
    description:
      'POS cihazı ile alınan ödemelerde, harcama tutarının iadesi için “Sipariş Numarasını” firmaya iletmen gerekmektedir',
    submit_button: 'Kupon Ekle',
  },
}
