import React from 'react'
import useEmblaCarousel from 'embla-carousel-react'
import type { EmblaOptionsType } from 'embla-carousel'
import type { EmblaCarouselPropType } from './EmblaCarousel.types'
import styles from './EmblaCarousel.module.css'
import { getSlideClassName, getSlideStyle } from './EmblaCarousel.utils'
import { Container } from '../Container'

const EmblaCarousel: React.FC<EmblaCarouselPropType> = props => {
  const { options, children, slideSize = 100 } = props
  const OPTIONS: EmblaOptionsType = { dragFree: true, containScroll: 'trimSnaps' }
  const [emblaRef] = useEmblaCarousel(options ?? OPTIONS)

  return (
    <div className={styles.Embla}>
      <div className={styles.EmblaViewport} ref={emblaRef}>
        <div className={styles.EmblaContainer}>
          {Array.isArray(children) &&
            children.map((slides, index) => (
              <div className={getSlideClassName(index, children.length)} style={getSlideStyle(slideSize)} key={index}>
                <Container>{slides}</Container>
              </div>
            ))}
        </div>
      </div>
    </div>
  )
}

export default EmblaCarousel
