import React from 'react'
import cn from 'classnames'
import type { EmptySheetProps } from './EmptySheet.types'
import styles from './EmptySheet.module.css'
import ThemeConfig from '~/tailwind.config'
import { Container } from '../Container'
import { Icon } from '../Icons'

const EmptySheet: React.FC<EmptySheetProps> = props => {
  const { title, description, icon = 'smile', className } = props
  const { colors } = ThemeConfig.theme.extend

  if (!title || !description) return null

  return (
    <Container>
      <div className={cn(styles.EmptySheetContainer, className)}>
        <div className={styles.EmptySheetAvatarIcon}>
          <Icon name={icon} size={58} color={colors.semantic.content.disabled} />
        </div>
        <div className={styles.EmptySheetContent}>
          <h6>{title}</h6>
          <p>{description}</p>
        </div>
      </div>
    </Container>
  )
}

export default EmptySheet
