import React from 'react'
import type { CampaignSectionProps } from './WalletDetail.types'
import { CampaignRewardList, CampaignSegmentedList } from '@/pages/campaign/components'

const CampaignSection: React.FC<CampaignSectionProps> = props => {
  const { walletId, companyId, color, currency } = props
  return (
    <React.Fragment>
      <CampaignRewardList walletId={walletId} companyId={companyId} color={color} currency={currency} />
      <CampaignSegmentedList companyId={companyId} />
    </React.Fragment>
  )
}

export default CampaignSection
