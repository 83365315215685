import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import type { CaptureFooterProps } from './Capture.types'
import toast from '@/context/ToastContext/Toast.utils'
import { Button } from '@/components/Button'
import { ToolBar } from '@/components/ToolBar'
import paymentApi from '../../api'

const CaptureFooter: React.FC<CaptureFooterProps> = props => {
  const { paymentId, walletId, pointUsage, onChangeStatus } = props
  const { t } = useTranslation()
  const navigate = useNavigate()

  const [completed, setCompleted] = React.useState<boolean>(false)

  const [respond, respondResult] = paymentApi.useRespondMutation()

  const navigateToWalletDetail = React.useCallback(() => {
    return navigate('/', {
      state: {
        walletId: walletId,
      },
      replace: true,
    })
  }, [navigate, walletId])

  const handleStatusComplete = React.useCallback(() => {
    const showCompleteAlert = () =>
      toast.modal({
        title: t('@payment.Capture.complate_alert_title'),
        description: t('@payment.Capture.complate_alert_description'),
        variant: 'success',
        icon: 'check-circle',
        actions: [
          {
            variant: 'primary',
            size: 'large',
            radiusType: 'rounded',
            children: t('@payment.Capture.complate_alert_accept_button'),
          },
        ],
      })

    setTimeout(showCompleteAlert, 750)
    navigateToWalletDetail()
  }, [navigateToWalletDetail, t])

  const handleStatusReject = React.useCallback(() => {
    toast.default({
      message: t('@payment.Capture.status_reject_message'),
      icon: 'warning',
    })

    navigateToWalletDetail()
  }, [navigateToWalletDetail, t])

  const handleRespond = React.useCallback(
    async (reply: boolean) => {
      try {
        setCompleted(true)

        const result = await respond({
          payment_id: paymentId,
          reply: reply,
          point_usage: pointUsage,
        }).unwrap()

        if (result.data.status === 'reject') return handleStatusReject()

        if (result.data.status === 'complete') return handleStatusComplete()

        onChangeStatus(result.data.status)
      } catch {
        navigateToWalletDetail()
      }
    },
    [handleStatusComplete, onChangeStatus, handleStatusReject, navigateToWalletDetail, paymentId, pointUsage, respond],
  )

  const showCancelAlert = React.useCallback(() => {
    toast.modal({
      title: t('@payment.Capture.cancel_alert_title'),
      description: t('@payment.Capture.cancel_alert_message'),
      variant: 'suggestion',
      icon: 'warning',
      actions: [
        {
          variant: 'primary',
          size: 'large',
          radiusType: 'rounded',
          children: t('@payment.Capture.cancel_alert_approve_button'),
          onClick: () => handleRespond(false),
        },
        {
          variant: 'secondary',
          size: 'large',
          radiusType: 'rounded',
          children: t('@payment.Capture.cancel_alert_cancel_button'),
        },
      ],
    })
  }, [handleRespond, t])

  return (
    <ToolBar>
      <ToolBar.Left>
        <Button
          style={{ justifyContent: 'flex-start' }}
          variant="ghost-on-light"
          size="small"
          radiusType="rounded"
          className="underline"
          onClick={showCancelAlert}>
          {t('@payment.Capture.action_button_cancel_title')}
        </Button>
      </ToolBar.Left>
      <ToolBar.Right>
        <Button
          variant="primary"
          size="large"
          radiusType="rounded"
          loading={respondResult.isLoading || completed}
          onClick={() => handleRespond(true)}>
          {t('@payment.Capture.button_payment_title')}
        </Button>
      </ToolBar.Right>
    </ToolBar>
  )
}

export default CaptureFooter
