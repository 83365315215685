import 'dayjs/locale/en'
import 'dayjs/locale/tr'
import dayjs from 'dayjs'
import i18next from '@/translations'

const setLocale = () => dayjs.locale(i18next.getSupportedLocale())

export default {
  setLocale: setLocale,
}
