import * as React from 'react'
import type { ToastProgressProps } from './Toast.types'
import { getToastProgressColors } from './Toast.utils'
import Progress from '../Progress/Progress'

const ToastProgress: React.FC<ToastProgressProps> = ({ type = 'default', ...props }) => {
  const colors = React.useMemo(() => {
    return getToastProgressColors(type)
  }, [type])

  return (
    <Progress
      backgroundColor={colors.background}
      indicatorColor={colors.indicator}
      valueTextColor={colors.text}
      size={40}
      {...props}
    />
  )
}

ToastProgress.displayName = 'Toast.Progress'

export default ToastProgress
