import styles from './EmblaCarousel.module.css'

export const getSlideClassName = (index: number, totalSlides: number) => {
  const isLastSlide = index === totalSlides - 1 && totalSlides > 1

  return [styles.EmblaSlide, isLastSlide ? styles.EmblaSlideLast : ''].join(' ').trim()
}

export const getSlideStyle = (slideSize: number | 'auto') => {
  return {
    flex: slideSize === 'auto' ? '0 0 auto' : `0 0 ${slideSize}%`,
  }
}
