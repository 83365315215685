import * as React from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import type { OtherPaymentMethodSectionProps } from './TopUp.types'
import { useAppSelector } from '@/store'
import { DepositCard } from '@/components/DepositCard'
import { Row } from '@/components/Row'
import { useSelectedCard } from '@/pages/deposit/hook'

const OtherPaymentSection: React.FC<OtherPaymentMethodSectionProps> = ({
  walletId,
  companyId,
  amount,
  enabledCashDeposit,
  enabledMasterPass,
}) => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const selected = useSelectedCard('masterpass')
  const { mpAccountLoading } = useAppSelector(state => state.depositSlice)

  if ((!enabledCashDeposit && !enabledMasterPass) || companyId == 222) return null

  return (
    <div className="pb-7">
      <Row>
        <div className="pb-3">
          <Row.Title title={t('@deposit.TopUp.other_payment_method_section_title')} />
        </div>
        {enabledMasterPass ? (
          mpAccountLoading || selected.isLoading ? (
            <DepositCard.Loader className="!h-[56px]" description={false} logoSize={32} />
          ) : (
            <DepositCard.Other
              title={t('@deposit.TopUpAnotherCard.content_header_title')}
              avatarIcon="credit-card"
              onClick={() =>
                navigate('/topup/another-card', {
                  state: {
                    walletId: walletId,
                    amount: amount,
                  },
                })
              }
            />
          )
        ) : null}
      </Row>
    </div>
  )
}

export default OtherPaymentSection
