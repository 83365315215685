import * as React from 'react'
import { map, slice } from 'lodash'
import type { ModulesSectionProps } from './WalletDetail.types'
import { ModuleItem } from '../../components/ModuleItem'

const ModulesSection: React.FC<ModulesSectionProps> = props => {
  const { modules, walletId, companyId } = props

  if (!modules) return null

  return (
    <ModuleItem>
      {map(slice(modules, 0, 3), (module, index) => (
        <ModuleItem.Button
          key={index}
          name={module.name}
          variant={index === 0 ? 'primary' : 'secondary'}
          params={{ walletId: walletId, companyId: companyId }}
        />
      ))}
    </ModuleItem>
  )
}

export default ModulesSection
