import type { CategoryItem } from './AccountActivitiesFilter.types'

export const Categories: CategoryItem[] = [
  {
    type: 'balance',
    filters: [
      {
        category: 'balance',
        type: 'capture',
        labelType: 'capture',
      },
      {
        category: 'balance',
        type: 'top-up',
        labelType: 'top_up',
      },
    ],
  },
  {
    type: 'refund',
    filters: [
      {
        category: 'refund',
        type: 'deposit_refund',
        labelType: 'deposit_refund',
      },
      {
        category: 'refund',
        type: 'payment_refund',
        labelType: 'payment_refund',
      },
    ],
  },
]
