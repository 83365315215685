import React from 'react'
import { motion } from 'framer-motion'
import type { AnimatedContainerProps } from './Container.types'

const Container: React.FC<AnimatedContainerProps> = props => {
  const { children, duration = 0.5, className } = props

  return (
    <motion.div
      initial="hidden"
      animate="visible"
      variants={{ hidden: { opacity: 0 }, visible: { opacity: 1 } }}
      transition={{ duration: duration, ease: 'easeInOut' }}
      className={className}>
      {children}
    </motion.div>
  )
}

export default Container
