/* eslint-disable */
import * as React from 'react'
import { useNavigate } from 'react-router-dom'
import { find } from 'lodash'
import toast from '@/context/ToastContext/Toast.utils'
import { MasterPassSDK } from '@macellan/masterpass-sdk/src'
import { useLocalStorageListener } from '@/hooks'
import baseApi from '@/api'
import { useAppDispatch } from '@/store'

import depositApi, { type CardType } from '@/pages/deposit/api'
import { MasterPassService, PaymentMethodsService } from '@/pages/deposit/services'
import walletApi, { Wallet } from '@/pages/wallet/api'
import { PaymentMinimal } from '@/pages/payment/api'

export interface UseTopUpData {
  walletId: number
  amount: number
  installmentCount: number | null
  canGoBack?: {
    payment: PaymentMinimal
    wallet: Wallet
    path: string
  }
}

const useTopUp = (data: UseTopUpData, onComplete: (success: boolean) => void) => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const [paymentMethod, setPaymentMethod] = React.useState<CardType>()

  const [loading, setLoading] = React.useState<boolean>(false)

  const cardListQuery = depositApi.useUserCardListQuery(undefined)
  const walletDetailsQuery = walletApi.useWalletDetailsQuery({}, { refetchOnMountOrArgChange: true })

  const depositMethods = React.useMemo(() => {
    return walletDetailsQuery.data?.data.company.deposit_methods
  }, [walletDetailsQuery.data?.data.company.deposit_methods])

  const handleLastPaymentMethod = React.useCallback(() => {
    if (!depositMethods) return

    PaymentMethodsService.getLastPaymentMethod(data.walletId, depositMethods).then(result => {
      setPaymentMethod(result as CardType)
    })
  }, [data.walletId, depositMethods])

  useLocalStorageListener(data.walletId, handleLastPaymentMethod)

  React.useEffect(() => {
    handleLastPaymentMethod()
  }, [handleLastPaymentMethod])

  const selectedCard = React.useMemo(() => {
    if (!cardListQuery.data?.data || !paymentMethod) return

    return PaymentMethodsService.getSelectedCard(cardListQuery.data?.data, paymentMethod)
  }, [cardListQuery.data?.data, paymentMethod])

  const getWalletList = React.useCallback(() => {
    dispatch(baseApi.util.invalidateTags(['Wallets']))
  }, [dispatch])

  const handleMasterPassPurchase = React.useCallback(async () => {
    try {
      if (!selectedCard) return

      const mpCardList = await MasterPassService.listCards()

      const card = find(mpCardList, mpCard => mpCard.Name === selectedCard.name)

      if (!card) return

      const purchaseResult = await MasterPassService.purchase({
        walletId: data.walletId,
        amount: data.amount,
        card: card,
        installmentCount: data.installmentCount,
      })

      if (
        purchaseResult.sdkResult.responseCode === MasterPassSDK.Constants.ResponseCodes.SUCCESS ||
        purchaseResult.sdkResult.responseCode === MasterPassSDK.Constants.ResponseCodes.SUCCESS_EMPTY
      ) {
        const mpMakeDepositResult = await MasterPassService.makeDeposit({
          depositId: purchaseResult.initResult.data.deposit_id,
          token: purchaseResult.sdkResult.token,
        })

        getWalletList()
        onComplete(true)
        return mpMakeDepositResult
      }

      if (purchaseResult.sdkResult.responseCode === MasterPassSDK.Constants.ResponseCodes.VALIDATE_OTP) {
        await MasterPassService.markOTP(purchaseResult.initResult.data.deposit_id)
        navigate('/masterpass-otp', {
          state: {
            canGoBack: data?.canGoBack,
            otpType: 'purchase',
            depositId: purchaseResult.initResult.data.deposit_id,
          },
        })
      }

      if (purchaseResult.sdkResult.responseCode === MasterPassSDK.Constants.ResponseCodes.VALIDATE_3D_SECURE) {
        navigate('/masterpass-3d', {
          state: {
            canGoBack: data?.canGoBack,
            depositId: purchaseResult.initResult.data.deposit_id,
            threeDUrl: purchaseResult.sdkResult.url3D + '&returnUrl=' + purchaseResult.initResult.data.callback_url,
          },
        })
      }
    } catch (error: any) {
      toast.error({ message: error.data.message, icon: 'warning' })
    }
  }, [data.amount, data.walletId, data.installmentCount, getWalletList, navigate, onComplete, selectedCard])

  const handleInit = React.useCallback(async () => {
    setLoading(true)

    try {
      if (paymentMethod === 'masterpass') return await handleMasterPassPurchase()
    } catch (error) {
      onComplete(false)
      return Promise.reject(error)
    } finally {
      setLoading(false)
    }
  }, [handleMasterPassPurchase, onComplete, paymentMethod])

  return {
    loading: loading,
    init: handleInit,
  }
}

export default useTopUp
