import dayjs from 'dayjs'
import * as yup from 'yup'
import i18n from '@/translations'

yup.setLocale({
  mixed: {
    default: () => i18n.t('validation.error_message.mixed_default'),
    required: () => i18n.t('validation.error_message.mixed_required'),
    oneOf: () => i18n.t('validation.error_message.mixed_one_of'),
  },
  string: {
    length: params =>
      i18n.t('validation.error_message.string_length', {
        length: params.length,
      }),
    min: params =>
      i18n.t('validation.error_message.string_min', {
        min: params.min,
      }),
    max: params =>
      i18n.t('validation.error_message.string_max', {
        max: params.max,
      }),
    matches: () => i18n.t('validation.error_message.string_matches'),
    email: () => i18n.t('validation.error_message.string_email'),
    url: () => i18n.t('validation.error_message.string_url'),
  },
  number: {
    min: params =>
      i18n.t('validation.error_message.number_min', {
        min: params.min,
      }),
    max: params =>
      i18n.t('validation.error_message.number_max', {
        max: params.max,
      }),
    lessThan: params =>
      i18n.t('validation.error_message.number_less_than', {
        less: params.less,
      }),
    moreThan: params =>
      i18n.t('validation.error_message.number_more_than', {
        more: params.more,
      }),
    positive: () => i18n.t('validation.error_message.number_positive'),
    negative: () => i18n.t('validation.error_message.number_negative'),
    integer: () => i18n.t('validation.error_message.number_integer'),
  },
  date: {
    min: params =>
      i18n.t('validation.error_message.date_min', {
        min: dayjs(params.min).format('DD/MM/YYYY'),
      }),
    max: params =>
      i18n.t('validation.error_message.date_max', {
        max: dayjs(params.max).format('DD/MM/YYYY'),
      }),
  },
  array: {
    length: params =>
      i18n.t('validation.error_message.array_length', {
        length: params.length,
      }),
    min: params =>
      i18n.t('validation.error_message.array_min', {
        min: params.min,
      }),
    max: params =>
      i18n.t('validation.error_message.array_max', {
        max: params.max,
      }),
  },
})

export default yup
