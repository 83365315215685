import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { find, map } from 'lodash'
import { useTranslation } from 'react-i18next'
import type { DetailItemProps } from './AccountActivityDetail.types'
import { useDetailsByType } from './AccountActivityDetail.hooks'
import { Button } from '@/components/Button'
import { Container } from '@/components/Container'
import { Header } from '@/components/Header'
import { Section } from '@/components/Section'
import { SectionBar } from '@/components/SectionBar'
import walletApi from '../../api'
import AccountActivityDetailSkeleton from './AccountActivityDetailSkeleton'
import DetailItem from './DetailItem'
import RefundSection from './RefundSection'

const AccountActivityDetail: React.FC = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { id } = useParams<{ id: string }>()

  const balanceHistoryDetailsQuery = walletApi.useBalanceHistoryDetailsQuery(
    { id: Number(id) },
    { refetchOnMountOrArgChange: true },
  )

  const wallet = React.useMemo(
    () => balanceHistoryDetailsQuery.data?.data.wallet,
    [balanceHistoryDetailsQuery.data?.data.wallet],
  )

  const details = useDetailsByType(t, balanceHistoryDetailsQuery.data?.data) as DetailItemProps[]

  const refundRequests = React.useMemo(
    () => balanceHistoryDetailsQuery.data?.data.historyable.refund_requests ?? [],
    [balanceHistoryDetailsQuery.data?.data.historyable.refund_requests],
  )

  const navigateRefund = React.useCallback(() => {
    if (!balanceHistoryDetailsQuery.data?.data.historyable || !wallet?.id) return

    if (balanceHistoryDetailsQuery.data?.data.historyable.gateway === 'eftpos') {
      return navigate('/payment-refund-eftpos', {
        state: {
          orderId: balanceHistoryDetailsQuery.data.data.historyable.order_id ?? 0,
          walletId: wallet.id,
        },
      })
    }
    return navigate('/payment-refund', {
      state: {
        paymendId: balanceHistoryDetailsQuery.data.data.historyable.id,
      },
    })
  }, [balanceHistoryDetailsQuery.data?.data.historyable, navigate, wallet])

  if (balanceHistoryDetailsQuery.isLoading) return <AccountActivityDetailSkeleton />

  if (!wallet || !balanceHistoryDetailsQuery.data || !details) return null

  return (
    <Container>
      <Header
        variant="primary"
        title={wallet.company.name}
        logo={wallet.company.logo}
        bgColor={wallet.company.color}
        color="white"
        goBack={() => navigate(-1)}
      />
      <Section className="pb-0 pt-4">
        <SectionBar
          title={t('@wallet.AccountActivityDetail.content_header_title')}
          description={t('@wallet.AccountActivityDetail.content_header_description')}
          LayoutRightComponent={
            balanceHistoryDetailsQuery.data?.data.type === 'capture' &&
            find(refundRequests, { complete: true }) === undefined
              ? () => (
                  <Button variant="ink-tonal" size="tiny" radiusType="pill" onClick={navigateRefund}>
                    {t('@wallet.AccountActivityDetail.refund_button_title')}
                  </Button>
                )
              : undefined
          }
        />
      </Section>
      {map(details, (item, index) => {
        return <DetailItem key={index.toString()} title={item?.title} sections={item?.sections} />
      })}
      {map(refundRequests, (item, index) => {
        return (
          <RefundSection
            key={item.id + index.toString()}
            status={item.status}
            type={balanceHistoryDetailsQuery.data?.data.type ?? ''}
            amount={item.amount}
            currency={wallet.company.currency}
            complete={item.complete}
            date={item.updated_at}
            referenceCode={item.reference_code}
            reason={item.reason_translate}
            companyComment={item.company_comment}
            userComment={item.user_comment}
          />
        )
      })}
    </Container>
  )
}

export default AccountActivityDetail
