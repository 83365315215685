import React from 'react'
import type { DepositCardOtherProps } from './DepositCard.types'
import styles from './DepositCard.module.css'
import ThemeConfig from '~/tailwind.config'
import { Icon } from '@/components/Icons'
import { Container } from '../Container'

const DepositCardOther: React.FC<DepositCardOtherProps> = props => {
  const { colors } = ThemeConfig.theme.extend
  const { title, avatarIcon, layoutRightIconProps, LayoutRightComponent, ...rest } = props

  const LayoutRightDefaultComponent = React.useMemo(() => {
    return <Icon name="caret-right" size={24} color={colors.semantic.content.inkLight} {...layoutRightIconProps} />
  }, [colors.semantic.content.inkLight, layoutRightIconProps])

  return (
    <Container>
      <button className={styles.DepositCardOther} {...rest}>
        <div className={styles.DepositCardLeft}>
          <div className={styles.DepositCardIcon}>
            {avatarIcon ? <Icon name={avatarIcon} size={16} color={colors.semantic.content.ink} /> : null}
          </div>
          <div className={styles.DepositCardLeftContent}>
            <h6 className={styles.DepositCardOtherTitle}>{title}</h6>
          </div>
        </div>
        <div className={styles.DepositCardRight}>
          {LayoutRightComponent ? LayoutRightComponent : LayoutRightDefaultComponent}
        </div>
      </button>
    </Container>
  )
}

export default DepositCardOther
