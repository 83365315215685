import React from 'react'
import dayjs from 'dayjs'
import { CurrencyFormatter } from '@macellan/formatter'
import type { HistoryItemContentProps } from './HistoryItem.types'
import styles from './HistoryItem.module.css'
import i18n from '@/translations'
import { Badge } from '../Badge'

const HistoryItemContent: React.FC<HistoryItemContentProps> = props => {
  const { item, amount, point, currency, subtitle, datetime } = props
  const locale = i18n.getLocale()

  const formattedDateText = React.useMemo(() => {
    return dayjs(datetime).format('DD MMM • HH:mm')
  }, [datetime])

  const formattedAmount = React.useMemo(() => {
    if (typeof amount !== 'number') return
    return CurrencyFormatter.format(amount, locale, currency)
  }, [amount, locale, currency])

  const formattedPoint = React.useMemo(() => {
    if (!point) return
    return CurrencyFormatter.format(point, locale, currency)
  }, [point, locale, currency])

  if (!item) return null

  return (
    <React.Fragment>
      <div className={styles.HistoryItemContentBody}>
        <h4>{item.title}</h4>
        {subtitle && <p>{subtitle}</p>}
      </div>
      <div className={styles.HistoryItemContentRight}>
        <div className={styles.HistoryItemAmountContainer}>
          {item.pointChipType && formattedPoint && (
            <Badge variant={item.pointChipType} size="tiny">
              {formattedPoint}
            </Badge>
          )}
          {item.amountColor && formattedAmount && (
            <span className={styles.HistoryItemAmount} style={{ color: item.amountColor }}>
              {formattedAmount}
            </span>
          )}
        </div>
        <div className={styles.HistoryItemDateTime}>
          <span>{formattedDateText}</span>
        </div>
      </div>
    </React.Fragment>
  )
}

export default HistoryItemContent
