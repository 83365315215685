import { CurrencyFormatter } from '@macellan/formatter'
import type { RenderDepositBadgesProps } from './DepositChips.types'
import { Badge } from '@/components/Badge'

export const renderDepositBadges = (props: RenderDepositBadgesProps) => {
  const {
    depositAmounts,
    amount,
    locale,
    currency,
    onChangeValue,
    otherLabel,
    isVisibleFooterComponent,
    handleNavigateToCustomAmount,
    useOtherLabelActive,
  } = props

  const modifiedAmounts = [...depositAmounts, { amount: 0, label: otherLabel }]

  return modifiedAmounts.map((item, index) =>
    item.label ? (
      isVisibleFooterComponent && (
        <Badge
          key={index}
          variant={useOtherLabelActive ? 'brand-tonal' : 'outlined'}
          size="large"
          onClick={handleNavigateToCustomAmount}>
          {item.label}
        </Badge>
      )
    ) : (
      <Badge
        key={index}
        variant={item.amount === amount ? 'brand-tonal' : 'outlined'}
        size="large"
        onClick={() => onChangeValue?.(item.amount)}>
        {CurrencyFormatter.format(item.amount, locale, currency)}
      </Badge>
    ),
  )
}
