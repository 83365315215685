import React from 'react'
import { useTranslation } from 'react-i18next'
import { CurrencyFormatter } from '@macellan/formatter'
import type { WalletDetailHeaderProps } from './WalletDetail.types'
import { Badge } from '@/components/Badge'
import i18n from '@/translations'
import { MoneyWrapper } from '../../components/MoneyWrapper'

const WalletDetailHeader: React.FC<WalletDetailHeaderProps> = props => {
  const { t } = useTranslation()
  const { balance, point, currency } = props

  const formattedBalance = React.useMemo(() => {
    return CurrencyFormatter.formatToDetails(balance, i18n.getLocale(), currency)
  }, [balance, currency])

  const formattedPoint = React.useMemo(() => {
    return CurrencyFormatter.format(point, i18n.getLocale(), currency)
  }, [currency, point])

  return (
    <div className="flex flex-col gap-2">
      <MoneyWrapper symbol={formattedBalance?.symbol} balance={formattedBalance?.text} />
      <Badge variant="yellow" size="small">
        {t('@wallet.WalletDetail.header_point', {
          point: formattedPoint,
        })}
      </Badge>
    </div>
  )
}

export default WalletDetailHeader
