import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import type { ResendVerifyMethods } from './OTPVerify.types'
import { formatPhoneNumber } from './OTPVerify.utils'
import toast from '@/context'
import { ContentHeader } from '@/components/ContentHeader'
import { Header } from '@/components/Header'
import { PinInput } from '@/components/PinInput'
import { Section } from '@/components/Section'
import { Environment } from '@/constants'
import { authSubmitByType } from '@/pages/user/views/Auth/Auth.utils'
import userApi from '../../api'
import ResendVerify from './ResendVerify'

const OTPVerify: React.FC = () => {
  const { state } = useLocation()
  const navigate = useNavigate()
  const { t } = useTranslation()

  const resendVerify = React.useRef<ResendVerifyMethods>(null)

  const [loginPhoneVerify] = userApi.useLoginPhoneVerifyMutation()

  const [code, setCode] = React.useState<string>('')

  const handleAutoVerifyCode = React.useCallback((verifyCode: string) => {
    setTimeout(() => {
      setCode(verifyCode)
    }, 3000)
  }, [])

  const handleVerifyLogin = React.useCallback(
    async (value: string) => {
      try {
        const result = await loginPhoneVerify({ code: value, phone: state.phone }).unwrap()

        localStorage.setItem('user.access_token', result.data.token.access_token)
        localStorage.setItem('user.refresh_token', result.data.token.refresh_token)

        if (!state.walletId)
          return navigate(`/wallet-subscribe`, {
            replace: true,
            state: { companyId: state.companyId },
          })

        if (result.data.redirect_page === 'deposit')
          return navigate(`/topup`, {
            replace: true,
            state: {
              walletId: result.data.wallet_id,
              triggerData: result.data.trigger_data,
            },
          })

        navigate('/', { replace: true })
      } catch {
        setCode('')
      }
    },
    [loginPhoneVerify, navigate, state.companyId, state.walletId, state.phone],
  )

  const handleResendVerify = React.useCallback(async () => {
    const result = await authSubmitByType({ phone: state.phone })

    toast.default({
      message: t('@user.OTPVerify.resend_toast_message'),
      icon: 'time',
    })

    if (result.data.code) {
      handleAutoVerifyCode(result.data.code)
    }

    resendVerify.current?.resetTimer(true)
  }, [handleAutoVerifyCode, t, state.phone])

  const handleSubmitValue = React.useCallback(
    (value: string) => {
      if (state.actionType === 'login') return handleVerifyLogin(value)

      return handleVerifyLogin(value)
    },
    [handleVerifyLogin, state.actionType],
  )

  React.useEffect(() => {
    if (Environment.ENV !== 'PROD' && state.code) {
      handleAutoVerifyCode(state.code)
    }
  }, [handleAutoVerifyCode, state.code])

  return (
    <React.Fragment>
      <Header title={t('@user.OTPVerify.route_header_title')} variant="secondary" goBack={() => navigate(-1)} />
      <Section className="!pt-8">
        <ContentHeader
          title={t('@user.OTPVerify.content_header_title')}
          description={t('@user.OTPVerify.content_header_description', {
            phone_number: formatPhoneNumber(state?.phone),
          })}
          className="pb-10"
        />
        <PinInput value={code} onChangeValue={setCode} autoFocus={true} onSubmitValue={handleSubmitValue} />
        <ResendVerify
          ref={resendVerify}
          duration={state?.duration}
          actionButtonProps={{
            onClick: () => handleResendVerify(),
          }}
        />
      </Section>
    </React.Fragment>
  )
}

export default OTPVerify
