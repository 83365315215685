import React from 'react'
import styles from './ModuleItem.module.css'
import ThemeConfig from '~/tailwind.config'
import { Icon } from '@/components/Icons'
import type { IconsNameType } from '@/components/Icons/Icon.types'

const ModuleItemMore: React.FC<{ icon: IconsNameType }> = props => {
  const { icon } = props
  const { colors } = ThemeConfig.theme.extend
  return (
    <div className={`${styles.ModuleItemContainer} ${styles.Secondary} h-fit !flex-initial`}>
      <Icon name={icon} size={24} color={colors.solid.core.white} />
    </div>
  )
}

export default ModuleItemMore
