import type { RewardChartComponentSizes, RewardChartListItemComponentSizes } from './RewardChart.types'

export const RewardChartSizes: RewardChartComponentSizes = {
  minHeight: 168,
}

export const RewardChartTableListItemSizes: RewardChartListItemComponentSizes = {
  width: 38,
  height: 120,
}
