import React from 'react'
import { filter, map } from 'lodash'
import { useTranslation } from 'react-i18next'
import type { FilterSectionProps } from './AccountActivitiesFilter.types'
import { Badge } from '@/components/Badge'
import { EmblaCarousel } from '@/components/EmblaCarousel'
import { Section } from '@/components/Section'
import AccountActivitiesSectionHeader from '../AccountActivities/AccountActivitiesSectionHeader'

const FilterSection: React.FC<FilterSectionProps> = props => {
  const { filters, selectedFilters, handleOnPress } = props
  const { t } = useTranslation()

  if (!filters?.length) return null

  return (
    <div className="flex flex-col gap-2">
      <Section className="!py-0">
        <AccountActivitiesSectionHeader title={t('@wallet.AccountActivitiesFilter.filters')} />
      </Section>
      <EmblaCarousel slideSize="auto">
        {map(filters, (item, index) => (
          <Badge
            key={index.toString() + item.type}
            variant={
              filter(selectedFilters, filteredItem => filteredItem.type === item.type).length > 0
                ? 'brand-tonal'
                : 'outlined'
            }
            size="medium"
            onClick={() => {
              handleOnPress(item)
            }}>
            {t(`@wallet.AccountActivitiesFilter.${item.labelType}`)}
          </Badge>
        ))}
      </EmblaCarousel>
    </div>
  )
}

export default FilterSection
