import type { WalletCardStaticProperties } from './WalletCard.types'
import WalletCardHeader from './WalletCardHeader'
import WalletCardLoyalty from './WalletCardLoyalty'
import WalletCardPaying from './WalletCardPaying'

const WalletCard: WalletCardStaticProperties = () => {
  return null
}

WalletCard.Paying = WalletCardPaying
WalletCard.Header = WalletCardHeader
WalletCard.Loyalty = WalletCardLoyalty

export default WalletCard
