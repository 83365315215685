import React from 'react'
import { useTranslation } from 'react-i18next'
import type { WalletMovementsProps } from './WalletDetail.types'
import { Section } from '@/components/Section'
import { SectionBar } from '@/components/SectionBar'
import { AccountActivityList } from '../../components/AccountActivityList'

const WalletMovements: React.FC<WalletMovementsProps> = ({ walletId }) => {
  const { t } = useTranslation()

  return (
    <Section>
      <AccountActivityList
        config={{ wallet_id: walletId }}
        limit={5}
        ListHeaderComponent={
          <SectionBar
            title={t('@wallet.WalletDetail.wallet_history_title')}
            description={t('@wallet.WalletDetail.wallet_history_subtitle')}
            to="/account-activities"
            state={{
              walletId: walletId,
            }}
          />
        }
      />
    </Section>
  )
}
export default WalletMovements
