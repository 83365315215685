import * as React from 'react'
import cn from 'classnames'
import type { ProgressBarProps } from './Progress.types'
import styles from './Progress.module.css'
import { useCircleProgress } from './Progress.hooks'
import ThemeConfig from '~/tailwind.config'
import { Container } from '../Container'

const Progress: React.FC<ProgressBarProps> = props => {
  const { colors } = ThemeConfig.theme.extend
  const {
    value,
    valueTextColor,
    indicatorColor,
    valueTextStyle,
    backgroundColor = colors.semantic.background.quaternary,
    strokeLinecap = 'round',
    size,
    totalValue,
    children,
    className,
  } = props

  const { c, r, sw, sd } = useCircleProgress(size)

  const percentageValue = React.useMemo(() => {
    return (value / totalValue) * 100
  }, [totalValue, value])

  return (
    <Container>
      <div className={cn('relative', className)}>
        <div className={styles.Circle} style={{ width: size, height: size }}>
          <svg height={size} width={size}>
            <circle
              cx={c}
              cy={c}
              r={r}
              fill={colors.solid.core.transparent}
              stroke={backgroundColor ?? colors.solid.brand[5]}
              strokeWidth={sw}
              strokeDasharray={sd}
              strokeDashoffset={0}
            />
            <circle
              cx={c}
              cy={c}
              r={r}
              fill={colors.solid.core.transparent}
              stroke={colors.solid.core.white}
              strokeWidth={sw - size * 0.001}
              strokeLinecap={strokeLinecap}
              strokeDasharray={sd}
              transform={`rotate(-90 ${c} ${c})`}
              strokeDashoffset={sd * ((100 - percentageValue) * 0.01)}
              style={{ transition: 'stroke-dashoffset 0.5s ease 0s, stroke 0.5s ease' }}
            />
            <circle
              cx={c}
              cy={c}
              r={r}
              fill={colors.solid.core.transparent}
              stroke={indicatorColor ?? colors.solid.ink[9]}
              strokeWidth={sw - size * 0.025}
              strokeLinecap={strokeLinecap}
              strokeDasharray={sd}
              strokeDashoffset={sd * ((100 - percentageValue) * 0.01)}
              transform={`rotate(-90 ${c} ${c})`}
              style={{ transition: 'stroke-dashoffset 0.5s ease 0s, stroke 0.5s ease' }}
            />
          </svg>
          <div className={styles.Progress}>
            {!children && (
              <div className={styles.Percentage} style={{ color: valueTextColor }}>
                {`${value}/${totalValue}`}
              </div>
            )}
          </div>
        </div>
        {children && (
          <div className={styles.Children} style={{ ...valueTextStyle, color: valueTextColor }}>
            {children}
          </div>
        )}
      </div>
    </Container>
  )
}

export default Progress
