import type dayjs from 'dayjs'
import styles from './DateTimeField.module.css'

export const getCells = (
  currentMonth: dayjs.Dayjs,
  selectedDate: dayjs.Dayjs | null,
  // eslint-disable-next-line
  handleDateSelect: (day: number) => void,
): React.ReactNode[] => {
  const daysInMonth = currentMonth.daysInMonth()
  const cells: React.ReactNode[] = []
  const startDayOfMonth = currentMonth.startOf('month').day() || 7

  let days = 1

  for (let i = 0; i < Math.ceil((daysInMonth + startDayOfMonth - 1) / 7); i++) {
    const weekCells: React.ReactNode[] = []

    for (let j = 1; j <= 7; j++) {
      if (days <= daysInMonth && (i > 0 || j >= startDayOfMonth)) {
        weekCells.push(
          <div
            key={days}
            onClick={() => handleDateSelect(i * 7 + j - startDayOfMonth + 1)}
            className={`${styles.CalendarCells} ${
              selectedDate?.month() === currentMonth.month() && selectedDate?.date() === days
                ? styles.CalendarCellsSelected
                : ''
            }`}>
            {days}
          </div>,
        )
        days++
      } else {
        weekCells.push(<div key={`${i}-${j}`} className="text-center text-gray-500"></div>)
      }
    }

    cells.push(
      <div key={`week-${i}`} className="grid grid-cols-7 place-items-end">
        {weekCells}
      </div>,
    )
  }

  return cells
}

export default getCells
