import * as React from 'react'
import { useNavigate } from 'react-router-dom'
import { first, size } from 'lodash'
import { useTranslation } from 'react-i18next'
import type { DefaultDepositAmount } from './TopUp.types'
import toast from '@/context/ToastContext/Toast.utils'
import { useAppDispatch } from '@/store'
import campaignApi from '@/pages/campaign/api'
import walletApi from '../../api'
import type { UseTopUpData } from '../../hook/useTopUp'

export const useDefaultDepositAmounts = (defaultDepositAmounts: DefaultDepositAmount[] | undefined = []) => {
  const [defaultAmount, setDefaultAmount] = React.useState<number>(0)

  React.useEffect(() => {
    if (!size(defaultDepositAmounts)) return
    const firstAmount = first(defaultDepositAmounts)
    firstAmount && setDefaultAmount(firstAmount.amount)
  }, [defaultDepositAmounts])

  return defaultAmount
}

export const useCompletePurchase = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { t } = useTranslation()

  const handleCompletePurchase = React.useCallback(
    (success: boolean, canGoBack?: UseTopUpData['canGoBack']) => {
      if (!success) return

      dispatch(walletApi.util.invalidateTags(['WalletDetail']))
      dispatch(campaignApi.util.invalidateTags(['DepositCampaignDetail']))
      toast.success({ message: t('@deposit.TopUp.toast_success_message'), icon: 'check-circle', duration: 10000 })
      window.dispatchEvent(new CustomEvent('deposit-success', { detail: { success: true } }))
      window.ReactNativeWebView?.postMessage(JSON.stringify({ success: true }))

      if (!canGoBack) return navigate('/', { replace: true })

      return navigate(canGoBack.path, {
        state: {
          ...canGoBack,
        },
      })
    },
    [dispatch, navigate, t],
  )

  return handleCompletePurchase
}
