import type { AuthSubmitByTypeFunction, GetAuthRouteTitleFunction } from './Auth.types'
import store from '@/store'
import userApi from '@/pages/user/api'

import i18n from 'i18next'

export const getAuthRouteTitle: GetAuthRouteTitleFunction = () => {
  return i18n.t('@user.Auth.route_type_login')
}

export const authSubmitByType: AuthSubmitByTypeFunction = async data => {
  return store.dispatch(userApi.endpoints.loginSendSms.initiate(data)).unwrap()
}
