import * as React from 'react'
import { useTranslation } from 'react-i18next'
import type { PaymentMethodSectionProps } from './TopUp.types'
import { Row } from '@/components/Row'
import { TopUpDepositCard } from '../../components/TopUpDepositCard'

const PaymentMethodSection: React.FC<PaymentMethodSectionProps> = props => {
  const { t } = useTranslation()
  const { walletId, visible, selectedLoading = false, companyId } = props

  if (!visible || companyId == 222) return null

  return (
    <div className="py-7">
      <Row className="flex items-center justify-between pb-3">
        <Row.Title title={t('@deposit.TopUp.payment_method_section_title')} />
      </Row>
      <TopUpDepositCard walletId={walletId} selectedLoading={selectedLoading} />
    </div>
  )
}

export default PaymentMethodSection
