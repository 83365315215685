import * as React from 'react'
import type { ActionLabelProps } from './ActionLabel.types'
import { getActionLabelColor, getActionLabelSize } from './ActionLabel.utils'
import { Container } from '../Container'

const ActionLabel: React.FC<ActionLabelProps> = ({
  type = 'default',
  size = 'small',
  fontType = 'body',
  label,
  actionLabelStyle,
  actionLabelProps,
  ...props
}) => {
  const actionLabelSize = getActionLabelSize(size, fontType)
  const actionLabelColor = getActionLabelColor(type)

  return (
    <button {...props}>
      <Container>
        <p
          className={`${actionLabelSize}`}
          style={{ ...actionLabelStyle, color: actionLabelColor }}
          {...actionLabelProps}>
          {label}
        </p>
      </Container>
    </button>
  )
}

ActionLabel.displayName = 'ActionLabel'

export default ActionLabel
