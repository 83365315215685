import type { ModuleItemComponent } from './ModuleItem.types'
import ModuleItemButton from './ModuleItemButton'
import ModuleItemMore from './ModuleItemMore'

const ModuleItemContainer: ModuleItemComponent = props => {
  const { children } = props
  return <div className="flex gap-1 pt-8 [&:nth-child(n+4)]:hidden">{children}</div>
}

ModuleItemContainer.Button = ModuleItemButton
ModuleItemContainer.More = ModuleItemMore

export default ModuleItemContainer
