import React from 'react'
import type { WalletHeaderSectionProps } from './CompanyWalletSubscribe.types'
import styles from './CompanyWalletSubscribe.module.css'

const WalletHeaderSection: React.FC<WalletHeaderSectionProps> = props => {
  const { logo, name, color } = props
  return (
    <div className={styles.WalletHeaderSection} style={{ backgroundColor: color }}>
      <img src={logo} alt={name} />
      <h2>{name}</h2>
    </div>
  )
}

export default WalletHeaderSection
