import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useNavigateToPage } from './Initialize.hooks'
import { updateLocalStorage } from './Initialize.utils'
import baseApi from '@/api'
import { Spinner } from '@/components/Spinner'
import i18n from '@/translations'

const Initialize: React.FC = () => {
  const navigate = useNavigate()
  const navigateToPage = useNavigateToPage()
  const { search } = useLocation()
  const query = new URLSearchParams(search)
  const [token] = React.useState(query.get('token') ?? '')

  baseApi.useAppInfoQuery({
    platform: 'android',
    version: '1.18.0',
  })

  const InitializeQuery = baseApi.useInitializeQuery({ token })

  const initialize = React.useMemo(() => InitializeQuery.data?.data, [InitializeQuery.data])

  React.useEffect(() => {
    if (!token) return navigate('/404', { replace: true })

    if (initialize) {
      i18n.changeLanguage(initialize.auth_data.locale)
      updateLocalStorage(token, initialize)
      navigateToPage(initialize)
    }
  }, [token, navigate, initialize, navigateToPage])

  if (InitializeQuery.isLoading)
    return (
      <div className="grid h-screen place-items-center">
        <Spinner size={32} />
      </div>
    )

  return null
}

export default Initialize
