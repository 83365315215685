import React from 'react'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'
import type { RefundSectionProps } from './AccountActivityDetail.types'
import styles from './AccountActivityDetail.module.css'
import { HistoryItem } from '@/components/HistoryItem'
import { Section } from '@/components/Section'
import RefundSectionInfo from './RefundSectionInfo'

const RefundSection: React.FC<RefundSectionProps> = props => {
  const { complete, status, type, referenceCode, amount, currency, date, reason, userComment, companyComment } = props
  const { t } = useTranslation()

  return (
    <Section className="flex flex-col gap-2 pt-0">
      <HistoryItem
        type={
          !complete
            ? status === 'close'
              ? 'close'
              : 'approve'
            : type === 'capture'
              ? 'payment_refund'
              : 'deposit_refund'
        }
        subtitle={`${t('@wallet.AccountActivityDetail.reference_number')}:${referenceCode}`}
        amount={amount}
        currency={currency}
        contentStyle={{ borderBottom: 'none' }}
        datetime={dayjs(date).toDate()}
      />
      <div className={styles.RefundSectionInfoContainer}>
        <RefundSectionInfo title={`${t('@wallet.AccountActivityDetail.refund_reason')}: `} comment={reason} />
        {userComment ? (
          <RefundSectionInfo title={`${t('@wallet.AccountActivityDetail.customer_comment')}: `} comment={userComment} />
        ) : null}
        {companyComment ? (
          <RefundSectionInfo
            title={`${t('@wallet.AccountActivityDetail.company_comment')}: `}
            comment={companyComment}
          />
        ) : null}
      </div>
    </Section>
  )
}
export default RefundSection
