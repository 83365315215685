import type { RouteObject } from 'react-router-dom'
import { InvalidToken, SessionTimeout } from '@/pages/cammon/views'

export const CammonRouter: RouteObject[] = [
  {
    path: '/session-timeout',
    element: <SessionTimeout />,
  },
  {
    path: '/invalid-token',
    element: <InvalidToken />,
  },
]
