import * as React from 'react'
import paymentApi, { type PaymentStatus } from '@/pages/payment/api'
import { usePaymentEvents } from '@/pages/payment/hooks'

import { SocketService } from '@/services'

export const useCheckPaymentStatus = (
  paymentId: number,
  status: PaymentStatus,
  // eslint-disable-next-line
  onChangeStatus: (status: PaymentStatus) => void,
) => {
  const handlePaymentEvents = usePaymentEvents()

  const [paymentStatusQuery] = paymentApi.useLazyPaymentStatusQuery()

  React.useEffect(() => {
    if (status === 'approval') return

    const connected = SocketService.getState() === 'connected'

    let attempt = 0
    let interval: ReturnType<typeof setInterval> | undefined

    const polling = () => {
      interval = setInterval(async () => {
        if (attempt >= 20) return clearInterval(interval)

        attempt++

        const result = await paymentStatusQuery({
          payment_id: paymentId,
        }).unwrap()

        onChangeStatus(result.data.status)

        handlePaymentEvents(result.data)
      }, 3000)
    }

    setTimeout(polling, connected ? 5000 : 0)

    return () => {
      attempt = 20
      interval = undefined
      clearInterval(interval)
    }
  }, [handlePaymentEvents, onChangeStatus, paymentId, paymentStatusQuery, status])
}
