/* eslint-disable */
import { filter, find, first } from 'lodash'
import { type MasterPassCard, MasterPassSDK } from '@macellan/masterpass-sdk/src'
import type {
  MPDirectPurchaseData,
  MPMakeData,
  MPPurchaseAndRegisterData,
  MPPurchaseData,
  MPRegisterData,
} from './MasterPass.types'
import store from '@/store'
import depositApi, { type UserCard } from '../../api'
import {
  removeMpCards,
  setMpAccountLoading,
  setMpAccountType,
  setMpCards,
  setMpCardsLoading,
  setMpDeleteLoading,
  setMpLinkLoading,
  setMpPurchaseLoading,
  setMpRegisterLoading,
  setMpResendOTPLoading,
  setMpUnLinkLoading,
  setMpValidateTransactionLoading,
} from '../../slice'

export default class MasterPassService {
  private static get dispatch() {
    return store.dispatch
  }

  private static getMpToken = async () => {
    const masterpassGetTokenResult = await this.dispatch(
      depositApi.endpoints.masterpassGetToken.initiate(undefined, {
        forceRefetch: true,
      }),
    ).unwrap()

    MasterPassSDK.setAddress(masterpassGetTokenResult.data.service_url)
    MasterPassSDK.setClientId(masterpassGetTokenResult.data.client_id)

    return masterpassGetTokenResult.data
  }

  public static fail = async (depositId: number, errorResponse: object) => {
    return await this.dispatch(
      depositApi.endpoints.masterpassFail.initiate({
        deposit_id: depositId,
        result: errorResponse,
      }),
    ).unwrap()
  }

  public static checkMasterPass = async () => {
    this.dispatch(setMpAccountLoading(true))

    try {
      const details = await this.getMpToken()

      const checkMasterPassResult = await MasterPassSDK.checkMasterPass({
        userId: details.user_phone,
        token: details.token,
        referenceNo: details.reference_code,
        sendSms: 'N',
        sendSmsLanguage: details.sms_language,
      })

      const accountType = MasterPassSDK.Utils.parseAccountStatus(checkMasterPassResult.accountStatus)

      this.dispatch(setMpAccountType(accountType))

      return accountType
    } catch (error) {
      return Promise.reject(error)
    } finally {
      this.dispatch(setMpAccountLoading(false))
    }
  }

  public static listCards = async () => {
    this.dispatch(setMpCardsLoading(true))

    try {
      const details = await this.getMpToken()

      const listCardsResult = await MasterPassSDK.listCards(details.user_phone, details.token)

      this.dispatch(setMpCards(listCardsResult.cards))

      return listCardsResult.cards
    } catch (error) {
      return Promise.reject(error)
    } finally {
      this.dispatch(setMpCardsLoading(false))
    }
  }

  public static deleteCards = async (accountAliasName: string) => {
    this.dispatch(setMpDeleteLoading(true))

    try {
      const details = await this.getMpToken()

      await MasterPassSDK.deleteCard({
        accountAliasName: accountAliasName,
        msisdn: details.user_phone,
        token: details.token,
        referenceNo: details.reference_code,
        sendSmsLanguage: details.sms_language,
        sendSms: 'N',
      })

      this.dispatch(removeMpCards(accountAliasName))

      const cardList = await this.dispatch(depositApi.endpoints.userCardList.initiate(undefined)).unwrap()

      const selectedMpCard = find(cardList.data, card => card.type === 'masterpass')

      if (selectedMpCard?.name === accountAliasName) {
        const otherMpCard = find(store.getState().depositSlice.mpCards, card => card.Name !== accountAliasName)

        otherMpCard && this.selectCard(otherMpCard)
      }

      const mpCards = store.getState().depositSlice.mpCards

      if (!mpCards.length) {
        this.dispatch(setMpAccountType('not-user'))
        return []
      }

      return mpCards
    } catch (error) {
      return Promise.reject(error)
    } finally {
      this.dispatch(setMpDeleteLoading(false))
    }
  }

  public static register = async (data: MPRegisterData) => {
    this.dispatch(setMpRegisterLoading(true))

    try {
      const details = await this.getMpToken()

      const registerResult = await MasterPassSDK.register({
        msisdn: details.user_phone,
        referenceNo: details.reference_code,
        sendSms: 'N',
        sendSmsLanguage: details.sms_language,
        rtaPan: data.rtaPan,
        expiryDate: data.expiryDate,
        accountAliasName: data.accountAliasName,
        token: details.token,
      })

      return registerResult
    } catch (error) {
      return Promise.reject(error)
    } finally {
      this.dispatch(setMpRegisterLoading(false))
    }
  }

  public static validateTransaction = async (validationCode: string) => {
    this.dispatch(setMpValidateTransactionLoading(true))

    try {
      const details = await this.getMpToken()

      const validateTransactionResult = await MasterPassSDK.validateTransaction({
        validationCode: validationCode,
        token: details.token,
        referenceNo: details.reference_code,
        sendSms: 'N',
        sendSmsLanguage: details.sms_language,
      })

      const mpCards = await this.listCards()

      if (mpCards.length === 1) {
        await this.selectCard(mpCards[0])
      }

      return validateTransactionResult
    } catch (error) {
      return Promise.reject(error)
    } finally {
      this.dispatch(setMpValidateTransactionLoading(false))
    }
  }

  public static selectCard = async (card: MasterPassCard) => {
    return await this.dispatch(depositApi.endpoints.masterpassSelect.initiate(card)).unwrap()
  }

  public static linkCardToClient = async () => {
    this.dispatch(setMpLinkLoading(true))

    try {
      const details = await this.getMpToken()

      const linkCardToClientResult = await MasterPassSDK.linkCardToClient({
        msisdn: details.user_phone,
        token: details.token,
        referenceNo: details.reference_code,
        sendSms: 'N',
        sendSmsLanguage: details.sms_language,
      })

      return linkCardToClientResult
    } catch (error) {
      return Promise.reject(error)
    } finally {
      this.dispatch(setMpLinkLoading(false))
    }
  }

  public static unlink = async () => {
    this.dispatch(setMpUnLinkLoading(true))

    try {
      const unlinkResult = await this.dispatch(depositApi.endpoints.masterpassUnlink.initiate(undefined)).unwrap()

      this.dispatch(setMpAccountType('unlinked'))

      this.dispatch(
        depositApi.util.updateQueryData('userCardList', undefined, draft => ({
          ...draft,
          data: filter(draft.data, (card: UserCard) => card.type !== 'masterpass'),
        })),
      )

      return unlinkResult
    } catch (error) {
      return Promise.reject(error)
    } finally {
      this.dispatch(setMpUnLinkLoading(false))
    }
  }

  public static resendOtp = async () => {
    this.dispatch(setMpResendOTPLoading(true))

    try {
      const details = await this.getMpToken()
      const resendOtpResult = await MasterPassSDK.resendOtp(details.sms_language)

      return resendOtpResult
    } catch (error) {
      return Promise.reject(error)
    } finally {
      this.dispatch(setMpResendOTPLoading(false))
    }
  }

  public static purchase = async (data: MPPurchaseData) => {
    let depositId: number | undefined

    this.dispatch(setMpPurchaseLoading(true))

    try {
      const mpInitResult = await this.dispatch(
        depositApi.endpoints.masterpassInit.initiate({
          wallet_id: data.walletId,
          amount: data.amount,
          card: data.card,
          installment_count: data.installmentCount,
        }),
      ).unwrap()

      depositId = mpInitResult.data.deposit_id

      if (mpInitResult.additional_data) {
        MasterPassSDK.setAdditionalParameters(mpInitResult.additional_data)
      }

      const purchaseResult = await MasterPassSDK.purchase({
        amount: mpInitResult.data.amount.toString(),
        listAccountName: data.card.Name,
        msisdn: mpInitResult.data.user_phone,
        referenceNo: mpInitResult.data.reference_code,
        sendSmsLanguage: mpInitResult.data.sms_language,
        token: mpInitResult.data.token,
        orderNo: mpInitResult.data.order_id,
        clientIp: mpInitResult.data.client_ip,
        paymentType: mpInitResult.data.payment_type,
        installmentCount: mpInitResult.data.installment_count,
      })

      return {
        initResult: mpInitResult,
        sdkResult: purchaseResult,
      }
    } catch (error: any) {
      if (typeof depositId === 'number') {
        MasterPassService.fail(depositId, error.data)
      }

      return Promise.reject(error)
    } finally {
      this.dispatch(setMpPurchaseLoading(false))
    }
  }

  public static directPurchase = async (data: MPDirectPurchaseData) => {
    let depositId: number | undefined

    try {
      const mpInitDirectResult = await this.dispatch(
        depositApi.endpoints.masterpassInitDirect.initiate({
          wallet_id: data.walletId,
          amount: data.amount,
          card: data.maskedRtaPan,
          installment_count: data.installmentCount,
        }),
      ).unwrap()

      depositId = mpInitDirectResult.data.deposit_id

      if (mpInitDirectResult.additional_data) {
        MasterPassSDK.setAdditionalParameters(mpInitDirectResult.additional_data)
      }

      const purchaseResult = await MasterPassSDK.directPurchase({
        amount: mpInitDirectResult.data.amount.toString(),
        msisdn: mpInitDirectResult.data.user_phone,
        sendSmsLanguage: mpInitDirectResult.data.sms_language,
        token: mpInitDirectResult.data.token,
        orderNo: mpInitDirectResult.data.order_id,
        paymentType: mpInitDirectResult.data.payment_type,
        rtaPan: data.rtaPan,
        cvc: data.cvc,
        expiryDate: data.expiryDate,
        installmentCount: mpInitDirectResult.data.installment_count,
      })

      return {
        initResult: mpInitDirectResult,
        sdkResult: purchaseResult,
      }
    } catch (error: any) {
      if (typeof depositId === 'number') {
        this.fail(depositId, error.data)
      }

      return Promise.reject(error)
    }
  }

  public static purchaseAndRegister = async (data: MPPurchaseAndRegisterData) => {
    let depositId: number | undefined

    try {
      const mpInitDirectResult = await this.dispatch(
        depositApi.endpoints.masterpassInitDirect.initiate({
          wallet_id: data.walletId,
          amount: data.amount,
          card: data.maskedRtaPan,
          installment_count: data.installmentCount,
        }),
      ).unwrap()

      depositId = mpInitDirectResult.data.deposit_id

      if (mpInitDirectResult.additional_data) {
        MasterPassSDK.setAdditionalParameters(mpInitDirectResult.additional_data)
      }

      const purchaseAndRegisterResult = await MasterPassSDK.purchaseAndRegister({
        amount: mpInitDirectResult.data.amount.toString(),
        orderNo: mpInitDirectResult.data.order_id,
        paymentType: mpInitDirectResult.data.payment_type,
        rtaPan: data.rtaPan,
        cvc: data.cvc,
        expiryDate: data.expiryDate,
        accountAliasName: data.accountAliasName,
        msisdn: mpInitDirectResult.data.user_phone,
        referenceNo: mpInitDirectResult.data.reference_code,
        sendSms: 'N',
        sendSmsLanguage: mpInitDirectResult.data.sms_language,
        token: mpInitDirectResult.data.token,
        installmentCount: mpInitDirectResult.data.installment_count,
      })

      return {
        initResult: mpInitDirectResult,
        sdkResult: purchaseAndRegisterResult,
      }
    } catch (error: any) {
      if (typeof depositId === 'number') {
        this.fail(depositId, error.data)
      }

      return Promise.reject(error)
    }
  }

  public static markOTP = async (depositId: number) => {
    try {
      const markOTPResult = await this.dispatch(
        depositApi.endpoints.masterpassMarkOTP.initiate({
          deposit_id: depositId,
        }),
      ).unwrap()

      return markOTPResult
    } catch (error) {
      return Promise.reject(error)
    }
  }

  public static makeDeposit = async (data: MPMakeData) => {
    try {
      const markOTPResult = await this.dispatch(
        depositApi.endpoints.masterpassMake.initiate({
          deposit_id: data.depositId,
          token: data.token,
        }),
      ).unwrap()

      return markOTPResult
    } catch (error) {
      return Promise.reject(error)
    }
  }

  public static syncSelectedCard = async (selected: UserCard) => {
    const mpCards = await this.listCards()

    const card = find(mpCards, item => item.Name === selected.name && item.Value1 === selected.mask_pan)

    if (card) return

    const firstMpCard = first(mpCards)

    if (!firstMpCard) return

    await this.selectCard(firstMpCard)
  }
}
