export default {
  SessionTimeout: {
    content_title: 'Session Timeout',
    content_description: 'Your session has expired. Please log in again.',
  },
  InvalidToken: {
    content_title: 'Invalid Session',
    content_description: 'Your session is invalid. Please close and reopen the application.',
  },
}
