import React from 'react'
import useEmblaCarousel from 'embla-carousel-react'
import type { SegmentedControlProps, SegmentedItem, TabProps } from './SegmentedControl.types'
import styles from './SegmentedControl.module.css'
import { getClassName } from './SegmentedControl.utils'

const SegmentedTab: React.FC<SegmentedControlProps> = props => {
  const { activeTabKey, items, onChangeItem } = props

  const [emblaRef] = useEmblaCarousel({ dragFree: true, containScroll: 'trimSnaps' })

  const handleClickItem = React.useCallback(
    (item: SegmentedItem, index: number) => {
      onChangeItem && onChangeItem(item, index)
    },
    [onChangeItem],
  )

  return (
    <div ref={emblaRef} className={styles.TabContainer}>
      <div className={styles.Embla}>
        {items.map((item, index) => (
          <div key={index} className={styles.EmblaSlide}>
            <Tab items={item} index={index} isActive={activeTabKey === item.key} onChangeItem={handleClickItem} />
          </div>
        ))}
      </div>
      <div className={styles.EmblaBorder} />
    </div>
  )
}

const Tab: React.FC<TabProps> = ({ items, index, isActive, onChangeItem }) => (
  <button
    className={getClassName(styles.TabItem, isActive, styles.Active)}
    onClick={() => onChangeItem && onChangeItem(items, index)}>
    {items.label}
    {isActive && <span className={styles.Border} />}
  </button>
)

export default SegmentedTab
