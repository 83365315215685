import * as React from 'react'

export const useCircleProgress = (size: number) => {
  const c = React.useMemo(() => size * 0.5, [size])
  const sw = React.useMemo(() => size * 0.13, [size])
  const r = React.useMemo(() => c - sw, [c, sw])
  const sd = React.useMemo(() => 2 * Math.PI * r, [r])

  return { c: c, sw: sw, r: r, sd: sd }
}
