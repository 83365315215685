import store from '@/store'
import depositApi from '../../api/DepositApi'

export default class AmountCheckService {
  private static get dispatch() {
    return store.dispatch
  }

  public static getAmount = async (price: number) => {
    const balanceResult = await this.dispatch(depositApi.endpoints.balanceCheck.initiate({ price })).unwrap()

    return balanceResult.data
  }
}
