import React from 'react'
import { filter, map } from 'lodash'
import type { DropdownFieldItem, DropdownListProps } from './DropdownField.types'
import styles from './DropdownField.module.css'
import ThemeConfig from '~/tailwind.config'

const DropdownList: React.FC<DropdownListProps> = props => {
  const { data, searchText = '', value, onChangeValue } = props
  const { colors } = ThemeConfig.theme.extend

  const handleFilterList = React.useCallback(
    (item: DropdownFieldItem) => {
      return searchText.length ? item.title.toLocaleLowerCase().includes(searchText.toLocaleLowerCase()) : true
    },
    [searchText],
  )

  return (
    <div className={styles.DropdownList}>
      {map(filter(data, handleFilterList), (item, index) => (
        <div
          key={index}
          className={styles.DropdownListItem}
          style={{ background: value?.key === item.key ? colors.solid.sand[3] : 'transparent' }}
          onClick={() => onChangeValue(item)}>
          <h6>{item.title}</h6>
          <p>{item.text}</p>
        </div>
      ))}
    </div>
  )
}

export default DropdownList
