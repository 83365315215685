import React from 'react'
import { Outlet, useLocation } from 'react-router-dom'
import { AuthProvider } from '@/context'
import { ToastProvider } from '@/context'
import { ToastContainer } from '@/components/Toast'

const AuthMiddleware: React.FC = () => {
  const location = useLocation()

  React.useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }, [location])

  return (
    <ToastProvider>
      <AuthProvider>
        <Outlet />
      </AuthProvider>
      <ToastContainer />
    </ToastProvider>
  )
}

export default AuthMiddleware
