import * as React from 'react'
import { map } from 'lodash'
import type { RewardChartTableListProps } from './RewardChart.types'
import RewardChartTableListItem from './RewardChartTableListItem'

const RewardChartTableList: React.FC<RewardChartTableListProps> = props => {
  const { amountList, color, currency } = props

  const totalBalance = React.useMemo(() => {
    if (!amountList.length) return 0

    const total = amountList.reduce((value, currentValue) => value + currentValue, 0)
    return total
  }, [amountList])

  return (
    <div className="flex flex-grow justify-end gap-1">
      {map(amountList, (balance, index) => (
        <RewardChartTableListItem
          key={index}
          balance={balance}
          totalBalance={totalBalance}
          color={color}
          currency={currency}
        />
      ))}
    </div>
  )
}

export default RewardChartTableList
