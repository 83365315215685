import React from 'react'
import { useTranslation } from 'react-i18next'
import type { DepositToastProps } from './Capture.types'
import { Toast, type ToastType } from '@/components/Toast'
import { useTopUp } from '@/pages/deposit/hook'
import walletApi from '@/pages/wallet/api'

const DepositToast: React.FC<DepositToastProps> = props => {
  const { payment, wallet, walletId, depositAmount, visible, getUpdatedWallet } = props
  const { t } = useTranslation()

  const [topUpSuccess, setTopUpSuccess] = React.useState<boolean>(false)

  const [getWalletDetails] = walletApi.useLazyWalletDetailsQuery()

  const handleUpdatedWallet = React.useCallback(async () => {
    const updated = await getWalletDetails({ wallet_id: walletId as number }).unwrap()
    getUpdatedWallet(updated.data)
  }, [getUpdatedWallet, getWalletDetails, walletId])

  const handleCompletePurchase = React.useCallback(
    (success: boolean) => {
      setTopUpSuccess(success)
      if (!success) return
      handleUpdatedWallet()
    },
    [handleUpdatedWallet],
  )

  const topUp = useTopUp(
    {
      walletId: walletId,
      amount: depositAmount,
      installmentCount: null,
      canGoBack: {
        payment,
        wallet,
        path: '/capture',
      },
    },
    handleCompletePurchase,
  )

  const ToastRightComponent = React.useCallback(
    (type: ToastType) => {
      if (type !== 'error') return <React.Fragment />
      return (
        <Toast.Chip variant={type} loading={topUp.loading} onClick={topUp.init}>
          {t('@payment.Capture.toast_chip_label')}
        </Toast.Chip>
      )
    },
    [t, topUp.init, topUp.loading],
  )

  if (!visible || depositAmount === 0) return null

  return (
    <div className="fixed bottom-[calc(var(--toolbar-height)+12px)] left-3 right-3">
      <Toast
        variant={topUpSuccess ? 'success' : 'error'}
        icon={topUpSuccess ? 'check-circle' : 'warning'}
        message={topUpSuccess ? t('@payment.Capture.toast_success_message') : t('@payment.Capture.toast_error_message')}
        LayoutRightComponent={ToastRightComponent}
        {...props}
      />
    </div>
  )
}

export default DepositToast
