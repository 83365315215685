import { isRejectedWithValue, type Middleware } from '@reduxjs/toolkit'
import { values } from 'lodash'
import toast from '@/context'
import { UrlUtils } from '@/utils'

// eslint-disable-next-line
const handleError: Middleware = _store => next => action => {
  if (isRejectedWithValue(action)) {
    let message: string = action?.payload?.data?.message
    if (action.payload.status === 401) return

    if (action.payload.status === 422) {
      const errors = action.payload.data.errors
      const errorMessages = values(errors)
      if (errorMessages?.[0]?.[0]) message = errorMessages[0][0]
    }

    if (action.meta.baseQueryMeta.request.url.includes('token/validate') && action.payload.status === 404)
      return location.replace('/invalid-token')

    if (!action.meta.baseQueryMeta.request.url.includes('with-phone') && action.payload.status === 404)
      return location.replace('/404')

    const ignored = UrlUtils.checkIsEndpointIgnored(action.meta.baseQueryMeta.request.url, action.payload.status)

    if (!ignored) {
      toast.error({ message: message, icon: 'warning', duration: 5000 })
    }
  }

  return next(action)
}

export default {
  handleError: handleError,
}
