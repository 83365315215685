export default {
  Auth: {
    route_type_login: 'Log In',
    route_type_register: 'Sign Up',
    route_type_update: 'Update Phone Number',
    content_header_title: 'Enter Phone Number',
    content_header_login_description: 'You can log in to Macellan SuperApp by entering your phone number',
    content_header_register_description: 'You can create a Macellan SuperApp account by entering your phone number',
    content_header_update_description:
      'You can change your registered number on Macellan SuperApp by entering your new phone number',
    auth_action_user_not_fount_title: 'User Not Found',
    auth_action_user_not_fount_message:
      'Do you want to register for Macellan SuperApp with the information you entered?',
    auth_action_user_not_fount_action_register: 'Sign Up',
    submit_button: 'Submit',
  },
  OTPVerify: {
    route_header_title: 'Verification Code',
    content_header_title: 'Enter Verification Code',
    content_header_description: 'Enter the code sent to your phone number {{phone_number}}',
    resend_toast_message: 'Verification code sent again',
    resend_verify_button: 'Resend',
    resend_verify_second: 's',
    update_phone_success_message: 'Phone number updated successfully',
  },
}
