import React from 'react'
import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { CurrencyFormatter } from '@macellan/formatter'
import { useDefaultDepositAmounts } from './TopUp.hooks'
import { useCompletePurchase } from './TopUp.hooks'
import { Button } from '@/components/Button'
import { Section } from '@/components/Section'
import { ToolBar } from '@/components/ToolBar'
import { WalletCard } from '@/components/WalletCard'
import i18n from '@/translations'
import { useTopUp } from '@/pages/deposit/hook'
import { useSelectedCard } from '@/pages/deposit/hook'
import walletApi from '@/pages/wallet/api/WalletApi'
import { EarnablePoint } from '../../components/EarnablePoint'
import { PaymentMethodsService } from '../../services'
import GoldFramerPaymentForm from '../GoldFramer/PaymentForm'
import AmountSection from './AmountSection'
import CustomAmountField from './CustomAmountField'
import InstallmentSection from './InstallmentSection'
import OtherPaymentSection from './OtherPaymentSection.tsx'
import PaymentMethodSection from './PaymentMethodSection'
import AmountCheckService from './TopUp.services'
import TopUpSkeleton from './TopUpSkeleton'

const TopUp: React.FC = () => {
  const { t } = useTranslation()
  const { state } = useLocation()
  const [walletId] = React.useState<number>(state?.walletId ?? localStorage.getItem('user.wallet_id') ?? '')
  const handleCompletePurchase = useCompletePurchase()
  const selected = useSelectedCard('masterpass')

  const [installmentCount, setInstallmentCount] = React.useState<number | null>(null)
  const [amount, setAmount] = React.useState<number>(0)

  const walletDetailsQuery = walletApi.useWalletDetailsQuery({}, { refetchOnMountOrArgChange: true })

  const wallet = React.useMemo(() => {
    return walletDetailsQuery.data?.data
  }, [walletDetailsQuery.data?.data])

  const defaultAmount = useDefaultDepositAmounts(wallet?.company.settings.default_deposit_amounts)

  const formattedAmount = React.useMemo(() => {
    if (!wallet) return null
    return CurrencyFormatter.format(amount, i18n.getLocale(), wallet.company.currency)
  }, [amount, wallet])

  const isAvailablePaymnetMethods = React.useMemo(() => {
    const paymentMethods = walletDetailsQuery.data?.data.company.deposit_methods

    if (!paymentMethods) return false

    const availableMethods = PaymentMethodsService.getAvailablePaymentMethods(paymentMethods)

    return !!availableMethods.length
  }, [walletDetailsQuery.data?.data.company.deposit_methods])

  const checkAmount = React.useCallback(async () => {
    const paymentAmount = state?.triggerData?.payment_amount
    const amount = state?.triggerData?.amount

    if (amount) return setAmount(amount)
    if (!paymentAmount) return

    const result = await AmountCheckService.getAmount(paymentAmount)

    if (result.point_usage.status) return setAmount(result.deposit_point_and_balance)

    if (result?.deposit_balance) return setAmount(result.deposit_balance)
  }, [state?.triggerData?.amount, state?.triggerData?.payment_amount])

  React.useEffect(() => {
    if (state?.triggerData?.payment_amount || state?.triggerData?.amount) return
    setAmount(state?.customAmount ?? defaultAmount)
  }, [defaultAmount, state?.customAmount, state?.triggerData?.amount, state?.triggerData?.payment_amount])

  React.useEffect(() => {
    checkAmount()
  }, [checkAmount])

  const purchase = useTopUp(
    { walletId: walletId, amount: amount, installmentCount: installmentCount },
    handleCompletePurchase,
  )

  if (walletDetailsQuery.isLoading) return <TopUpSkeleton />

  if (!wallet) return null

  return (
    <React.Fragment>
      <WalletCard.Paying
        name={wallet.company.name}
        logo={wallet.company.logo}
        color={wallet.company.color}
        amount={wallet.balance}
        point={wallet.point}
        currency={wallet.company.currency}
        walletId={walletId}
        redirected={!!state?.triggerData}
      />
      <AmountSection amount={amount} onChangeValue={setAmount} walletId={walletId} />
      <Section className="!py-0">
        <CustomAmountField
          value={amount}
          defaultAmounts={wallet.company.settings.default_deposit_amounts}
          currency={wallet.company.currency}
          walletId={walletId}
        />
        <EarnablePoint
          amount={amount}
          currency={wallet.company.currency}
          minimumDepositAmount={wallet.company.settings.minimum_deposit.amount}
          maximumDepositAmount={wallet.company.settings.maximum_deposit.amount}
          onChangeAmount={setAmount}
          walletId={walletId}
        />
        <PaymentMethodSection
          visible={isAvailablePaymnetMethods}
          walletId={walletId}
          selectedLoading={state?.selectedLoading ?? false}
          amount={amount}
          companyId={wallet.company_id}
        />
        <InstallmentSection
          amount={amount}
          walletId={walletId}
          currency={wallet.company.currency}
          onChangeCount={setInstallmentCount}
          companyId={wallet.company_id}
        />
        <OtherPaymentSection
          enabledCashDeposit={wallet.company.modules.enable_cash_deposit}
          enabledMasterPass={walletDetailsQuery.data?.data.company.deposit_methods.masterpass}
          walletId={walletId}
          amount={amount}
          companyId={wallet.company_id}
        />
        <GoldFramerPaymentForm
          walletId={walletId}
          amount={amount}
          companyId={wallet.company_id}
          currency={wallet.company.currency}
        />
      </Section>
      {wallet.company_id !== 222 && (
        <ToolBar>
          <ToolBar.Left>
            <p>{t('@deposit.TopUp.footer_balance_title')}</p>
            <span>{formattedAmount}</span>
          </ToolBar.Left>
          <ToolBar.Right>
            <Button
              variant="primary"
              size="large"
              radiusType="rounded"
              disabled={!selected.card}
              loading={purchase.loading}
              onClick={purchase.init}>
              {t('@deposit.TopUp.footer_submit_button')}
            </Button>
          </ToolBar.Right>
        </ToolBar>
      )}
    </React.Fragment>
  )
}

export default TopUp
